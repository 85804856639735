<template>
    <IonPage>
    <IonContent>
        <MainNavigationBar/>
        <div class="flex flex--100">
            <MainProductMenu/>
        </div>
        <MainFooter/>
    </IonContent>
    </IonPage>
</template>

<script>
import { IonContent, IonPage, } from "@ionic/vue";
import MainFooter from "@/components/website/WebsiteMainFooter.vue";
import MainNavigationBar from "@/components/website/WebsiteMainNavigationBar.vue";
import MainProductMenu from "@/components/website/WebsiteProductsMenu.vue";

export default {
    name: "ProductsView",
    components: {
        MainProductMenu,
        MainNavigationBar,
        MainFooter,
        IonPage,
        IonContent,
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

::v-deep(.website-products-menu) {
    .button {
        width: 100%;
        max-width: initial;
        margin: 21px 21px 0 21px;
        background-image:
            linear-gradient(-45deg, rgb(255, 255, 255) 0%, rgba($brand-color, 0.23) 100%);
    }

    .secondary-section {
        .button {
            margin-top: 0;
        }

        .button + .button {
            margin: 21px 21px 0 21px;
        }
    }
}
</style>
