<template>
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 32 32" class="flex hand-swipe-up-icon">
        <path d="M18.23633,26.83008c-.99707,0-1.98047-.29883-2.82129-.87402l-6.09082-4.16992c-.43066-.29492-.56152-.87305-.30078-1.3252l.54688-.94727c.74414-1.28418,2.33984-1.83496,3.7168-1.2832l1.17383.4707-1.87695-7.00293c-.3916-1.46387.48047-2.97461,1.94434-3.36816,1.4668-.39258,2.97559.48145,3.36816,1.94434l1.02734,3.83496,3.84277-.06543h.05176c1.35742,0,2.54395.9082,2.89551,2.22363l1.14941,4.28711c.57031,2.13086-.69824,4.32812-2.8291,4.89844l-4.50098,1.20605c-.42773.11523-.86328.1709-1.29688.1709ZM11.21875,20.65918l5.32617,3.64648c.72266.49609,1.62207.65137,2.4707.42188l4.50098-1.20605c.5166-.13867.94727-.46973,1.21484-.93164.26758-.46289.33789-1.00195.2002-1.51758l-1.14941-4.28711c-.11914-.44434-.5293-.75293-.98242-.74121l-4.62305.07812c-.45703-.01172-.86328-.29785-.98242-.74121l-1.22949-4.58887c-.10645-.39746-.51562-.63867-.91797-.53027-.39941.10742-.6377.51953-.53125.91895l2.39453,8.93457c.09863.36621-.01855.75586-.30273,1.00684-.2832.25098-.6875.32129-1.03516.17969l-3.03027-1.21582c-.46289-.18359-.99219,0-1.24023.42871l-.08301.14453ZM11.63574,7.72168l-2.42871-2.42871c-.39062-.39062-1.02344-.39062-1.41406,0l-2.42871,2.42871c-.39062.39062-.39062,1.02344,0,1.41406s1.02344.39062,1.41406,0l.72168-.72168v7.58594c0,.55273.44727,1,1,1s1-.44727,1-1v-7.58594l.72168.72168c.19531.19531.45117.29297.70703.29297s.51172-.09766.70703-.29297c.39062-.39062.39062-1.02344,0-1.41406Z"/>
    </svg>
</template>

<script>
import { defineComponent, } from "vue";

export default defineComponent({
    name: "HandSwipeUpIcon",
});
</script>
