<template>
    <IonPage>
    <IonHeader>
        <IonToolbar>
            <IonButtons slot="start">
                <IonBackButton/>
            </IonButtons>
            <IonTitle>Discover Categories</IonTitle>
        </IonToolbar>
    </IonHeader>
    <IonContent>
        <div class="flex flex--100">
            <SimpleCategory
                v-for="{ id, } in localVisibleCategories"
                :key="id"
                :id="id"
            />
        </div>
        <hr class="safe-bottom-area"/>
    </IonContent>
    </IonPage>
</template>

<script>
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonPage,
    IonTitle,
    IonToolbar,
} from "@ionic/vue";
import UserAvatar from "@/components/UserAvatar.vue";
import SimpleCategory from "@/components/categories/SimpleCategory.vue";
import { categoriesStore, } from "@/main";

export default {
    name: "DiscoverCategoriesView",
    components: {
        IonTitle, IonBackButton,
        SimpleCategory,
        UserAvatar,
        IonPage,
        IonHeader,
        IonToolbar,
        IonContent,
        IonButton,
        IonButtons,
        IonIcon,
    },
    data () {
        return {
            localVisibleCategories: [],
        };
    },
    computed: {
        enabledCategories () {
            return categoriesStore.all
                .filter((category) => !category.isArchived);
        },

        categoriesNotFollowed () {
            return this.enabledCategories
                .filter((category) => !category.$user.isFollowing);
        },
    },
    methods: {
        async fetch () {
            await categoriesStore.fetch();
            this.resetVisibleCategories();
        },

        resetVisibleCategories () {
            this.localVisibleCategories =
                this.categoriesNotFollowed.map((category) => category);
        },
    },
    async ionViewWillEnter () {
        await this.fetch();
    },
};
</script>
