//import parsePhoneNumber from "libphonenumber-js/mobile";

export function mobileNumberIsValid (mobileNumber: string) {
    return !!parseMobileNumber(mobileNumber);
}

export function parseMobileNumber (mobileNumber: string) {
    return {};/*
    if (!mobileNumber) {
        return undefined;
    }

    let parsedMobileNumber = undefined;

    try {
        parsedMobileNumber = parsePhoneNumber(mobileNumber.trim(), {
            extract: false,
        });
    }
    catch {
        // Silence is golden
    }

    if (!parsedMobileNumber?.isValid()) {
        return undefined;
    }

    return parsedMobileNumber;*/
}
