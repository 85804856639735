<template>
    <div class="flex flex--100 unclaimed-profile-disclaimer">
        <div class="flex flex--100 flex--x-align-center">
            <span class="flex flex--y-align-center unclaimed-button" @click="openModal">
                Unclaimed Profile
                <InfoIcon/>
            </span>
        </div>
        <IonModal
            class="unclaimed-profile-modal"
            :is-open="modalIsOpen"
            :keep-contents-mounted="true"
            :initial-breakpoint="1"
            :breakpoints="[0, 1]"
            @ionModalDidDismiss="dismissModal"
        >
            <IonHeader>
                <IonToolbar>
                    <IonButtons>
                        <IonButton @click="dismissModal">Dismiss</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div class="flex flex--100 unclaimed-text-section">
                    <div class="unclaimed-text">
                        The account associated with these polls is currently in transition and awaiting official claim by the rightful owner.
                        All content, including these polls, is being managed on their behalf until the account is officially claimed.
                        <br><br>
                        If you'd like to see the account claimed and more polls be conducted by
                        its official owner, please encourage them by sharing these polls via invitation or message.
                        <br><br>
                        If you are the rightful owner
                        and wish to claim this profile, please contact us at enquiry@vottify.com.
                    </div>
                </div>
            </IonContent>
        </IonModal>
    </div>
</template>

<script>
import { defineComponent, } from "vue";
import {
    IonButton,
    IonButtons,
    IonContent, IonHeader,
    IonModal,
    IonToolbar,
} from "@ionic/vue";
import InfoIcon from "@/components/icons/InfoIcon.vue";

export default defineComponent({
    name: "UnclaimedProfileDisclaimer",
    components: {
        InfoIcon,
        IonHeader,
        IonModal,
        IonContent,
        IonToolbar,
        IonButtons,
        IonButton,
    },
    data () {
        return {
            modalIsOpen: false,
        };
    },
    methods: {
        openModal () {
            this.modalIsOpen = true;
        },

        dismissModal () {
            this.modalIsOpen = false;
        },
    },
});
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.unclaimed-profile-disclaimer {
    margin: 0;
    padding: 15px;

    border-bottom: 0.5px solid rgba(0, 0, 0, 0.05);
}

.unclaimed-button {
    will-change: opacity;
    transition: opacity 190ms;
    cursor: pointer;

    padding: 11px 23px;

    font-size: 13px;
    font-weight: 600;
    letter-spacing: normal;

    background-image:
        linear-gradient(75deg, rgb(233, 233, 233), rgb(243, 243, 243));
    border-radius: 999px;

    color: rgba(0, 0, 0, 0.9);

    &:hover {
        opacity: 0.5;
    }
}

.info-icon {
    width: 16px;

    margin: 0 0 0 5px;
    padding: 0;

    color: rgba(0, 0, 0, 0.9);
}

.unclaimed-text-section {
    background-image:
        linear-gradient(75deg, rgb(241, 241, 241), rgb(251, 251, 251));
}

.unclaimed-text {
    margin: 15px;
    padding: 0;

    font-weight: 400;
    font-size: 15px;
    color: $base-text-color;
    letter-spacing: 0.008rem;
}

.unclaimed-profile-modal {
    --height: 50%;

    &::part(handle) {
        outline: none;
    }
}
</style>
