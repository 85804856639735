<template>
    <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">

                    <IonBackButton text=""/>

                </IonButtons>
                <IonTitle>My Preferences</IonTitle>
                <IonButtons slot="end">

                    <IonButton
                        :disabled="isWaitingServerResponseForUpdate"
                        v-if="hasChangedConfiguration"
                        @click="updateConfiguration"
                    >
                        Update
                    </IonButton>

                </IonButtons>
            </IonToolbar>
        </IonHeader>
        <IonContent>

            <IonList>
                <IonListHeader>
                    <IonLabel>Polls</IonLabel>
                </IonListHeader>
                <IonItem>
                    <IonToggle v-model="localConfiguration.autoFollowPollCategoriesOnVoted">
                        Follow Voted Poll Categories
                    </IonToggle>
                </IonItem>
                <IonItem lines="none">
                    <IonNote>Auto-follow the categories of voted polls.</IonNote>
                </IonItem>
            </IonList>

            <IonList v-if="!isCreator">
                <IonListHeader>
                    <IonLabel>Privacy</IonLabel>
                </IonListHeader>
                <IonItem>
                    <IonToggle v-model="localConfiguration.shortenLastName">
                        Use Initial For Last Name
                    </IonToggle>
                </IonItem>
                <IonItem lines="none">
                    <IonNote>Your name will appear as {{ visibleName }}</IonNote>
                </IonItem>
            </IonList>

        </IonContent>
    </IonPage>
</template>

<script>
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCheckbox,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonToggle,
    IonNote,
} from "@ionic/vue";
import {userManager,} from "@/UserManager";
import {mapStores} from "pinia";
import {useUserStore} from "@/stores/UserStore";
import {cloneDeep} from "lodash/lang";

export default {
    name: "MyPreferencesView",
    components: {
        IonIcon,
        IonPage,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonNote,
        IonList,
        IonListHeader,
        IonItem,
        IonLabel,
        IonButtons,
        IonBackButton,
        IonButton,
        IonCheckbox,
        IonToggle,
    },

    data () {
        return {
            localConfiguration: {},
            isWaitingServerResponseForUpdate: false,
        };
    },

    computed: {
        ...mapStores(useUserStore),

        loggedUser () {
            return this.userStore.user;
        },

        isCreator () {
            return this.loggedUser.isCreator;
        },

        hasChangedConfiguration () {
            return JSON.stringify(this.loggedUser.configuration) !== JSON.stringify(this.localConfiguration);
        },

        visibleName () {
            return `${this.loggedUser.firstName} ${this.loggedUser.lastName[0]}.`;
        },
    },

    methods: {
        async updateConfiguration () {
            if (this.isWaitingServerResponseForUpdate) {
                return;
            }

            this.isWaitingServerResponseForUpdate = true;
            const response = await userManager.updateConfiguration(this.localConfiguration);
            this.isWaitingServerResponseForUpdate = false;

            if (response.user) {
                this.userStore.update(response.user);
                this.resetLocalConfiguration();
                //this.$router.back();
            }
        },

        resetLocalConfiguration () {
            this.localConfiguration =
                cloneDeep(this.loggedUser.configuration);
        },
    },

    created () {
        this.resetLocalConfiguration();
    },
};
</script>
