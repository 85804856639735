<template>
    <svg fill="currentColor" class="flex industry-innovation-infrastructure-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <path d="m74.477 51.26-8.863-5.117a5.467 5.467 0 0 0-1.493-.582c.154-.508.243-1.04.243-1.584V33.743a5.517 5.517 0 0 0-2.75-4.763l-8.863-5.117a5.517 5.517 0 0 0-5.5 0l-8.863 5.117a5.517 5.517 0 0 0-2.75 4.763v10.234c0 .544.089 1.076.243 1.584a5.467 5.467 0 0 0-1.493.582l-8.863 5.117a5.515 5.515 0 0 0-2.75 4.764v10.234a5.515 5.515 0 0 0 2.75 4.763l8.863 5.117c.848.49 1.799.735 2.75.735s1.902-.245 2.75-.735l8.863-5.117a5.469 5.469 0 0 0 1.25-1.002c.363.387.779.73 1.25 1.002l8.863 5.117c.848.49 1.799.735 2.75.735s1.902-.245 2.75-.735l8.863-5.117a5.516 5.516 0 0 0 2.75-4.764V56.023a5.512 5.512 0 0 0-2.75-4.763zm-1.75 2.454-9.864 5.694L53 53.713l8.614-4.973a2.504 2.504 0 0 1 2.5 0l8.613 4.974zm-34.09-9.737v-9.946l9.863 5.694v11.39l-8.613-4.973a2.504 2.504 0 0 1-1.25-2.165zm22.726-9.946v9.946c0 .891-.479 1.72-1.25 2.166L51.5 51.115v-11.39l9.863-5.694zM48.75 26.46a2.504 2.504 0 0 1 2.5 0l8.614 4.973L50 37.128l-9.864-5.695 8.614-4.973zM35.887 48.74a2.504 2.504 0 0 1 2.5 0L47 53.713l-9.864 5.695-9.864-5.695 8.615-4.973zm-8.864 19.682a2.506 2.506 0 0 1-1.25-2.165v-9.946l9.863 5.694v11.39l-8.613-4.973zm20.227.001-8.613 4.973v-11.39l9.863-5.695v9.946c0 .89-.479 1.72-1.25 2.166zm5.5 0a2.509 2.509 0 0 1-1.25-2.166v-9.946l9.863 5.695v11.39l-8.613-4.973zm20.227 0-8.613 4.973v-11.39l9.863-5.694v9.946c0 .89-.479 1.719-1.25 2.165z"/>
    </svg>
</template>

<script>
export default {
    name: "IndustryInnovationInfrastructureIcon",
};
</script>
