<template>
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" class="flex zero-hunger-icon" viewBox="0 0 100 100">
        <path d="M57 76H43c-1.93 0-3.5-1.57-3.5-3.5v-.522c-11.788-2.55-17.841-9.762-17.995-21.453a2.468 2.468 0 0 1 .717-1.78A2.488 2.488 0 0 1 24.005 48h51.989c.676 0 1.31.265 1.783.745a2.47 2.47 0 0 1 .717 1.78c-.153 11.691-6.206 18.903-17.994 21.453v.522c0 1.93-1.57 3.5-3.5 3.5zM24.516 51c.309 10.286 5.794 16.265 16.754 18.272a1.5 1.5 0 0 1 1.23 1.476V72.5a.5.5 0 0 0 .5.5h14a.5.5 0 0 0 .5-.5v-1.752c0-.724.518-1.345 1.23-1.476C69.69 67.265 75.175 61.286 75.484 51H24.516zm52.479-.495h.01-.01zm-25.848-5.039c4.813-5.716 2.031-9.02 0-11.433-1.987-2.359-3.187-3.784 0-7.567a1.5 1.5 0 1 0-2.295-1.933c-4.813 5.716-2.031 9.02 0 11.433 1.987 2.359 3.187 3.784 0 7.567a1.5 1.5 0 1 0 2.295 1.933zm-10 0c4.813-5.716 2.031-9.02 0-11.433-1.987-2.359-3.187-3.784 0-7.567a1.5 1.5 0 1 0-2.295-1.933c-4.813 5.716-2.031 9.02 0 11.433 1.987 2.359 3.187 3.784 0 7.567a1.5 1.5 0 1 0 2.295 1.933zm20 0c4.813-5.716 2.031-9.02 0-11.433-1.987-2.359-3.187-3.784 0-7.567a1.5 1.5 0 1 0-2.295-1.933c-4.813 5.716-2.031 9.02 0 11.433 1.987 2.359 3.187 3.784 0 7.567a1.5 1.5 0 1 0 2.295 1.933z"/>
    </svg>
</template>

<script>
export default {
    name: "ZeroHungerIcon",
}
</script>
