<template>
    <IonModal
        class="stripe-subscription-modal"
        :initial-breakpoint="1"
        :breakpoints="[0, 1]"
        :is-open="isOpen"
        :keep-contents-mounted="true"
        @ionModalDidDismiss="dismissModal"
    >
        <IonHeader>
            <IonToolbar>
                <IonTitle>{{ productName }} Subscription</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <StripeSubscriptionForm
                :price-id="priceId"
                :key="priceId"
                v-if="priceId"
                @subscription-confirmed="onSubscriptionConfirmed"
            />
        </IonContent>
    </IonModal>
</template>

<script>
import { IonContent, IonHeader, IonModal, IonTitle, IonToolbar } from "@ionic/vue";
import { defineComponent, } from "vue";
import PollFaq from "@/components/mixed/PollFaq";
import StripeSubscriptionForm from "@/components/billings/StripeSubscriptionForm.vue";

export default defineComponent({
    name: "StripeSubscriptionModal",
    components: {StripeSubscriptionForm, PollFaq, IonModal, IonContent, IonHeader, IonToolbar, IonTitle},
    data () {
        return {
            isOpen: false,
            priceId: undefined,
            productName: "",
            confirmationCallback: undefined,
        };
    },
    methods: {
        openModal (priceId, productName, confirmationCallback) {
            this.isOpen = true;
            this.priceId = priceId;
            this.productName = productName;
            this.confirmationCallback = confirmationCallback;
        },

        dismissModal () {
            this.isOpen = false;
            this.priceId = undefined;
            this.productName = undefined;
            this.confirmationCallback = undefined;
        },

        onSubscriptionConfirmed () {
            const confirmationCallback = this.confirmationCallback;

            this.dismissModal();
            confirmationCallback?.();
        },
    },
    watch: {
        $route () {
            this.dismissModal();
        },
    },
});
</script>

<style lang="scss" scoped>
.stripe-subscription-modal {
    --height: 70%;

    &::part(handle) {
        outline: none;
    }
}
</style>
