<template>
    <div class="flex application-marketplace-buttons">
        <div class="flex marketplace-button flex--x-align-center flex--y-align-center" @click="toAppStore">
            <img src="assets/site/AppStoreIcon.svg" alt="App store" class="store-logo store-logo--apple">
            <div class="flex flex--direction-column store-text">
                <span class="store-subtitle">Download on the</span>
                <span class="store-title">App Store</span>
            </div>
        </div>
        <div class="flex marketplace-button flex--x-align-center flex--y-align-center" @click="toPlayStore">
            <img src="assets/site/PlayStoreIcon.svg" alt="Google Play" class="store-logo store-logo--android">
            <div class="flex flex--direction-column store-text">
                <span class="store-subtitle">GET IT ON</span>
                <span class="store-title">Google Play</span>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, } from "vue";
import { APP_STORE_URI, PLAY_STORE_URI, } from "@/utilities/Utilities";

export default defineComponent({
    name: "ApplicationMarketplaceButtons",
    methods: {
        toAppStore () {
            window.open(APP_STORE_URI, "_blank");
        },

        toPlayStore() {
            window.open(PLAY_STORE_URI, "_blank");
        },
    },
});
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

$store-button-height: 56px;

.application-marketplace-buttons {

}

.marketplace-button {
    cursor: pointer;

    position: relative;

    width: 211px;
    height: $store-button-height;

    background-color: rgb(0, 0, 0);
    border-radius: 9px;

    & + & {
        margin: 0 0 0 27px;
    }

    &--disabled {
        cursor: default;

        opacity: 0.3;
    }

    @media (max-width: $mobile-breaking-point) {
        width: 100%;

        & + & {
            margin: 27px 0 0 0;
        }
    }
}

.store-logo {
    width: 36px;

    &--android {
        width: 31px;
    }
}

.store-subtitle {
    margin: 0;
    font-size: 13px;

    color: rgb(255, 255, 255);
}

.store-title {
    margin: 0;
    font-size: 23px;

    color: rgb(255, 255, 255);
}

.store-text {
    margin-left: 14px;
}
</style>
