<template>
    <div class="flex flex--100 plan">
        <div class="flex flex--100 plan-features-section">
            <div class="flex flex--100 flex--y-align-center feature-section">
                <ApplicationIcon/>
                <span class="feature">
                    3 Polls
                    <span class="monthly">/ month</span>
                </span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section">
                <ApplicationIcon/>
                <span class="feature">
                    30.000 Votes
                    <span class="monthly">/ month</span>
                </span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section">
                <ApplicationIcon/>
                <span class="feature">
                    2 Demographics Analytics
                    <InfoButton>
                        Uncover Analytics by<br>
                        Gender and Age Range
                    </InfoButton>
                </span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section">
                <ApplicationIcon/>
                <span class="feature">Polls in Feed Algorithm</span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section">
                <ApplicationIcon/>
                <span class="feature">4 Poll Options</span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section">
                <ApplicationIcon/>
                <span class="feature">
                    Voters Verification<!--<br>
                    (Email, Mobile Number)-->
                </span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section">
                <ApplicationIcon/>
                <span class="feature">Export Poll Data</span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section feature-section--disabled">
                <ApplicationIcon/>
                <div class="feature">
                    Poll Actions
                </div>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section feature-section--disabled">
                <ApplicationIcon/>
                <span class="feature">
                    Targeted Polls<!--<br>
                    (Age, Gender, Income Range, etc.)-->
                </span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section feature-section--disabled">
                <ApplicationIcon/>
                <span class="feature">
                    Collaboration on Polls
                </span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section feature-section--disabled">
                <ApplicationIcon/>
                <div class="feature">
                    Social Media Integrations
                </div>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section feature-section--disabled">
                <ApplicationIcon/>
                <span class="feature">
                    Poll Comments Sentiment Analysis
                </span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section feature-section--disabled">
                <ApplicationIcon/>
                <span class="feature">
                    Poll Options as Images
                </span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section feature-section--disabled">
                <ApplicationIcon/>
                <span class="feature">
                    Group Polls to Survey
                </span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section feature-section--disabled">
                <ApplicationIcon/>
                <span class="feature">
                    Recurrent Polls
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import ApplicationIcon from "@/components/ApplicationIcon.vue";
import { defineComponent, } from "vue";
import InfoButton from "@/components/mixed/InfoButton.vue";

export default defineComponent({
    name: "LightCreatorPlan",
    components: {
        InfoButton,
        ApplicationIcon,
    },
});
</script>

<style lang="scss" scoped>
@import "@/theme/pricing-plans.scss";
</style>
