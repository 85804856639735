<template>
    <div class="flex flex--100 stripe-subscription-form">

        <div class="flex flex--100 flex--x-align-center loading-section" v-show="isWaitingServerResponse">

            <div class="flex flex--100 flex--x-align-center flex--y-align-center subscription-form">
                <IonSpinner name="circles"/>
            </div>

        </div>
        <div class="flex flex--100 form-section" v-show="!isWaitingServerResponse">

            <div class="flex flex--100 flex--x-align-center flex--y-align-center subscription-form">
                <div ref="stripeRoot"></div>

                <div class="flex flex--100 flex--x-align-center">
                    <IonButton
                        class="subscribe-button"
                        @click="confirmSubscription"
                    >
                        Confirm Subscription
                    </IonButton>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { defineComponent, } from "vue";
import { getStripeClient, } from "@/utilities/Stripe";
import { userManager } from "@/UserManager";
import { IonButton, IonSpinner } from "@ionic/vue";

export default defineComponent({
    name: "StripeSubscriptionForm",
    components: {
        IonSpinner,
        IonButton,
    },
    props: {
        priceId: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            isWaitingServerResponse: false,
            stripeClient: undefined,
            stripeElements: undefined,
        };
    },
    methods: {
        async confirmSubscription () {
            this.isWaitingServerResponse = true;
            const stripeResponse = await this.stripeClient.confirmPayment({
                elements: this.stripeElements,
                confirmParams: {
                    return_url: window.location.href,
                },
                redirect: "if_required",
            });
            this.isWaitingServerResponse = false;

            const paymentIntent = stripeResponse.paymentIntent;
            const error = paymentIntent.error;
            const status = paymentIntent.status;

            if (error) {
                console.log(error);
            }

            if (status === "succeeded") {
                this.$emit("subscription-confirmed");
            }
        },

        async loadStripeForm (clientSecret) {
            this.stripeClient = await getStripeClient();
            const options = {
                clientSecret,
                appearance: {
                    labels: "floating",
                },
            };
            const elements = this.stripeClient.elements(options);
            const form = elements.create("payment");
            this.stripeElements = elements;

            form.mount(this.$refs.stripeRoot);
        },

        async fetch () {
            this.isWaitingServerResponse = true;

            const {
                clientSecret,
            } = await userManager.createStripeSubscription(this.priceId);

            await this.loadStripeForm(clientSecret);

            this.isWaitingServerResponse = false;
        },
    },
    mounted () {
        this.fetch();
    },
});
</script>

<style lang="scss" scoped>
.stripe-subscription-form {
    margin: 0;
    padding: 0;
}

.subscribe-button {
    margin-top: 21px;
}

.form-section {
    height: 100%;
}

.loading-section {
    height: 100%;
}
</style>
