<template>
    <div class="flex hamburger-button" :class="modifiers">
        <div class="flex flex--100 main-section">
            <div class="hamburger-line"></div>
            <div class="hamburger-line"></div>
            <div class="hamburger-line"></div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        modifiers () {
            return {
                "hamburger-button--active": this.isActive,
            };
        },
    },
});
</script>

<style lang="scss" scoped>
.hamburger-button {

}

.hamburger-line {
    will-change: transform, top, opacity;
    transition: transform 300ms, top 300ms, opacity 300ms;

    position: absolute;
    left: 50%;
    top: 50%;

    width: 100%;
    height: 2.5px;
    margin: 0;
    padding: 0;

    border: none;
    background-color: rgb(63, 63, 63);

    &:first-child {
        top: calc(50% - 8px);
    }
    &:last-child {
        top: calc(50% + 8px);
    }

    transform: translate(-50%, -50%);
}

.main-section {
    cursor: pointer;
    position: relative;

    width: 33px;
    height: 28px;
}

.hamburger-button--active {
    .hamburger-line:first-child {
        top: 50%;

        transform: translate(-50%, -50%) rotate(45deg);
    }

    .hamburger-line:nth-child(2) {
        opacity: 0;
    }

    .hamburger-line:last-child {
        top: 50%;

        transform: translate(-50%, -50%) rotate(-45deg);
    }
}
</style>
