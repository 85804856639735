<template>
    <div class="flex flex--100 simple-country-selector">

        <div class="flex flex--100 search-section">
            <IonSearchbar class="searchbar" v-model="searchQuery"/>
        </div>
        <div class="flex flex--100 main-section">
            <div class="flex flex--100 countries-section" ref="section">
                <div
                    class="flex flex--y-align-center country"
                    v-for="country in visibleCountries"
                    :key="country.iso2"
                    :class="getCountryModifiers(country)"
                    @click="toggleCountrySelection(country)"
                >
                    <div class="flex flex--50">
                        {{ shortenCountryName(country.name) }}
                    </div>
                    <div class="flex flex--50 flex--x-align-end">
                        <span class="country-emoji">{{ country.emojiIcon }}</span>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {cloneDeep} from "lodash/lang";
import { IonSearchbar } from "@ionic/vue";
import Fuse from "fuse.js";
import { shortenCountryName } from "@/utilities/Countries";

export default {
    name: "SimpleCountrySelector",
    components: {
        IonSearchbar,
    },
    props: {
        countries: {
            type: Array,
            required: true,
        },
        value: {
            type: Array,
            required: true,
        },
        itemsPerRow: {
            type: Number,
            default: 5,
        },
    },
    data () {
        return {
            searchQuery: "",
        };
    },
    computed: {
        fuse () {
            return new Fuse(this.countries, {
                keys: [ "name", ],
            });
        },

        visibleCountries () {
            const searchQuery = this.searchQuery;

            if (!searchQuery) {
                return this.countries;
            }

            return this.fuse.search(searchQuery).map((value) => value.item);
        },
    },
    methods: {
        selectCountry (country) {
            if (this.countryIsSelected(country)) {
                return;
            }

            this.$emit("update:value", [ ...this.value, country.iso2, ]);
        },

        deselectCountry (country) {
            if (!this.countryIsSelected(country)) {
                return;
            }

            const valueCopy = cloneDeep(this.value);

            valueCopy.splice(valueCopy.indexOf(country.iso2), 1);
            this.$emit("update:value", valueCopy);
        },

        toggleCountrySelection (country) {
            if (!this.countryIsSelected(country)) {
                this.selectCountry(country);
            }
            else {
                this.deselectCountry(country);
            }
        },

        countryIsSelected (country) {
            return this.value.includes(country.iso2);
        },

        getCountryModifiers (country) {
            return {
                "country--selected": this.countryIsSelected(country),
            };
        },

        shortenCountryName (name) {
            return shortenCountryName(name);
        },
    },
    watch: {
        // Used to reset scrolling when searching new elements
        searchQuery () {
            this.$refs.section.scrollLeft = 0;
            this.$refs.section.scrollTop = 0;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.simple-country-selector {

}

.search-section {

}

.searchbar {
    padding: 40px 20px;
}

.main-section {
    overflow-y: scroll;

    background-color: rgb(255, 255, 255);

    &::-webkit-scrollbar {
        display: none;
    }
}

.countries-section {
    height: 40vh;

    margin: 0;
    padding: 0;
}

.country {
    -webkit-user-select: none;
    user-select: none;

    will-change: border-color, box-shadow, background-color;
    transition: border-color 140ms, box-shadow 140ms, background-color 140ms;

    cursor: pointer;

    width: 100%;
    margin: 0;
    padding: 20px;

    background-color: rgb(255, 255, 255);

    font-weight: 700;
    font-size: 13px;
    color: $base-text-color;

    letter-spacing: 0.008rem;

    &--selected {
        border-color: $brand-color;
        box-shadow: inset 0 0 0 2px $brand-color;
        background-color: rgba($brand-color, 0.1);
    }

    & + & {
        margin-top: 0;
        border-top: none;
    }
}

.country-emoji {
    width: 32px;
    height: 32px;
    background-color: rgb(230, 230, 230);
    border-radius: 50%;
    font-size: 21px;
    line-height: 32px;

    text-align: center;
}
</style>
