<template>
    <IonPage>
    <IonContent ref="content">
        <MainNavigationBar/>
        <div class="flex flex--100">
            <WebsiteLightHeader
                :title="presentation.title"
                :subtitle="presentation.subtitle"
            />
            <div class="flex flex--100 flex--x-align-center features-navigation">
                <div class="flex flex--80">
                    <IonSegment :value="activeFeature">
                        <IonSegmentButton
                            class="feature-navigation-button"
                            v-for="(feature, i) in features"
                            :key="i"
                            :value="feature.id"
                            @click="scrollToFeature(feature.id)"
                        >
                            {{ feature.shortTitle }}
                        </IonSegmentButton>
                    </IonSegment>
                </div>
            </div>
            <div class="flex flex--100 flex--x-align-center features-section">
                <FeatureOverview
                    v-for="(feature, i) in presentation.features"
                    :key="i"
                    :presentation="feature"
                    :ref="feature.id"
                    @viewport-entered="onFeatureViewportEntered"
                    @viewport-exited="onFeatureViewportExited"
                />
            </div>
            <CreatorPricingPreviewSection/>
        </div>
        <MainFooter/>
    </IonContent>
    </IonPage>
</template>

<script>
import { IonContent, IonPage, IonSegment, IonSegmentButton, } from "@ionic/vue";
import CreatorPricingPreviewSection from "@/components/website/CreatorPricingPreview.vue";
import MainFooter from "@/components/website/WebsiteMainFooter.vue";
import MainNavigationBar from "@/components/website/WebsiteMainNavigationBar.vue";
import CategorisationIntroduction from "@/components/website/ContentCategorisation.vue";
import { mapStores } from "pinia";
import { usePollsStore } from "@/stores/PollsStore";
import FeatureOverview from "@/components/website/FeatureOverview.vue";
import WebsiteMainHeader from "@/components/website/WebsiteMainHeader.vue";
import WebsiteLightHeader from "@/components/website/TargetCustomerHeader.vue";
import { analyticsFeature, integrationsFeature, sharingFeature, } from "@/utilities/ProductSheet";

const features = [
    {
        id: "engagement",
        screenshotUri: "/assets/mockups/ngo-poll-2.png",
        screenshotPosition: "left",
        title: "Engage Global Audience with Real-Time Polls",
        shortTitle: "Engage Globally",
        paragraphs: [
            {
                title: "Engage Global Audiences",
                body: `
                    Poll communities around the world to understand their perspectives on
                    international issues, enabling more informed and inclusive policymaking.
                `,
            },
            {
                title: "Drive Cross-Border Action",
                body: `
                    Use call-to-action features to rally support for global causes,
                    from climate change initiatives to humanitarian aid efforts.
                `,
            },
            {
                title: "Monitor Global Trends",
                body: `
                    Stay ahead of emerging global issues by tracking shifts in public opinion across regions and industries.
                `,
            },
        ],
    },
    analyticsFeature(),
    sharingFeature(),
    integrationsFeature(),
];

export default {
    name: "ForInternationalOrganisationsView",
    components: {
        WebsiteLightHeader,
        WebsiteMainHeader,
        FeatureOverview,
        CategorisationIntroduction,
        MainNavigationBar,
        MainFooter,
        CreatorPricingPreviewSection,
        IonPage,
        IonContent,
        IonSegment,
        IonSegmentButton,
    },
    data () {
        return {
            activeFeature: undefined,
            upcomingFeature: undefined,
        };
    },
    computed: {
        ...mapStores(usePollsStore),

        presentation () {
            return {
                title: `
                    Shape Global Solutions with Measurable Insights
                `,
                subtitle: `
                     Gather data on global issues, shape policy and initiatives with the
                     backing of real-time and measurable public sentiment.
                `,
                features,
            };
        },

        features () {
            return features;
        },
    },
    methods: {
        onFeatureViewportEntered (featureId) {
            if (!this.activeFeature) {
                this.activeFeature = featureId;
            }
            else {
                this.upcomingFeature = featureId;
            }
        },

        onFeatureViewportExited (featureId) {
            if (this.activeFeature === featureId) {
                this.activeFeature = undefined;
            }

            if (!this.activeFeature && this.upcomingFeature) {
                this.activeFeature = this.upcomingFeature;
                this.upcomingFeature = undefined;
            }
        },

        scrollToFeature (featureId) {
            const featureElement = this.$refs[featureId]?.[0]?.$el;

            featureElement.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        },
    },
    ionViewWillLeave () {
        this.$refs.content.$el.scrollToTop();
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.features-section {
    margin: 111px 0;
}

.feature-overview {
    scroll-margin-top: 123px;
}

.features-navigation {
    position: sticky;
    top: 113px;

    padding: 19px;

    background-color: rgb(241, 241, 241);
    background-image: linear-gradient(45deg, rgb(241, 241, 241), rgba($brand-color, 0.15), rgb(241, 241, 241));

    z-index: 100;

    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;

        width: 100%;
        height: 0.5px;

        background-color: rgb(220, 220, 220);
        z-index: 54;
    }

    @media (max-width: $mobile-breaking-point) {
        display: none;
    }
}

.feature-navigation-button {
    font-size: 15px;
    font-weight: 400;

    &.segment-button-checked {
        font-weight: 500;
    }
}

.creator-pricing-preview {
    margin-bottom: 111px;
}
</style>
