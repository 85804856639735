<template>
    <div class="flex user-avatar" :style="style"></div>
</template>

<script>
import { defineComponent, } from "vue";

export default defineComponent({
    name: "UserAvatar",
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    computed: {
        profileImageUri () {
            let profileImageUri;

            if (this.user.profileImageUri) {
                profileImageUri = this.user.profileImageUri;
            }

            return profileImageUri;
        },

        style () {
            return {
                "background-image": `url("${this.profileImageUri}")`,
            };
        },
    },
});
</script>

<style lang="scss" scoped>
.user-avatar {
    background-color: rgb(240, 240, 240);
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid rgb(200, 200, 200);
    border-radius: 50%;
}
</style>
