import { HTTP_API_URI, } from "@/ServerManager";
import { userManager, } from "@/UserManager";

export async function connectTwitterAccount () {
    const oAuthUri =
        `${HTTP_API_URI}/integrations/twitter/request-token?authenticationToken=${userManager.authenticationToken}`;

    window.open(oAuthUri, "_system");
}

export async function connectLinkedInAccount () {
    const oAuthUri =
        `${HTTP_API_URI}/integrations/linked-in/request-token?authenticationToken=${userManager.authenticationToken}`;

    window.open(oAuthUri, "_system");
}

export async function connectFacebookAccount () {
    const oAuthUri =
        `${HTTP_API_URI}/integrations/facebook/request-token?authenticationToken=${userManager.authenticationToken}`;

    window.open(oAuthUri, "_system");
}
