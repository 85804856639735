<template>
    <div class="flex flex--100 simple-progress-bar">
        <div class="flex flex--100 flex--x-align-center">
            <ApplicationLogo/>
        </div>
        <div class="flex flex--100 flex--x-align-center">
            <IonProgressBar class="progress-bar" :value="progress"/>
        </div>
    </div>
</template>

<script>
import { defineComponent, } from "vue";
import ApplicationLogo from "@/components/ApplicationLogo.vue";
import { IonProgressBar, } from "@ionic/vue";

export default defineComponent({
    name: "SimpleProgressBar",
    components: {
        ApplicationLogo,
        IonProgressBar,
    },
    props: {
        progress: {
            type: Number,
        },
    },
});
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.simple-progress-bar {
    margin: 0;
    padding: 32px 0;
}

.progress-bar {
    --progress-background: #{$brand-color};

    width: 80%;
}

.application-logo {
    margin: 0 0 32px 0;

    width: 90vw;
    max-width: 142px;
}
</style>
