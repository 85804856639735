<template>
    <svg fill="currentColor" class="flex grid-icon" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.5,6.5h-13c-1.6543,0-3,1.3457-3,3v13c0,1.6543,1.3457,3,3,3h13c1.6543,0,3-1.3457,3-3v-13c0-1.6543-1.3457-3-3-3ZM23.5,9.5v2.5h-3.5v-3.5h2.5c.55176,0,1,.44873,1,1ZM14,18v-4h4v4h-4ZM18,20v3.5h-4v-3.5h4ZM12,18h-3.5v-4h3.5v4ZM14,12v-3.5h4v3.5h-4ZM20,14h3.5v4h-3.5v-4ZM9.5,8.5h2.5v3.5h-3.5v-2.5c0-.55127.44824-1,1-1ZM8.5,22.5v-2.5h3.5v3.5h-2.5c-.55176,0-1-.44873-1-1ZM22.5,23.5h-2.5v-3.5h3.5v2.5c0,.55127-.44824,1-1,1Z"/>
    </svg>
</template>

<script>
import { defineComponent, } from "vue";

export default defineComponent({
    name: "GridIcon",
});
</script>
