import { categoriesStore } from "@/main";
import { defineStore, } from "pinia";
import { ref, } from "vue";

const POLL_METRICS_RANDOMISATION =
    process.env.VUE_APP_POLL_METRICS_RANDOMISATION === "true";

export const usePollsStore = defineStore("polls", () => {
    const polls: Record<string, any> = ref({});

    return {
        get (id: string) {
            return polls.value[id];
        },
        update (poll: any) {
            const id: string = poll.id;

            if (POLL_METRICS_RANDOMISATION) {
                let totalVotes = 0;
                poll.metrics.votesCounters = poll.options.map(() => {
                    const votes = Math.random() * (1_000_000 - 10000) + 10000;

                    totalVotes += votes;

                    return votes;
                });
                poll.metrics.impressionsCounter = Math.random() * (totalVotes * 2 - totalVotes) + totalVotes;
                poll.metrics.viewsCounter = Math.random() * (totalVotes * 2 - totalVotes) + totalVotes;
                poll.metrics.commentsCounter = Math.random() * (totalVotes / 100 - 100) + 100;
                poll.metrics.sharesCounter = Math.random() * (totalVotes / 100 - 100) + 100;

                poll.createdBy.metrics.followersCounter = Math.random() * (totalVotes - totalVotes / 3) + totalVotes / 3;
            }

            polls.value[id] = poll;

            categoriesStore.updateMany(poll.categories);
        },
        updateMany (polls: any[]) {
            for (const poll of polls) {
                this.update(poll);
            }
        },
    };
});
