<template>
    <div class="flex simple-category-icon" v-if="alternativeId">

        <div
            class="flex flex--x-align-center flex--y-align-center category-icon climate-action"
            v-if="alternativeId === 'ClimateAction'"
        >
            <ClimateActionIcon/>
        </div>

        <div
            class="flex flex--x-align-center flex--y-align-center category-icon good-health-well-being"
            v-if="alternativeId === 'GoodHealthWellBeing'"
        >
            <GoodHealthWellBeingIcon/>
        </div>

        <div
            class="flex flex--x-align-center flex--y-align-center category-icon life-below-water"
            v-if="alternativeId === 'LifeBelowWater'"
        >
            <LifeBelowWaterIcon/>
        </div>

        <div
            class="flex flex--x-align-center flex--y-align-center category-icon no-poverty"
            v-if="alternativeId === 'NoPoverty'"
        >
            <NoPovertyIcon/>
        </div>

        <div
            class="flex flex--x-align-center flex--y-align-center category-icon affordable-clean-energy"
            v-if="alternativeId === 'AffordableCleanEnergy'"
        >
            <AffordableCleanEnergyIcon/>
        </div>

        <div
            class="flex flex--x-align-center flex--y-align-center category-icon social-media-trending"
            v-if="alternativeId === 'SocialMediaTrending'"
        >
            <SocialMediaTrendingIcon/>
        </div>

        <div
            class="flex flex--x-align-center flex--y-align-center category-icon peace-justice-institution"
            v-if="alternativeId === 'PeaceJusticeStrongInstitutions'"
        >
            <PeaceJusticeStrongInstitutionIcon/>
        </div>

        <div
            class="flex flex--x-align-center flex--y-align-center category-icon partnerships-for-the-goals"
            v-if="alternativeId === 'PartnershipGoals'"
        >
            <PartnershipForTheGoalsIcon/>
        </div>

        <div
            class="flex flex--x-align-center flex--y-align-center category-icon zero-hunger"
            v-if="alternativeId === 'ZeroHunger'"
        >
            <ZeroHungerIcon/>
        </div>

        <div
            class="flex flex--x-align-center flex--y-align-center category-icon quality-education"
            v-if="alternativeId === 'QualityEducation'"
        >
            <QualityEducationIcon/>
        </div>

        <div
            class="flex flex--x-align-center flex--y-align-center category-icon gender-equality"
            v-if="alternativeId === 'GenderEquality'"
        >
            <GenderEqualityIcon/>
        </div>

        <div
            class="flex flex--x-align-center flex--y-align-center category-icon clean-water-sanitation"
            v-if="alternativeId === 'CleanWaterSanitation'"
        >
            <CleanWaterSanitationIcon/>
        </div>

        <div
            class="flex flex--x-align-center flex--y-align-center category-icon decent-work-economic-growth"
            v-if="alternativeId === 'DecentWorkEconomicGrowth'"
        >
            <DecentWorkEconomicGrowthIcon/>
        </div>

        <div
            class="flex flex--x-align-center flex--y-align-center category-icon life-on-land"
            v-if="alternativeId === 'LifeOnLand'"
        >
            <LifeOnLandIcon/>
        </div>

        <div
            class="flex flex--x-align-center flex--y-align-center category-icon vottify"
            v-if="alternativeId === 'Vottify'"
        >
            <VottifyIcon/>
        </div>

        <div
            class="flex flex--x-align-center flex--y-align-center category-icon industry-innovation-infrastructure"
            v-if="alternativeId === 'IndustryInnovationInfrastructure'">
            <IndustryInnovationInfrastructureIcon/>
        </div>

        <div
            class="flex flex--x-align-center flex--y-align-center category-icon reduced-inequalities"
            v-if="alternativeId === 'ReducedInequalities'">
            <ReducedInequalitiesIcon/>
        </div>

        <div
            class="flex flex--x-align-center flex--y-align-center category-icon sustainable-cities-communities"
            v-if="alternativeId === 'SustainableCitiesCommunities'">
            <SustainableCitiesCommunitiesIcon/>
        </div>

        <div
            class="flex flex--x-align-center flex--y-align-center category-icon responsible-consumption-production"
            v-if="alternativeId === 'ResponsibleConsumptionProduction'">
            <ResponsibleConsumptionProductionIcon/>
        </div>

    </div>
</template>

<script>
import ClimateActionIcon from "@/components/icons/ClimateActionIcon.vue";
import GoodHealthWellBeingIcon from "@/components/icons/GoodHealthWellBeingIcon.vue";
import LifeBelowWaterIcon from "@/components/icons/LifeBelowWaterIcon.vue";
import NoPovertyIcon from "@/components/icons/NoPovertyIcon.vue";
import AffordableCleanEnergyIcon from "@/components/icons/AffordableCleanEnergyIcon.vue";
import SocialMediaTrendingIcon from "@/components/icons/SocialMediaTrendingIcon.vue";
import PeaceJusticeStrongInstitutionIcon from "@/components/icons/PeaceJusticeStrongInstitutionIcon.vue";
import PartnershipForTheGoalsIcon from "@/components/icons/PartnershipForTheGoalsIcon.vue";
import ZeroHungerIcon from "@/components/icons/ZeroHungerIcon.vue";
import QualityEducationIcon from "@/components/icons/QualityEducationIcon.vue";
import GenderEqualityIcon from "@/components/icons/GenderEqualityIcon.vue";
import CleanWaterSanitationIcon from "@/components/icons/CleanWaterSanitationIcon.vue";
import DecentWorkEconomicGrowthIcon from "@/components/icons/DecentWorkEconomicGrowthIcon.vue";
import LifeOnLandIcon from "@/components/icons/LifeOnLandIcon.vue";
import VottifyIcon from "@/components/icons/VottifyIcon.vue";
import IndustryInnovationInfrastructureIcon from "@/components/icons/IndustryInnovationInfrastructureIcon.vue";
import ReducedInequalitiesIcon from "@/components/icons/ReducedInequalitiesIcon.vue";
import SustainableCitiesCommunitiesIcon from "@/components/icons/SustainableCitiesCommunitiesIcon.vue";
import ResponsibleConsumptionProductionIcon from "@/components/icons/ResponsibleConsumptionProductionIcon.vue";

export default {
    name: "SimpleCategoryIcon",
    components: {
        ResponsibleConsumptionProductionIcon,
        SustainableCitiesCommunitiesIcon,
        ReducedInequalitiesIcon,
        IndustryInnovationInfrastructureIcon,
        VottifyIcon,
        LifeOnLandIcon,
        DecentWorkEconomicGrowthIcon,
        CleanWaterSanitationIcon,
        GenderEqualityIcon,
        QualityEducationIcon,
        ZeroHungerIcon,
        PartnershipForTheGoalsIcon,
        PeaceJusticeStrongInstitutionIcon,
        SocialMediaTrendingIcon,
        AffordableCleanEnergyIcon,
        NoPovertyIcon,
        LifeBelowWaterIcon,
        GoodHealthWellBeingIcon,
        ClimateActionIcon,
    },
    props: {
        alternativeId: {
            type: String,
            required: true,
        },
    },
    computed: {

    },
    methods: {

    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette";

.category-icon {
    width: 42px;
    height: 42px;

    border-radius: 50%;
}

.life-on-land {
    background-color: rgb(89,186,72);
}

.life-below-water {
    background-color: rgb(31,151,212);
}

.peace-justice-institution {
    background-color: rgb(18,106,159);
}

.no-poverty {
    background-color: rgb(229,35,61);
}

.affordable-clean-energy {
    background-color: rgb(251,196,18);
}

.social-media-trending {
    background-image: linear-gradient(45deg, rgb(241, 210, 90), rgb(215, 69, 60), rgb(155, 71, 150));
}

.industry-innovation-infrastructure {
    background-color: rgb(215, 69, 60);
}

.zero-hunger {
    background-color: rgb(221,167,58);
}

.quality-education {
    background-color: rgb(197,25,45);
}

.gender-equality {
    background-color: rgb(239,64,44);
}

.clean-water-sanitation {
    background-color: rgb(39,191,230);
}

.decent-work-economic-growth {
    background-color: rgb(163,28,68);
}

.partnerships-for-the-goals {
    background-color: rgb(19,73,107);
}

.good-health-well-being {
    background-color: rgb(76,161,70);
}

.climate-action {
    background-color: rgb(64,127,70);
}

.reduced-inequalities {
    background-color: rgb(224,20,131);
}

.sustainable-cities-communities {
    background-color: rgb(248,157,42);
}

.responsible-consumption-production {
    background-color: rgb(191,141,44);
}

.vottify {
    background-image: linear-gradient(45deg, #279EFF, #3085C3);
}

.climate-action-icon,
.good-health-well-being-icon,
.life-below-water-icon,
.no-poverty-icon,
.affordable-clean-energy-icon,
.social-media-trending-icon,
.peace-justice-strong-institution-icon,
.partnerships-for-the-goals-icon,
.industry-innovation-infrastructure-icon,
.zero-hunger-icon,
.quality-education-icon,
.gender-equality-icon,
.clean-water-sanitation-icon,
.decent-work-economic-growth-icon,
.life-on-land-icon,
.reduced-inequalities-icon,
.sustainable-cities-communities-icon,
.responsible-consumption-production-icon,
.vottify-icon {
    width: 38px;
    height: auto;

    color: rgb(255, 255, 255);
}

.no-poverty-icon {
    width: 29px;
}
</style>
