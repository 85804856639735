<template>
    <div class="flex flex--100 user-notification" :class="modifiers" @click="interact">
        <div class="flex flex--100 flex--y-align-center">
            <div class="flex image-section">
                <SimpleCategoryIcon alternative-id="Vottify"/>
            </div>
            <div class="flex main-section">
                <p class="message">
                    {{ message }}
                </p>
                <div class="flex flex--100">
                    <span class="notification-creation-date">
                        <template v-if="visibleCreatedAt === '0m'">
                            Just now
                        </template>
                        <template v-else>
                            {{ visibleCreatedAt }} ago
                        </template>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {timeToCompactString} from "@/utilities/Date";
import {userManager} from "@/UserManager";
import ApplicationIcon from "@/components/ApplicationIcon.vue";
import SimpleCategoryIcon from "@/components/categories/SimpleCategoryIcon.vue";
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";

export default {
    name: "UserNotification",
    components: {
        SimpleCategoryIcon,
        ApplicationIcon,
    },
    props: {
        notification: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapStores(useUserStore),

        modifiers () {
            return {
                "user-notification--unseen": !this.hasSeen,
            };
        },

        id () {
            return this.notification.id;
        },

        hasSeen () {
            return !!this.notification.seenAt;
        },

        message () {
            if (this.notification.message) {
                return this.notification.message;
            }

            return "";
        },

        createdAt () {
            return new Date(this.notification.createdAt);
        },

        visibleCreatedAt () {
            return timeToCompactString(Date.now() - this.createdAt.getTime());
        },
    },
    methods: {
        async interact () {
            if (!this.hasSeen) {
                const {
                    notification,
                    user,
                } = await userManager.hitNotificationView(this.id);

                this.userStore.update(user);
                this.$emit("update:notification", notification);
            }

            /*
            switch (this.actionType) {
                case "ToPoll": {
                    this.$router.push(`/navigation/polls/${this.descriptor.pollId}`);

                    return;
                }
                case "ToUser": {
                    this.$router.push(`/navigation/users/${this.descriptor.userId}`);

                    return;
                }
                case "CreatePoll": {
                    this.$router.push(`/poll/new`);

                    return;
                }
            }*/
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.user-notification {
    cursor: pointer;

    margin: 0;
    padding: 20px;

    & + & {
        border-top: $base-border;
    }

    &--unseen {
        .message {
            font-weight: 700;
        }

        .notification-creation-date {
            font-weight: 600;
        }

        .image-section {
            display: initial;
        }
    }
}

.message {
    margin: 0;

    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.02rem;

    color: $base-text-color;
}

.notification-creation-date {
    margin-top: 6px;

    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.02rem;
    color: rgba(77, 77, 77, 0.7);
}

.image-section {
    display: none;
}

.main-section {
    flex: 1;
}

::v-deep(.simple-category-icon) {
    margin-right: 15px;

    .category-icon {
        width: 33px;
        height: 33px;
    }
}
</style>
