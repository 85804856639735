<template>
    <svg fill="currentColor" class="flex climate-action-icon" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 100 100">
        <path d="M50 26.5c-18.965 0-39.5 17.618-39.5 23.5S31.035 73.5 50 73.5c18.966 0 39.5-17.618 39.5-23.5S68.966 26.5 50 26.5zm0 44c-18.744 0-36.425-17.601-36.5-20.499.075-2.9 17.756-20.501 36.5-20.501s36.425 17.601 36.5 20.499C86.425 52.899 68.744 70.5 50 70.5zm0-38c-9.649 0-17.5 7.851-17.5 17.5S40.351 67.5 50 67.5 67.5 59.649 67.5 50 59.649 32.5 50 32.5zm12.899 10.909h-5.048c-.496-2.682-1.291-5.002-2.314-6.805a14.577 14.577 0 0 1 7.362 6.805zM64.5 50c0 1.241-.174 2.44-.468 3.591H58.27c.107-1.153.163-2.353.163-3.591s-.056-2.438-.163-3.591h5.761c.295 1.151.469 2.35.469 3.591zM50 64.5c-1.82 0-3.787-3.131-4.78-7.909h9.56C53.787 61.369 51.82 64.5 50 64.5zm-5.241-10.909c-.12-1.142-.192-2.339-.192-3.591s.072-2.448.192-3.591h10.483c.12 1.142.192 2.339.192 3.591s-.072 2.448-.192 3.591H44.759zM35.5 50c0-1.241.174-2.44.468-3.591h5.762c-.107 1.153-.163 2.353-.163 3.591s.056 2.438.163 3.591h-5.762A14.475 14.475 0 0 1 35.5 50zM50 35.5c1.82 0 3.787 3.131 4.78 7.909h-9.56c.993-4.778 2.96-7.909 4.78-7.909zm-5.538 1.104c-1.022 1.803-1.818 4.122-2.313 6.805h-5.048a14.578 14.578 0 0 1 7.361-6.805zm-7.361 19.987h5.048c.496 2.682 1.291 5.002 2.313 6.805a14.578 14.578 0 0 1-7.361-6.805zm18.437 6.805c1.022-1.803 1.818-4.122 2.314-6.805H62.9a14.584 14.584 0 0 1-7.362 6.805z"/>
    </svg>
</template>

<script>
export default {
    name: "ClimateActionIcon",
};
</script>
