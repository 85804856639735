<template>
    <div class="flex flex--100 website-products-menu">
        <!--
        <div class="flex flex--100 primary-section">
            <div class="flex flex--100 button button--highlighted">
                <div class="flex flex--100">
                    <ApplicationIcon/>
                    <h2 class="button__title">Product Overview</h2>
                </div>
                <div class="flex flex--100">
                    <p class="button__paragraph">
                        Vottify is built to handle every use case and need. Explore our product
                        to learn how Vottify can work for you.
                    </p>
                </div>
            </div>
        </div>
        -->
        <div class="flex flex--100 secondary-section">
            <div class="flex flex--100 secondary-section__row">

                <div class="flex button" @click="toForNongovernmentalOrganisationsCharitiesView">
                    <div class="flex flex--100">
                        <ApplicationIcon/>
                        <h2 class="button__title">For NGOs & Charities</h2>
                    </div>
                    <div class="flex flex--100">
                        <p class="button__paragraph">
                            Engage supporters, build movements, and amplify your impact, conduct polls,
                            call to action, fundraisings and petitions.
                        </p>
                    </div>
                </div>

                <div class="flex button" @click="toForFoundationsView">
                    <div class="flex flex--100">
                        <ApplicationIcon/>
                        <h2 class="button__title">For Foundations</h2>
                    </div>
                    <div class="flex flex--100">
                        <p class="button__paragraph">
                            Drive engagement for meaningful impact, understand the needs and opinions of the communities you serve.
                        </p>
                    </div>
                </div>
                <div class="flex button" @click="toForThinkTanksView">
                    <div class="flex flex--100">
                        <ApplicationIcon/>
                        <h2 class="button__title">For Think Tanks</h2>
                    </div>
                    <div class="flex flex--100">
                        <p class="button__paragraph">
                            Turn data into actionable insights for policy & research,
                            create targeted polls to gather public opinion on political, economic,
                            or social issues.
                        </p>
                    </div>
                </div>
            </div>
            <div class="flex flex--100 secondary-section__row">

                <div class="flex button" @click="toForCompaniesView">
                    <div class="flex flex--100">
                        <ApplicationIcon/>
                        <h2 class="button__title">For Companies</h2>
                    </div>
                    <div class="flex flex--100">
                        <p class="button__paragraph">
                            Uncover our innovative methods to measure customer satisfaction,
                            conduct social polls, quantify opinions, and make strategic decisions.
                        </p>
                    </div>
                </div>

                <div class="flex button" @click="toForPublicFiguresContentCreators">
                    <div class="flex flex--100">
                        <ApplicationIcon/>
                        <h2 class="button__title">For Creators & Public Figures</h2>
                    </div>
                    <div class="flex flex--100">
                        <p class="button__paragraph">
                            Transform engagement into real-time insights & action,
                            discover what content resonates most and create high engagement.
                        </p>
                    </div>
                </div>

                <div class="flex button" @click="toForInternationalOrganisationsView">
                    <div class="flex flex--100">
                        <ApplicationIcon/>
                        <h2 class="button__title">For International Organisations</h2>
                    </div>
                    <div class="flex flex--100">
                        <p class="button__paragraph">
                            Harness global voices & drive solutions forward, conduct polls
                            and gather insights on international issues and initiatives.
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, } from "vue";
import ApplicationIcon from "@/components/ApplicationIcon.vue";

export default defineComponent({
    name: "WebsiteProductsMenu",
    components: {
        ApplicationIcon,
    },
    methods: {
        toForCompaniesView () {
            this.$router.push("/products/for-companies");
        },

        toForNongovernmentalOrganisationsCharitiesView () {
            this.$router.push("/products/for-ngos-and-charities");
        },

        toForPublicFiguresContentCreators () {
            this.$router.push("/products/for-public-figures-and-content-creators");
        },

        toForFoundationsView () {
            this.$router.push("/products/for-foundations");
        },

        toForInternationalOrganisationsView () {
            this.$router.push("/products/for-international-organisations");
        },

        toForThinkTanksView () {
            this.$router.push("/products/for-think-tanks");
        },
    },
});
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.website-products-menu {
    padding: 51px 0;
}

.button {
    cursor: pointer;
    transition: background-color 120ms;

    padding: 21px 27px;

    //background-image: radial-gradient(ellipse, rgb(255, 255, 255) 0%, rgba($brand-color, 0.23) 100%);
    border-radius: 17px;

    .application-icon {
        width: 17px;
        margin-right: 11px;

        transform: scaleX(-1);
    }

    &__title {
        flex: 1;
        margin: 0;

        font-size: 21px;
        font-weight: 600;

        color: rgba(0, 0, 0, 0.8);
    }

    &__paragraph {
        margin: 11px 0 0 0;

        font-size: 15px;
        font-weight: 400;

        color: rgba(71, 71, 71, 0.8);
    }

    &--highlighted {
        background-color: rgb(243, 243, 243);
    }

    &:hover {
        background-color: rgba($brand-color, 0.2);
    }
}

.primary-section {
    .button {
        max-width: 471px;
    }
}

.secondary-section {
    .button {
        flex: 1 1 100%;

        max-width: 383px;
    }
    .button + .button {
        margin-left: 13px;
    }

    &__row {
        align-items: stretch;
    }
    &__row + &__row {
        margin-top: 21px;
    }
}
</style>
