<template>
    <div class="flex flex--100 simple-category-selector">

        <div class="flex flex--100 search-section">
            <IonSearchbar class="searchbar" v-model="searchQuery"/>
        </div>
        <div class="flex flex--100 main-section">
            <div
                class="flex flex--100 categories-section"
                ref="categoriesSection"
            >
                <div
                    class="flex flex--y-align-center category"
                    v-for="category in visibleCategories"
                    :key="category.id"
                    :class="getCategoryModifiers(category)"
                    @click="toggleCategorySelection(category)"
                >
                    <div class="flex flex--50">
                        {{ category.name }}
                    </div>
                    <div class="flex flex--50 flex--x-align-end">
                        <SimpleCategoryIcon :alternative-id="category.alternativeId" v-if="category.alternativeId"/>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { cloneDeep, } from "lodash/lang";
import { IonSearchbar } from "@ionic/vue";
import Fuse from "fuse.js";
import SimpleCategoryIcon from "@/components/categories/SimpleCategoryIcon.vue";

export default {
    name: "SimpleCategorySelector",
    components: {
        SimpleCategoryIcon,
        IonSearchbar,
    },
    props: {
        categories: {
            type: Array,
            required: true,
        },
        value: {
            type: Array,
            required: true,
        },
    },
    data () {
        return {
            searchQuery: "",
        };
    },
    computed: {
        fuse () {
            return new Fuse(this.categories, {
                keys: [ "name", ],
            });
        },

        visibleCategories () {
            const searchQuery = this.searchQuery;

            if (!searchQuery) {
                return this.categories;
            }

            return this.fuse.search(searchQuery).map((value) => value.item);
        },
    },
    methods: {
        selectCategory (category) {
            if (this.categoryIsSelected(category)) {
                return;
            }

            this.$emit("update:value", [ ...this.value, category.id, ]);
        },

        deselectCategory (category) {
            if (!this.categoryIsSelected(category)) {
                return;
            }

            const valueCopy = cloneDeep(this.value);

            valueCopy.splice(valueCopy.indexOf(category.id), 1);

            this.$emit("update:value", valueCopy);
        },

        toggleCategorySelection (category) {
            if (!this.categoryIsSelected(category)) {
                this.selectCategory(category);
            }
            else {
                this.deselectCategory(category);
            }
        },

        categoryIsSelected (category) {
            return this.value.includes(category.id);
        },

        getCategoryModifiers (category) {
            return {
                "category--selected": this.categoryIsSelected(category),
            };
        },
    },
    watch: {
        // Used to reset scrolling when searching new elements
        searchQuery () {
            this.$refs.categoriesSection.scrollLeft = 0;
            this.$refs.categoriesSection.scrollTop = 0;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.simple-category-selector {

}

.search-section {

}

.searchbar {
    padding: 40px 20px;
}

.main-section {
    overflow-y: scroll;

    background-color: rgb(255, 255, 255);

    &::-webkit-scrollbar {
        display: none;
    }
}

.categories-section {
    height: 40vh;

    margin: 0;
    padding: 0;
}

.category {
    -webkit-user-select: none;
    user-select: none;

    will-change: border-color, box-shadow, background-color;
    transition: border-color 140ms, box-shadow 140ms, background-color 140ms;

    cursor: pointer;

    width: 100%;
    margin: 0;
    padding: 20px;

    background-color: rgb(255, 255, 255);
    border-top: 1px solid rgb(240, 240, 240);
    border-bottom: 1px solid rgb(240, 240, 240);

    font-weight: 700;
    font-size: 13px;
    color: $base-text-color;

    letter-spacing: 0.008rem;

    &--selected {
        background-color: $brand-color;

        color: rgb(255, 255, 255);
    }

    & + & {
        margin-top: 0;
        border-top: none;
    }
}

.simple-category-icon {
    ::v-deep(.category-icon) {
        width: 32px;
        height: 32px;
    }
}
</style>
