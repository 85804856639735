import { userManager } from "@/UserManager";
import { defineStore, } from "pinia";
import { computed, Ref, ref, } from "vue";

export type Category = {
    id: string;
    alternativeId: string;
    name: string;
    description: string;
    isArchived: boolean;
    isSdg: boolean;
};

export const useCategoriesStore = defineStore("categories", () => {
    const categories: Ref<Record<string, Category>> = ref({});
    const all = computed(() => Object.values(categories.value));

    return {
        all,
        categories,

        update (category: Category): Category {
            return categories.value[category.id] = {
                ...categories.value[category.id] ?? {},
                ...category,
            };
        },

        updateMany (categories: Category[]): void {
            for (const category of categories) {
                this.update(category);
            }
        },

        async fetch (): Promise<void> {
            const response = await userManager.categories.getAll();
            const { categories, } = response.data.result;

            this.updateMany(categories);
        },
    };
});
