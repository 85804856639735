<template>
    <div class="flex flex--100 poll-results-guide" :class="modifiers">
        <div class="flex flex--100 flex--x-align-center main-section">
            <!-- <feed-switcher> -->
            <div class="flex flex--100 flex--x-align-center flex--y-align-center feed-selection-section">
                <span class="feed-type-button feed-type-button--disabled">
                    For You
                </span>
                <span class="feed-type-button feed-type-button--selected">
                    Results
                    <span class="swipe-up">
                        <HandSwipeUpIcon/>
                    </span>
                </span>
            </div>
            <!-- </feed-switcher> -->
            <h2 class="title">
                Voted Polls are in<br>
                Results Feed
            </h2>
        </div>
    </div>
</template>

<script>
import { defineComponent, } from "vue";
import HandSwipeDownIcon from "@/components/icons/HandSwipeDownIcon.vue";
import HandSwipeUpIcon from "@/components/icons/HandSwipeUpIcon.vue";

export default defineComponent({
    name: "PollResultsGuide",
    components: {
        HandSwipeUpIcon,
        HandSwipeDownIcon,
    },
    data () {
        return {
            isOpen: false,
        };
    },
    computed: {
        modifiers () {
            return {
                "poll-results-guide--open": this.isOpen,
            };
        },
    },
    methods: {
        show () {
            this.isOpen = true;
        },
        hide () {
            this.isOpen = false;
        },
    },
});
</script>

<style lang="scss" scoped>
@import "@/theme/globals.scss";

.poll-results-guide {
    transition: opacity 333ms;

    pointer-events: none;
    position: fixed;
    left: 0;
    top: 0;

    width: 100%;
    height: 100vh;

    z-index: 2147483647;

    background-color: rgba(0, 0, 0, 0.9);

    opacity: 0;

    &--open {
        opacity: 1;
    }
}

.main-section {
    position: relative;

    height: 100%;
}

.hand-swipe-up-icon {
    width: 51px;

    color: rgb(255, 255, 255);
}

.swipe-up {
    will-change: opacity;
    animation: glow 2.8s ease-in-out infinite;

    position: absolute;
    left: 50%;
    top: 17px;

    transform: translateX(-50%);

    @keyframes glow {
        0% {
            opacity: 0.3;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0.3;
        }
    }
}

.title {
    position: absolute;
    top: 30%;

    max-width: 80%;

    letter-spacing: 0.01rem;
    font-size: 31px;
    line-height: 37px;
    font-weight: 800;
    color: rgb(255, 255, 255);
    text-align: center;
}

.feed-type-button {
    transition:
        color 190ms,
        background-color 190ms,
        border-color 190ms;

    cursor: pointer;
    user-select: none;

    position: relative;

    margin: 0 7px;
    padding: 5px 17px;

    background-color: rgb(14, 14, 16);
    border: 1.5px solid rgb(38, 38, 40);
    border-radius: 999px;

    font-weight: 600;
    letter-spacing: 0.02rem;
    font-size: 13px;
    color: rgb(110, 110, 110);

    &--selected {
        background-color: rgb(19, 19, 20);
        border-color: rgb(90, 89, 91);

        color: rgb(255, 255, 255);
    }
    &--disabled {
        cursor: default;

        opacity: 0.5;
    }
}

.feed-selection-section {
    position: absolute;
    left: 0;
    top: env(safe-area-inset-top);

    height: $feed-selection-section-height;

    background-color: transparent;

    z-index: 10;
}
</style>
