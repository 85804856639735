<template>
    <IonPage>
    <IonHeader>
        <IonToolbar class="ion-toolbar">
            <IonButtons slot="start">
                <IonBackButton/>
            </IonButtons>
            <IonTitle>Poll Statistics</IonTitle>
        </IonToolbar>
    </IonHeader>
    <IonContent class="ion-content">
        <div class="flex flex--100 simple-poll-statistics" :class="pollStatisticsModifiers" v-if="!isLoading">
            <div class="flex flex--100 header-section">
                <!-- <title> -->
                <div class="flex flex--100 question-section">
                    <span class="question">{{ question }}</span>
                </div>
                <!-- </title> -->
                <!-- <privacy> -->
                <div class="flex flex--100 privacy-section">
                    <div class="privacy">
                        Votes are anonymous.
                        <span class="privacy-link" @click="toPollFaqView">Learn more</span>.
                    </div>
                </div>
                <!-- </privacy> -->
            </div>
            <div class="flex flex--100 main-section">
                <div class="flex flex--100 options-section">
                    <div
                        class="flex flex--100 option-section"
                        v-for="(option, i) in options"
                        :key="i"
                    >
                        <div
                            class="flex option"
                            :class="getOptionModifiers(i)"
                            :style="getOptionStyle(i)"
                            @click="selectOption(i)"
                        >
                            <span class="option-text">
                                <!-- TODO: Separate text from emojis in a computed property, then invert the emojis filter -->
                                {{ getVisibleOption(option) }}
                            </span>
                        </div>
                        <div class="votes-percentage" v-if="votesAreVisible">
                            {{ numberToCompactString(votesCounters[i]) }} · {{ getOptionVotesPercentage(i) }}%
                        </div>
                    </div>
                </div>
                <div class="flex flex--100 metrics-section">
                    <div class="flex flex--50">
                        <span class="metric">
                            <span class="highlighted-value">{{ visibleTotalVotesCounter }}</span>
                            votes
                            ·
                            <template v-if="isClosed">
                            <span class="highlighted-value">Final results</span>
                            </template>
                            <template v-else>
                            <span class="highlighted-value">{{ visibleRemainingTime }}</span>
                            left
                            </template>
                        </span>
                    </div>
                    <div class="flex flex--50 flex--x-align-end">
                        <span class="metric">
                            <span class="highlighted-value">{{ visibleViewsCounter }}</span>
                            views
                        </span>
                    </div>
                </div>
            </div>
            <div class="flex flex--100 granular-section">
                <!-- <gender> -->
                <div class="flex flex--100 metric-chart-section">
                    <div class="flex flex--100 flex--y-align-center">
                        <h2 class="flex granular-title">
                            Votes by Gender
                        </h2>
                    </div>
                    <div class="flex flex--100 flex--x-align-center">
                        <div
                            class="flex chart-section"
                            :class="chartSectionModifiers(analyticsVisibility.byGender)"
                        >
                            <SimpleBarChart
                                :data="votesByGenderChartData.data"
                                :labels="votesByGenderChartData.labels"
                                base-color="31, 108, 225"
                            />
                        </div>
                    </div>
                </div>
                <!-- </gender> -->
                <!-- <age-range> -->
                <div class="flex flex--100 metric-chart-section">
                    <div class="flex flex--100">
                        <h2 class="flex granular-title">
                            Votes By Age Range
                        </h2>
                    </div>
                    <div class="flex flex--100 flex--x-align-center">
                        <div
                            class="flex chart-section"
                            :class="chartSectionModifiers(analyticsVisibility.byAgeRange)"
                        >
                            <SimpleBarChart
                                :data="votesByAgeRangeChartData.data"
                                :labels="votesByAgeRangeChartData.labels"
                                base-color="142,69,206"
                            />
                            <span
                                class="flex upgrade-button"
                                @click="toCreatorPlansView"
                                v-if="!analyticsVisibility.byAgeRange"
                            >
                                Upgrade to Light
                            </span>
                        </div>
                    </div>
                </div>
                <!-- </age-range> -->
                <!-- <country> -->
                <div class="flex flex--100 metric-chart-section">
                    <div class="flex flex--100 flex--y-align-center">
                        <h2 class="flex granular-title">
                            Votes By Country
                        </h2>
                        <span
                            class="flex upgrade-button"
                            @click="toCreatorPlansView"
                            v-if="!analyticsVisibility.byCountry"
                        >
                            Upgrade to Standard
                        </span>
                    </div>
                    <div class="flex flex--100 flex--x-align-center">
                        <div
                            class="flex chart-section"
                            :class="chartSectionModifiers(analyticsVisibility.byCountry)"
                        >
                            <SimpleBarChart
                                :data="votesByCountryChartData.data"
                                :labels="votesByCountryChartData.labels"
                                base-color="48,183,174"
                            />
                        </div>
                    </div>
                </div>
                <!-- </country> -->
                <!-- <education> -->
                <div class="flex flex--100 metric-chart-section">
                    <div class="flex flex--100 flex--y-align-center">
                        <h2 class="flex granular-title">
                            Votes By Education
                        </h2>
                        <span
                            class="flex upgrade-button"
                            @click="toCreatorPlansView"
                            v-if="!analyticsVisibility.byEducation"
                        >
                            Upgrade to Standard
                        </span>
                    </div>
                    <div class="flex flex--100 flex--x-align-center">
                        <div
                            class="flex chart-section"
                            :class="chartSectionModifiers(analyticsVisibility.byEducation)"
                        >
                            <SimpleBarChart
                                :data="votesByEducationChartData.data"
                                :labels="votesByEducationChartData.labels"
                                base-color="241,170,5"
                            />
                        </div>
                    </div>
                </div>
                <!-- </education> -->
                <!-- <income-range> -->
                <div class="flex flex--100 metric-chart-section">
                    <div class="flex flex--100 flex--y-align-center">
                        <h2 class="flex granular-title">
                            Votes By Income Range
                        </h2>
                        <span
                            class="flex upgrade-button"
                            @click="toCreatorPlansView"
                            v-if="!analyticsVisibility.byIncomeRange"
                        >
                            Upgrade to Premium
                        </span>
                    </div>
                    <div class="flex flex--100 flex--x-align-center">
                        <div
                            class="flex chart-section"
                            :class="chartSectionModifiers(analyticsVisibility.byIncomeRange)"
                        >
                            <SimpleBarChart
                                :data="votesByIncomeRangeChartData.data"
                                :labels="votesByIncomeRangeChartData.labels"
                                base-color="73,166,52"
                            />
                        </div>
                    </div>
                </div>
                <!-- </income-range> -->
                <!-- <industry> -->
                <div class="flex flex--100 metric-chart-section">
                    <div class="flex flex--100 flex--y-align-center">
                        <h2 class="flex granular-title">
                            Votes By Industry
                        </h2>
                        <span
                            class="flex upgrade-button"
                            @click="toCreatorPlansView"
                            v-if="!analyticsVisibility.byIndustry"
                        >
                            Upgrade to Premium
                        </span>
                    </div>
                    <div class="flex flex--100 flex--x-align-center">
                        <div
                            class="flex chart-section"
                            :class="chartSectionModifiers(analyticsVisibility.byIndustry)"
                        >
                            <SimpleBarChart
                                :data="votesByIndustryChartData.data"
                                :labels="votesByIndustryChartData.labels"
                                base-color="255,87,34"
                            />
                        </div>
                    </div>
                </div>
                <!-- </industry> -->
                <!-- <religion> -->
                <div class="flex flex--100 metric-chart-section">
                    <div class="flex flex--100 flex--y-align-center">
                        <h2 class="flex granular-title">
                            Votes By Religion
                        </h2>
                        <span
                            class="flex upgrade-button"
                            @click="toCreatorPlansView"
                            v-if="!analyticsVisibility.byReligion"
                        >
                            Upgrade to Premium
                        </span>
                    </div>
                    <div class="flex flex--100 flex--x-align-center">
                        <div
                            class="flex chart-section"
                            :class="chartSectionModifiers(analyticsVisibility.byReligion)"
                        >
                            <SimpleBarChart
                                :data="votesByReligionChartData.data"
                                :labels="votesByReligionChartData.labels"
                                base-color="192,12,9"
                            />
                        </div>
                    </div>
                </div>
                <!-- </religion> -->
            </div>
            <!--
            <div class="flex flex--100 flex--x-align-center flex--y-align-center no-votes-section" v-else>
                <h2 class="no-votes-section__title">No votes yet</h2>
            </div>
            -->
        </div>
        <span class="flex flex--100 bottom-navigation-area"></span>
        <hr class="safe-bottom-area"/>
    </IonContent>
    </IonPage>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonList,
    IonListHeader,
    IonItem,
    IonLabel,
    IonButtons,
    IonBackButton,
    IonButton,
    IonFooter,
    IonTextarea,
    IonSegment,
    IonSegmentButton,
} from "@ionic/vue";

import { userManager, } from "@/UserManager";
import {mapStores} from "pinia";
import {useUserStore} from "@/stores/UserStore";
import SimplePoll from "@/components/polls/SimplePoll.vue";
import CommentIcon from "@/components/icons/CommentIcon.vue";
import SimplePollComment from "@/components/polls/comments/SimplePollComment.vue";
import SimplePollComments from "@/components/polls/comments/SimplePollComments.vue";
import {pollsStore} from "@/main";
import {
    EducationType,
    GenderType,
    IncomeRangeType,
    numberToCompactString, ReligionType,
    AgeRangeType, IndustryType,
} from "@/utilities/Utilities";
import UserAvatar from "@/components/UserAvatar.vue";
import SimpleCategoryIcon from "@/components/categories/SimpleCategoryIcon.vue";
import VerifiedBadgeIcon from "@/components/icons/VerifiedBadgeIcon.vue";
import ApplicationIcon from "@/components/ApplicationIcon.vue";
import ShareIcon from "@/components/icons/ShareIcon.vue";
import {timeToCompactString} from "@/utilities/Date";
import { countries, shortenCountryName, } from "@/utilities/Countries";
import SimpleBarChart from "@/components/charts/SimpleBarChart.vue";

const OPTION_MAX_LENGTH = 30;

export default {
    name: "PollStatisticsView",
    components: {
        SimpleBarChart,
        ShareIcon, ApplicationIcon, VerifiedBadgeIcon, SimpleCategoryIcon, UserAvatar,
        SimplePollComments,
        SimplePollComment,
        CommentIcon,
        SimplePoll,
        IonPage,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonList,
        IonListHeader,
        IonItem,
        IonLabel,
        IonButtons,
        IonBackButton,
        IonButton,
        IonFooter,
        IonTextarea,
        IonSegment,
        IonSegmentButton,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            isLoading: true,
            selectedOptionIndex: 0,
        };
    },
    computed: {
        StatisticsAgeRangeType () {
            return AgeRangeType;
        },
        GenderType () {
            return GenderType;
        },
        ...mapStores(useUserStore),

        analyticsVisibility () {
            return this.userStore.entitlements.analyticsVisibility;
        },

        votesByCountryChartData () {
            const selectedOptionStatistics = this.statistics[this.selectedOptionIndex];
            const labels = [];
            const data = [];

            Object.keys(selectedOptionStatistics.byCountry).forEach((countryIso) => {
                const matchedCountry =
                    countries.find((country) => country.iso2.toUpperCase() === countryIso.toUpperCase());

                if (matchedCountry) {
                    labels.push(`${matchedCountry.emojiIcon} ${shortenCountryName(matchedCountry.name)}`);
                }
                else {
                    labels.push(countryIso);
                }
            });

            for (const value of Object.values(selectedOptionStatistics.byCountry)) {
                data.push(value);
            }

            data.sort((a, b) => b - a);

            return {
                labels,
                data,
            };
        },

        votesByGenderChartData () {
            const labels = [];
            const data = [];

            for (const value of Object.values(GenderType)) {
                labels.push(this.$t(`genderType.${value}`));
                data.push(this.statistics[this.selectedOptionIndex].byGender[value] ?? 0);
            }

            data.sort((a, b) => b - a);

            return {
                labels,
                data,
            };
        },

        votesByAgeRangeChartData () {
            const labels = [];
            const data = [];

            for (const value of Object.values(AgeRangeType)) {
                labels.push(this.$t(`ageRangeType.${value}`));
                data.push(this.statistics[this.selectedOptionIndex].byAgeRange[value] ?? 0);
            }

            data.sort((a, b) => b - a);

            return {
                labels,
                data,
            };
        },

        votesByIncomeRangeChartData () {
            const labels = [];
            const data = [];

            for (const value of Object.values(IncomeRangeType)) {
                labels.push(this.$t(`incomeRangeType.${value}`));
                data.push(this.statistics[this.selectedOptionIndex].byIncomeRange[value] ?? 0);
            }

            data.sort((a, b) => b - a);

            return {
                labels,
                data,
            };
        },

        votesByEducationChartData () {
            const labels = [];
            const data = [];

            for (const value of Object.values(EducationType)) {
                labels.push(this.$t(`educationType.${value}`));
                data.push(this.statistics[this.selectedOptionIndex].byEducation[value] ?? 0);
            }

            data.sort((a, b) => b - a);

            return {
                labels,
                data,
            };
        },

        votesByReligionChartData () {
            const labels = [];
            const data = [];

            for (const value of Object.values(ReligionType)) {
                labels.push(this.$t(`religionType.${value}`));
                data.push(this.statistics[this.selectedOptionIndex].byReligion[value] ?? 0);
            }

            data.sort((a, b) => b - a);

            return {
                labels,
                data,
            };
        },

        votesByIndustryChartData () {
            const labels = [];
            const data = [];

            for (const value of Object.values(IndustryType)) {
                labels.push(this.$t(`industryType.${value}`));
                data.push(this.statistics[this.selectedOptionIndex].byIndustry[value] ?? 0);
            }

            data.sort((a, b) => b - a);

            return {
                labels,
                data,
            };
        },

        loggedUser () {
            return this.userStore.user;
        },

        poll () {
            return pollsStore.get(this.id);
        },

        metrics () {
            return this.poll.metrics;
        },

        statistics () {
            return this.poll.statistics;
        },

        // Total votes counter
        totalVotesCounter () {
            let totalVotesCounter = 0;

            for (const optionVotesCounter of this.votesCounters) {
                totalVotesCounter += optionVotesCounter;
            }

            return totalVotesCounter;
        },

        // Votes counters for each option
        votesCounters () {
            return this.metrics.votesCounters;
        },

        visibleViewsCounter () {
            return numberToCompactString(this.viewsCounter);
        },

        visibleTotalVotesCounter () {
            return numberToCompactString(this.totalVotesCounter);
        },

        pollStatisticsModifiers () {
            return {
                "simple-poll-statistics--closed": this.isClosed,
                "simple-poll-statistics--visible-votes": this.votesAreVisible,
            };
        },

        votesAreVisible () {
            return true;
        },

        question () {
            return this.poll.question;
        },

        options () {
            return this.poll.options;
        },

        createdAt () {
            return new Date(this.poll.createdAt);
        },

        expireAt () {
            return new Date(this.poll.expireAt);
        },

        viewsCounter () {
            return this.metrics.viewsCounter;
        },

        remainingTimeMs () {
            return this.expireAt.getTime() - Date.now();
        },

        isClosed () {
            return Date.now() >= this.expireAt.getTime();
        },

        visibleCreatedAt () {
            return timeToCompactString(Date.now() - this.createdAt.getTime());
        },

        visibleRemainingTime () {
            const ms = this.remainingTimeMs;

            if (ms <= 0) {
                return "";
            }

            return timeToCompactString(ms);
        },

        selectedOptionVotes () {
            return this.votesCounters[this.selectedOptionIndex];
        },
    },
    methods: {
        numberToCompactString,

        toCreatorPlansView () {
            this.$router.push("/creator/plans");
        },

        chartSectionModifiers (isVisible) {
            return {
                "chart-section--blurred": !isVisible,
            };
        },

        async fetch () {
            this.isLoading = true;

            pollsStore.update(await userManager.getPoll(this.id));

            this.isLoading = false;
        },

        getVisibleOption (option) {
            if (option.length <= OPTION_MAX_LENGTH) {
                return option;
            }

            return `${option.substring(0, OPTION_MAX_LENGTH)}...`;
        },

        getOptionVotesPercentage (optionIndex) {
            const optionVotes = this.votesCounters[optionIndex];

            if (optionVotes === 0) {
                return 0;
            }

            return Number(Math.round(optionVotes / this.totalVotesCounter * 100).toFixed(0));
        },

        getOptionModifiers (optionIndex) {
            return {
                "option--selected": this.selectedOptionIndex === optionIndex,
            };
        },

        getOptionStyle (optionIndex) {
            const style = {};

            if (this.votesAreVisible) {
                const votedPercentage = Math.max(this.getOptionVotesPercentage(optionIndex), 1);

                style["--background-width"] = `${votedPercentage}%`;
            }

            return style;
        },

        toPollFaqView () {
            this.$router.push("/poll/faq");
        },

        selectOption (i) {
            this.selectedOptionIndex = i;
        },

        getVotesPercentage (votes) {
            if (votes === 0) {
                return 0;
            }

            return Math.round(votes / this.selectedOptionVotes * 100);
        },
    },
    created () {
        this.fetch();
    },
    ionViewWillEnter () {
        this.fetch();
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";
@import "@/theme/paywall.scss";
@import "@/theme/globals.scss";

.ion-toolbar {
    --background: rgb(0, 0, 0);
    --border-color: rgb(33, 33, 33);
    --ion-text-color: rgb(255, 255, 255);
    --ion-text-color-rgb: 255, 255, 255;
}

.ion-content {
    &::part(background) {
        background-color: rgb(0, 0, 0);
    }
}

.simple-poll-statistics {
    flex-direction: column;

    min-height: 100%;
}

.poll-creation-date {
    font-size: 12px;
    letter-spacing: 0.02rem;
    color: rgb(122, 122, 122);
}

.header-section {
    margin: 0;
    padding: 20px 20px 5px 20px;

    background-color: rgb(0, 0, 0);
}

.main-section {
    //position: sticky;
    //top: 5px;
    //left: 0;

    margin: 5px;
    padding: 0 20px 20px 20px;

    width: calc(100% - 10px);

    background-color: rgb(19, 19, 19);
    border-radius: 13px;
    box-shadow:
        0 4px 6px rgba(0, 0, 0, 0.3),
        0 10px 15px rgba(0, 0, 0, 0.2),
        0 20px 25px rgba(0, 0, 0, 0.1);

    //z-index: 10;
}

.granular-section {
    margin: 0;
    padding: 15px;

    background-color: rgb(0, 0, 0);
}

.metric-chart-section {
    margin: 0;
    padding: 0;

    & + & {
        margin: 21px 0 0 0;
        padding: 21px 0 0 0;

        border-top: 0.5px solid rgb(33, 33, 33);
    }
}

.question-section {

}
.question {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.008rem;
    color: rgb(255, 255, 255);
}

.privacy-section {
    margin: 4px 0 0 0;
}
.privacy {
    font-size: 11px;
    font-weight: 400;
    color: rgb(255, 255, 255);
}

.options-section {
    margin: 20px 0;
}
.option-section {
    position: relative;

    & + & {
        margin-top: 10px;
    }

    .votes-percentage {
        pointer-events: none;

        position: absolute;
        right: 20px;
        top: 50%;

        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0.02rem;
        color: $base-text-color;

        transform: translateY(-50%);
        filter: invert(1);
        mix-blend-mode: difference;

        z-index: 1;
    }
}
.option {
    --background-width: 100%;

    cursor: pointer;
    position: relative;

    will-change: color;
    transition: color 160ms;

    width: 100%;

    padding: 13px 20px;

    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.02rem;

    z-index: 1;

    &::before {
        content: "";

        will-change: width, background-color, border-color;
        transition: width 160ms, background-color 160ms, border-color 160ms;

        position: absolute;
        left: 0;
        top: 0;

        width: var(--background-width);
        height: 100%;

        background-color: rgb(255, 255, 255);
        border-radius: 8px;

        z-index: -1;
    }

    &-text {
        max-width: calc(100% - 60px);

        color: $base-text-color;

        filter: invert(1);
        mix-blend-mode: difference;
    }
}
.simple-poll-statistics--closed {
    .main-section {
        background-color: rgb(253, 253, 253);
    }
}
.simple-poll-statistics--visible-votes {
    .option {
        &::before {
            background-color: rgb(29, 29, 29);
        }

        &--selected {
            cursor: default;

            &::before {
                background-image:
                    radial-gradient(circle, hsla(210, 100%, 88%, 1) 0%, hsl(0, 0%, 97%) 100%);
            }
        }
    }
}

.privacy-link {
    cursor: pointer;

    font-weight: 600;
    color: $brand-color;
}

.metrics-section {

}

.metric {
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.04rem;
    color: $base-text-color;

    filter: invert(1);
    mix-blend-mode: difference;
}

.highlighted-value {
    font-weight: 700;

    color: rgb(0, 0, 0);
}

.granular-title {
    margin: 0 0 7px 0;
    padding: 0;

    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.008rem;
    color: rgba(243, 243, 243, 0.9);
}

.upgrade-button {
    display: none;

    //@extend .paywall-button;
}

.chart-section {
    width: 100%;

    margin: 0;
    padding: 0;

    z-index: 1;

    &--blurred {
        //@extend .paywall-blur;
    }
}

.no-votes-section {
    cursor: default;
    user-select: none;

    flex-grow: 1;

    &__title {
        margin: 21px;

        font-size: 23px;
        font-weight: 600;
        letter-spacing: 0.01rem;
        color: rgb(0, 0, 0);
        text-align: center;
    }
}

.bottom-navigation-area {
    height: $main-bottom-navigation-height;
}
</style>
