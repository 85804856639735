<template>
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" class="flex gender-equality-icon" xml:space="preserve" viewBox="0 0 100 100">
        <path d="M54.5 49.25a1.5 1.5 0 0 1-1.5 1.5H40a1.5 1.5 0 1 1 0-3h13a1.5 1.5 0 0 1 1.5 1.5zm-1.5-8.5H40a1.5 1.5 0 1 0 0 3h13a1.5 1.5 0 1 0 0-3zm18-18v9a1.5 1.5 0 1 1-3 0v-5.379l-8.121 8.121A17.415 17.415 0 0 1 64 45.75c0 9.143-7.052 16.659-16 17.424v6.076h5a1.5 1.5 0 1 1 0 3h-5v5a1.5 1.5 0 1 1-3 0v-5h-5a1.5 1.5 0 1 1 0-3h5v-6.076c-8.948-.765-16-8.281-16-17.424 0-9.649 7.851-17.5 17.5-17.5 4.286 0 8.212 1.554 11.258 4.121l8.121-8.121H60.5a1.5 1.5 0 1 1 0-3h9a1.5 1.5 0 0 1 1.5 1.5zm-10 23c0-7.995-6.505-14.5-14.5-14.5S32 37.755 32 45.75s6.505 14.5 14.5 14.5S61 53.745 61 45.75z"/>
    </svg>
</template>

<script>
export default {
    name: "GenderEqualityIcon",
};
</script>
