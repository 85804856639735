<template>
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 32 32" class="flex filled-heart-icon">
        <path d="M16,26.2c-1.2,0-11.2-6.8-11.5-13,0-2,.6-3.9,1.9-5.3,1.3-1.3,3-2,4.9-2s3.8,1.1,4.8,2.2c.9-1.1,2.5-2.2,4.8-2.2s3.6.7,4.9,2c1.3,1.3,2,3.2,1.9,5.3-.3,6.2-10.3,13-11.5,13,0,0,0,0,0,0ZM16.3,24.3h0,0Z"/>
    </svg>
</template>

<script>
import { defineComponent, } from "vue";

export default defineComponent({
    name: "FilledHeartIcon",
});
</script>
