<template>
    <IonPage>
    <IonContent>
        <MainNavigationBar/>
        <div class="flex flex--100 flex--x-align-center main">
            <div class="flex flex--100 sign-up-form">
                <div class="flex flex--100 flex--x-align-center">
                    <h2 class="title">
                        Sign up to see polls and comments
                    </h2>
                </div>
            <IonToolbar>
                <!-- <progress-bar> -->
                <div class="flex flex--100">
                    <SimpleProgressBar :progress="progress"/>
                </div>
                <!-- </progress-bar> -->
            </IonToolbar>
            <IonItemGroup class="input-section" v-show="actualStep === 1">
                <IonItem>
                    <IonInput
                        v-model="user.firstName"
                        label="First name"
                        label-placement="floating"
                        autocomplete="given-name"
                    ></IonInput>
                </IonItem>
                <IonItem>
                    <IonInput
                        v-model="user.lastName"
                        label="Last name"
                        label-placement="floating"
                        autocomplete="family-name"
                    ></IonInput>
                </IonItem>
                <IonItem>
                    <IonInput
                        type="number"
                        label="Age"
                        label-placement="floating"
                        v-model="user.age"
                    >
                    </IonInput>
                </IonItem>
            </IonItemGroup>
            <IonItemGroup class="input-section" v-show="actualStep === 2">
                <IonItem>
                    <IonSelect v-model="user.genderType" label="Gender" label-placement="floating" interface="action-sheet">
                        <IonSelectOption
                            v-for="value in Object.values(GenderType)"
                            :key="value"
                            :value="value"
                        >
                            {{ $t(`genderType.${value}`) }}
                        </IonSelectOption>
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonSelect
                        v-model="user.incomeRangeType"
                        label="Income Range"
                        label-placement="floating"
                        interface="action-sheet"
                    >
                        <IonSelectOption
                            v-for="value in Object.values(IncomeRangeType)"
                            :key="value"
                            :value="value"
                        >
                            {{ $t(`incomeRangeType.${value}`) }}
                        </IonSelectOption>
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonSelect v-model="user.educationType" label="Education" label-placement="floating" interface="action-sheet">
                        <IonSelectOption
                            v-for="value in Object.values(EducationType)"
                            :key="value"
                            :value="value"
                        >
                            {{ $t(`educationType.${value}`) }}
                        </IonSelectOption>
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonSelect v-model="user.religionType" label="Religion" label-placement="floating" interface="action-sheet">
                        <IonSelectOption
                            v-for="value in Object.values(ReligionType)"
                            :key="value"
                            :value="value"
                        >
                            {{ $t(`religionType.${value}`) }}
                        </IonSelectOption>
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonSelect v-model="user.industryType" label="Industry" label-placement="floating" interface="action-sheet">
                        <IonSelectOption
                            v-for="value in Object.values(IndustryType)"
                            :key="value"
                            :value="value"
                        >
                            {{ $t(`industryType.${value}`) }}
                        </IonSelectOption>
                    </IonSelect>
                </IonItem>
            </IonItemGroup>
            <IonItemGroup class="input-section" v-show="actualStep === 3">
                <MobileNumberInput
                    v-model:mobile-number="localMobileNumber"
                    v-model:country-iso="countryIso"
                    v-model:dial-code="dialCode"
                ></MobileNumberInput>
                <IonItem>
                    <IonInput
                        type="password"
                        label="Password"
                        label-placement="floating"
                        autocomplete="new-password"
                        v-model="user.password"
                    ></IonInput>
                </IonItem>
                <div class="flex flex--100 flex--x-align-center" v-show="sendMobileNumberVerificationRemainingSeconds === 0">
                    <IonButton
                        class="simple-button"
                        fill="outline"
                        :disabled="!canNext || isWaitingServerResponse"
                        @click="sendMobileNumberVerificationCode"
                    >
                        Send Mobile Number Code
                    </IonButton>
                </div>
                <div class="flex flex--100" v-show="sendMobileNumberVerificationRemainingSeconds > 0">
                <span class="send-verification-code-text">
                    We have sent a code to your mobile number<br>
                    You can send another code
                    in {{ sendMobileNumberVerificationRemainingSeconds }} seconds
                </span>
                </div>
                <IonItem v-show="mobileNumberVerificationCodeSentAt">
                    <IonInput
                        label="Verification Code"
                        label-placement="floating"
                        autocomplete="one-time-code"
                        v-model="mobileNumberVerificationCode"
                    ></IonInput>
                </IonItem>
                <div class="flex flex--100 flex--x-align-center" v-show="mobileNumberVerificationCodeSentAt">
                    <IonButton
                        class="simple-button"
                        fill="outline"
                        :disabled="!mobileNumberVerificationCode || isWaitingServerResponse"
                        @click="verifyMobileNumberByCode"
                    >
                        Confirm
                    </IonButton>
                </div>
            </IonItemGroup>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton
                            default-href="#"
                            :disabled="isWaitingServerResponse"
                            @click="onBackButtonClick"
                            v-if="actualStep > 1"
                        />
                    </IonButtons>
                    <IonButtons slot="end">
                        <IonButton :disabled="!canNext" @click="nextStep" v-show="actualStep < totalSteps">
                            Next
                            <IonIcon :icon="chevronForward"/>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </div>
        </div>
        <MainFooter/>
    </IonContent>
    </IonPage>
</template>

<script>
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonDatetime,
    IonDatetimeButton,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonItemGroup,
    IonLabel,
    IonModal,
    IonNote,
    IonPage,
    IonProgressBar,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
} from "@ionic/vue";
import { userManager, } from "@/UserManager";
import { chevronForward, } from "ionicons/icons";
import ApplicationLogo from "@/components/ApplicationLogo.vue";
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
import MobileNumberInput from "@/components/inputs/MobileNumberInput.vue";
import { EducationType, GenderType, IncomeRangeType, IndustryType, ReligionType } from "@/utilities/Utilities";
import SimpleProgressBar from "@/components/mixed/SimpleProgressBar.vue";
import { mobileNumberIsValid } from "@/utilities/MobileNumberUtilities";
import MainNavigationBar from "@/components/website/WebsiteMainNavigationBar.vue";
import MainFooter from "@/components/website/WebsiteMainFooter.vue";

const MIN_MOBILE_NUMBER_VERIFICATION_S = 20;

export default {
    name: "SignUpView",
    components: {
        MainFooter,
        MainNavigationBar,
        SimpleProgressBar,
        ApplicationLogo,
        IonPage,
        IonContent,
        IonItem,
        IonLabel,
        IonInput,
        IonDatetime,
        IonDatetimeButton,
        IonModal,
        IonSelect,
        IonSelectOption,
        IonItemGroup,
        IonProgressBar,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonButton,
        IonBackButton,
        IonButtons,
        IonIcon,
        IonNote,
        MobileNumberInput,
    },

    data () {
        return {
            isWaitingServerResponse: false,
            actualStep: 1,
            totalSteps: 3,
            user: {
                mobileNumber: "",
                mobileNumberVerificationId: "",
                password: "",
                firstName: "",
                lastName: "",
                genderType: "",
                age: "",
                incomeRangeType: "",
                educationType: "",
                religionType: "",
                industryType: "",
            },
            localMobileNumber: "",
            countryIso: userManager.preferredCountry.iso2,
            dialCode: userManager.preferredCountry.dialCode,

            mobileNumberVerificationCode: "",
            mobileNumberVerificationCodeSentAt: undefined,
            sendMobileNumberVerificationRemainingSeconds: 0,
        };
    },

    computed: {
        IndustryType () {
            return IndustryType
        },

        ...mapStores(useUserStore),

        mobileNumber () {
            return `+${this.dialCode}${this.localMobileNumber}`;
        },

        progress () {
            return this.actualStep / this.totalSteps;
        },

        chevronForward () {
            return chevronForward;
        },

        GenderType () {
            return GenderType;
        },

        IncomeRangeType () {
            return IncomeRangeType;
        },

        EducationType () {
            return EducationType;
        },

        ReligionType () {
            return ReligionType;
        },

        canNext () {
            if (this.isWaitingServerResponse) {
                return false;
            }

            switch (this.actualStep) {
                case 1: {
                    return (
                        this.user.firstName
                        && this.user.lastName
                        && Number.isFinite(Number(this.user.age))
                        && this.user.age > 0
                    );
                }
                case 2: {
                    return (
                        Number.isFinite(this.user.genderType)
                        && Number.isFinite(this.user.incomeRangeType)
                        && Number.isFinite(this.user.educationType)
                        && Number.isFinite(this.user.religionType)
                    );
                }
                case 3: {
                    return (
                        mobileNumberIsValid(this.user.mobileNumber)
                        && this.user.password
                    );
                }
            }

            return false;
        },
    },

    methods: {
        onBackButtonClick (event) {
            event.stopImmediatePropagation();

            if (this.actualStep > 1) {
                --this.actualStep;
            }
            else {
                this.$router.back();
            }
        },

        toHomeView () {
            this.$router.push("/navigation/feed");
        },

        async sendMobileNumberVerificationCode () {
            await userManager.sendMobileNumberVerificationCode(this.mobileNumber);

            this.mobileNumberVerificationCodeSentAt = Date.now();
            this.sendMobileNumberVerificationRemainingSeconds = MIN_MOBILE_NUMBER_VERIFICATION_S;

            const intervalId = setInterval(() => {
                this.sendMobileNumberVerificationRemainingSeconds = this.getRemainingSeconds();

                if (this.sendMobileNumberVerificationRemainingSeconds === 0) {
                    clearInterval(intervalId);
                }
            }, 1000);
        },

        getRemainingSeconds () {
            if (!this.mobileNumberVerificationCodeSentAt) {
                return 0;
            }

            const elapsedSeconds =
                ((Date.now() - this.mobileNumberVerificationCodeSentAt) / 1000) | 0;

            if (elapsedSeconds >= MIN_MOBILE_NUMBER_VERIFICATION_S) {
                return 0;
            }

            return MIN_MOBILE_NUMBER_VERIFICATION_S - elapsedSeconds;
        },
        async verifyMobileNumberByCode () {
            if (!this.mobileNumberVerificationCode) {
                return;
            }

            const response =
                await userManager.verifyMobileNumberByCode(this.mobileNumber, this.mobileNumberVerificationCode);

            switch (response.status) {
                case "error": {
                    switch (response.meta.type) {
                        case "InvalidDataError": {
                            //await presentInvalidDataAlert();

                            break;
                        }
                        case "MobileAlreadyUsedError": {
                            //await presentMobileAlreadyUsedAlert();

                            break;
                        }
                    }

                    break;
                }
                case "ok": {
                    this.user.mobileNumberVerificationId =
                        response.result.verificationId;

                    await this.nextStep();

                    break;
                }
            }
        },
        async nextStep () {
            if (this.actualStep === this.totalSteps) {
                await this.signUp();
            }
            else {
                ++this.actualStep;
            }
        },
        async signUp () {
            if (this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;

            try {
                const response = await userManager.signUp(this.user);
                const loggedUser = response?.result?.user;
                this.isWaitingServerResponse = false;

                switch (response.status) {
                    case "error": {
                        switch (response.meta.type) {
                            case "InvalidDataError": {
                                //await presentInvalidDataAlert();

                                break;
                            }
                            case "MobileAlreadyUsedError": {
                                //await presentMobileAlreadyUsedAlert();

                                break;
                            }
                        }

                        break;
                    }
                    case "ok": {
                        userManager.setPreferredCountryIso(this.countryIso);
                        this.userStore.update(loggedUser);
                        this.toHomeView();

                        break;
                    }
                }
            }
            catch (e) {
                console.log(e);

                this.isWaitingServerResponse = false;
            }
        },
    },
    created () {
        this.user.mobileNumber = this.mobileNumber;
    },
    watch: {
        mobileNumber (value) {
            this.user.mobileNumber = value;
        },
    },
    ionViewDidLeave () {
        this.$resetState();
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.send-verification-code-text {
    margin: 32px 16px 16px 16px;
    padding: 0;

    font-size: 14px;
    font-weight: 400;
    color: $base-text-color;
}

.simple-button {
    margin: 32px 0 16px 0;

    min-width: 320px;
    max-width: 80vw;

    font-weight: 600;
}

.input-section {
    width: 100%;

    padding: 21px 0;

    background-color: transparent;
}

.sign-up-form {
    max-width: 651px;
    margin: 71px;
    padding: 50px;

    height: 100%;

    background-color: rgb(255, 255, 255);

}

.main {

}

.title {
    margin: 0;
    padding: 0;

    font-size: 51px;
    font-weight: 800;
    letter-spacing: 0.002rem;
    text-align: center;

    color: $base-text-color;
}

::v-deep(.simple-progress-bar) {
    .application-logo {
        display: none;
    }
}
</style>
