<template>
    <div class="flex flex--100 share-profile-overlay" :class="modifiers" @click="onTapped">
        <div class="flex flex--100 flex--x-align-center flex--y-align-center main-section" ref="main">
            <div class="flex qr-code-section">
                <canvas class="flex qr-code-canvas" ref="canvas"/>
                <ApplicationIcon/>
            </div>
            <div class="flex flex--100 footer" v-if="user">
                <div class="flex flex--100 flex--x-align-center flex--y-align-center name-section">
                    <h2 class="title">{{ visibleName }}</h2>
                    <VerifiedBadgeIcon class="badge-icon" v-if="user?.isCreator"/>
                </div>
                <div class="flex flex--100 flex--x-align-center">
                    <div class="profile-uri">
                        {{ profileUri }}
                        <div class="flex flex--100 copy-link-button" @click="onCopyLinkButtonPressed">
                            <span class="copy-link-button__text">
                                <template v-if="profileUriJustSavedToClipboard">
                                    Link Copied!
                                </template>
                                <template v-else>
                                    Copy Link
                                </template>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SimpleCategory from "@/components/categories/SimpleCategory.vue";
import { IonContent, IonHeader, IonModal, IonTitle, IonToolbar } from "@ionic/vue";
import { defineComponent, } from "vue";
import * as QrCode from "qrcode";
import ApplicationIcon from "@/components/ApplicationIcon.vue";
import { Clipboard, } from "@capacitor/clipboard";
import { serverManager } from "@/ServerManager";
import VerifiedBadgeIcon from "@/components/icons/VerifiedBadgeIcon.vue";
import { Haptics, ImpactStyle } from "@capacitor/haptics";

export default defineComponent({
    name: "ShareProfileOverlay",
    components: {
        VerifiedBadgeIcon,
        ApplicationIcon, IonModal, SimpleCategory, IonContent, IonHeader, IonToolbar, IonTitle},
    data () {
        return {
            isOpen: false,
            user: undefined,

            profileUriJustSavedToClipboard: false,
            profileUriJustSavedTimeoutId: undefined,
        };
    },
    computed: {
        modifiers () {
            return {
                "share-profile-overlay--open": this.isOpen,
            };
        },

        visibleName () {
            return this.user.visibleName;
        },

        profileUri () {
            return serverManager.composeLink(`/users/${this.user.id}`);
        },
    },
    methods: {
        async vibrate () {
            try {
                await Haptics.impact({
                    style: ImpactStyle.Medium,
                });
            }
            catch {
                // Silence is golden
            }
        },

        show (user) {
            this.user = user;
            this.isOpen = true;
            this.profileUriJustSavedToClipboard = false;
        },

        hide () {
            this.isOpen = false;
            this.profileUriJustSavedToClipboard = false;
        },

        onTapped (e) {
            if (e.target === this.$refs.main) {
                this.hide();
            }
        },

        drawQrCode () {
            QrCode.toCanvas(this.$refs.canvas, this.profileUri, {
                width: Math.min(window.innerWidth * 0.5, 321),
                color: {
                    light: "#0000",
                },
            });
        },

        async saveProfileUriToClipboard () {
            await Clipboard.write({
                url: this.profileUri,
            });
        },

        async onCopyLinkButtonPressed () {
            if (this.profileUriJustSavedTimeoutId) {
                clearTimeout(this.profileUriJustSavedTimeoutId);
            }

            await this.saveProfileUriToClipboard();
            this.vibrate().then().catch((e) => e);

            this.profileUriJustSavedToClipboard = true;
            this.profileUriJustSavedTimeoutId = setTimeout(() => {
                this.profileUriJustSavedToClipboard = false;
            }, 3000);
        },
    },
    watch: {
        user (value) {
            if (value) {
                this.drawQrCode();
            }
        },
    },
});
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.share-profile-overlay {
    pointer-events: none;
    user-select: none;

    transition: opacity 111ms;

    position: fixed;
    left: 0;
    top: 0;

    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(31px);

    width: 100vw;
    height: 100vh;

    opacity: 0;

    &--open {
        pointer-events: initial;

        opacity: 1;
    }
}

.main-section {
    height: 100%;
}

.qr-code-section {
    position: relative;

    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(21px);
    border-radius: 15px;
}

.qr-code-canvas {
    z-index: 2;
}

.application-icon {
    position: absolute;
    right: 0;
    top: 0;

    width: 33px;

    z-index: 1;

    transform: translate(51%, -51%);
}

.footer {
    margin: 0;
    padding: 0;
}

.name-section {
    margin: 27px;
    padding: 0;
}

.title {
    margin: 0;
    padding: 0;

    font-size: 25px;
    letter-spacing: 0.01rem;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.9);
}

.badge-icon {
    width: 26px;
    margin-left: 3px;

    fill: $brand-color;
}

.profile-uri {
    overflow: hidden;

    position: relative;

    margin: 0;
    padding: 13px 21px;

    width: 80%;

    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(21px);
    border-radius: 11px;

    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 13px;
    letter-spacing: 0.01rem;
    font-weight: 700;
    color: rgba(11, 11, 11, 0.5);
}

.copy-link-button {
    cursor: pointer;

    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;

    line-height: 100%;

    background-color: transparent;

    &__text {
        position: absolute;
        left: 50%;
        top: 50%;

        padding: 7px 17px;

        backdrop-filter: blur(11px);
        border-radius: 999px;

        font-size: 13px;
        font-weight: 700;
        color: rgba(11, 11, 11, 0.9);

        transform: translate(-50%, -50%);
    }
}
</style>
