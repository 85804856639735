import { isPlatform, } from "@ionic/vue";

export const isDesktop = isPlatform("desktop");
export const isInApp = isPlatform("capacitor");

export let splashScreenHasEnded = false;

export function notifySplashScreenEnded () {
    splashScreenHasEnded = true;
}
