<template>
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 32 32" class="flex notifications-icon" xml:space="preserve">
        <path d="M25.171,20.105l-1.192-2.384l-0.439-4.836C23.182,8.96,19.941,6,16,6c-3.941,0-7.182,2.96-7.54,6.885l-0.439,4.836l-1.192,2.384c-0.312,0.625-0.279,1.352,0.087,1.946C7.284,22.646,7.92,23,8.618,23H13c0,1.654,1.346,3,3,3s3-1.346,3-3h4.382c0.698,0,1.334-0.354,1.701-0.948S25.483,20.731,25.171,20.105z M16,24c-0.551,0-1-0.449-1-1h2C17,23.551,16.551,24,16,24zM8.618,21l1.276-2.553c0.056-0.112,0.09-0.232,0.102-0.357l0.457-5.024C10.715,10.178,13.1,8,16,8c2.899,0,5.284,2.178,5.547,5.066l0.457,5.024c0.012,0.124,0.046,0.245,0.102,0.356L23.387,21H8.618z"/>
    </svg>
</template>

<script>
export default {
    name: "NotificationsIcon",
};
</script>
