<template>
    <IonApp>
        <IonRouterOutlet/>
        <!-- <shared-components> -->
        <PollFaqModal ref="pollFaqModal"/>
        <PollCommentsModal ref="pollCommentsModal"/>
        <CategoriesModal ref="categoriesModal"/>
        <StripeSubscriptionModal ref="stripeSubscriptionModal"/>
        <PollSwiperGuide ref="pollSwiperGuide"/>
        <PollResultsGuide ref="pollResultsGuide"/>
        <ShareProfileOverlay ref="shareProfileOverlay"/>
        <SharePollOverlay ref="sharePollOverlay"/>
        <!-- </shared-components> -->
    </IonApp>
</template>

<script>
import { defineComponent, } from "vue";
import { IonApp, IonRouterOutlet, } from "@ionic/vue";
import CategoriesModal from "@/components/mixed/CategoriesModal.vue";
import { mapStores, } from "pinia";
import { useUtilityStore, } from "@/stores/UtilityStore";
import PollFaqModal from "@/components/mixed/PollFaqModal.vue";
import PollCommentsModal from "@/components/mixed/PollCommentsModal.vue";
import StripeSubscriptionModal from "@/components/mixed/StripeSubscriptionModal.vue";
import PollResultsGuide from "@/components/guides/PollResultsGuide.vue";
import PollSwiperGuide from "@/components/guides/PollSwiperGuide.vue";
import ShareProfileOverlay from "@/components/mixed/ShareProfileOverlay.vue";
import SharePollOverlay from "@/components/mixed/SharePollOverlay.vue";

export default defineComponent({
    name: "Application",
    components: {
        SharePollOverlay,
        ShareProfileOverlay,
        PollSwiperGuide,
        PollResultsGuide,
        StripeSubscriptionModal,
        PollCommentsModal,
        PollFaqModal,
        IonApp,
        IonRouterOutlet,
        CategoriesModal,
    },
    computed: {
        ...mapStores(useUtilityStore)
    },
    mounted () {
        this.utilityStore.sharedComponents = {
            pollFaqModal: this.$refs.pollFaqModal,
            pollCommentsModal: this.$refs.pollCommentsModal,
            categoriesModal: this.$refs.categoriesModal,
            stripeSubscriptionModal: this.$refs.stripeSubscriptionModal,
            pollSwiperGuide: this.$refs.pollSwiperGuide,
            pollResultsGuide: this.$refs.pollResultsGuide,
            shareProfileOverlay: this.$refs.shareProfileOverlay,
            sharePollOverlay: this.$refs.sharePollOverlay,
        };
    },
});
</script>
