<template>
    <IonPage>
    <IonHeader>
        <IonToolbar>
            <IonButtons slot="start">
                <IonBackButton/>
            </IonButtons>
            <IonTitle>My Integrations</IonTitle>
        </IonToolbar>
    </IonHeader>
    <IonContent>
        <IonList>
            <IonListHeader>
                <IonLabel>
                    Integrations
                </IonLabel>
            </IonListHeader>
            <IonItem>
                <IonToggle
                    :model-value="integrations.hasConnectedTwitter"
                    @click="toggleTwitterAccess"
                >
                    X (Twitter)
                </IonToggle>
            </IonItem>
            <IonItem>
                <IonToggle
                    :model-value="integrations.hasConnectedLinkedIn"
                    @click="toggleLinkedInAccess"
                >
                    LinkedIn
                </IonToggle>
            </IonItem>
            <IonItem>
                <IonToggle
                    :model-value="integrations.hasConnectedFacebook"
                    @click="toggleFacebookAccess"
                >
                    Facebook
                </IonToggle>
            </IonItem>
        </IonList>
    </IonContent>
    </IonPage>
</template>

<script>
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonNote,
    IonPage,
    IonTitle,
    IonToggle,
    IonToolbar,
} from "@ionic/vue";
import { mapStores, } from "pinia";
import { useUserStore, } from "@/stores/UserStore";
import { connectFacebookAccount, connectLinkedInAccount, connectTwitterAccount, } from "@/UserIntegrations";
import { userManager, } from "@/UserManager";
import { presentConfirmationModal, } from "@/utilities/Utilities";

export default {
    name: "MyIntegrationsView",
    components: {
        IonNote, IonToggle,
        IonPage,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonList,
        IonListHeader,
        IonItem,
        IonLabel,
        IonButtons,
        IonBackButton,
        IonButton,
    },
    computed: {
        ...mapStores(useUserStore),

        loggedUser () {
            return this.userStore.user;
        },

        integrations () {
            return this.loggedUser.integrations;
        },
    },
    methods: {
        async toggleTwitterAccess (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            e.stopPropagation();

            if (!this.integrations.hasConnectedTwitter) {
                await connectTwitterAccount();

                return;
            }

            if (!await presentConfirmationModal("Are you sure you want to disconnect your Twitter account? You will need to reconnect it if you wish to use Twitter features again.")) {
                return;
            }

            try {
                const response =
                    await userManager.integrations.revokeTwitterAccess();
                const {
                    user: updatedUser,
                } = response.data.result;

                this.userStore.update(updatedUser);
            }
            catch {
                // Silence is golden
            }
        },

        async toggleLinkedInAccess (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            e.stopPropagation();

            if (!this.integrations.hasConnectedLinkedIn) {
                await connectLinkedInAccount();

                return;
            }

            if (!await presentConfirmationModal("Are you sure you want to disconnect your LinkedIn account? You will need to reconnect it if you wish to use LinkedIn features again.")) {
                return;
            }

            try {
                const response =
                    await userManager.integrations.revokeLinkedInAccess();
                const {
                    user: updatedUser,
                } = response.data.result;

                this.userStore.update(updatedUser);
            }
            catch {
                // Silence is golden
            }
        },

        async toggleFacebookAccess (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            e.stopPropagation();

            if (!this.integrations.hasConnectedFacebook) {
                await connectFacebookAccount();

                return;
            }

            if (!await presentConfirmationModal("Are you sure you want to disconnect your Facebook account? You will need to reconnect it if you wish to use Facebook features again.")) {
                return;
            }

            try {
                const response =
                    await userManager.integrations.revokeFacebookAccess();
                const {
                    user: updatedUser,
                } = response.data.result;

                this.userStore.update(updatedUser);
            }
            catch {
                // Silence is golden
            }
        },
    },
};
</script>
