import { userManager } from "@/UserManager";
import { isProduction } from "@/utilities/Utilities";
import { isPlatform } from "@ionic/vue";
import axios from "axios";

// TODO: Distinguish base components / API uri from Website URI

export const HTTP_API_PROTOCOL =
    isProduction ? "https://" : "http://";

export const HTTP_API_HOST =
    isPlatform("android") ?
        process.env.VUE_APP_ANDROID_HTTP_API_HOST : process.env.VUE_APP_HTTP_API_HOST;

export const BASE_HTTP_URI = `${HTTP_API_PROTOCOL}${HTTP_API_HOST}`;

export const HTTP_API_URI = `${BASE_HTTP_URI}/api/v1`;

export const HTTP_IMAGES_URI = `${HTTP_API_PROTOCOL}${HTTP_API_HOST}/images`;

//const OK_RESPONSE_STATUS = "ok";
export const ERROR_RESPONSE_STATUS = "error";

const HTTP_REQUEST_TIMEOUT_MS = 30000;

export function responseHasError (responseData: Record<string, any>) {
    return responseData.status === ERROR_RESPONSE_STATUS;
}

export function processResponse (response: Record<string, any>) {
    const data = response.data;

    if (responseHasError(data)) {
        if (data.meta.type === "InvalidAuthTokenError") {
            userManager.signOut();
            //routerManager.push("/sign-up");
        }
    }
}

export const serverManager = Object.freeze({
    getImageUri (id: string) {
        return `${HTTP_IMAGES_URI}/${id}.png`;
    },

    composeLink (path: string) {
        return `${BASE_HTTP_URI}${path}`;
    },

    async get (path: string, parameters = {}, headers = {}) {
        const finalHeaders = {
            ...headers,
            ...userManager.authenticationHeaders,
        };

        const response = await axios.get(`${HTTP_API_URI}/${path}`, {
            params: parameters,
            headers: finalHeaders,
            timeout: HTTP_REQUEST_TIMEOUT_MS,
            validateStatus: () => true,
        });

        processResponse(response);

        return response;
    },

    async post (path: string, parameters = {}, headers = {}) {
        const finalHeaders = {
            ...headers,
            ...userManager.authenticationHeaders,
        };

        const response = await axios.post(`${HTTP_API_URI}/${path}`, parameters, {
            headers: finalHeaders,
            timeout: HTTP_REQUEST_TIMEOUT_MS,
            validateStatus: () => true,
        });

        processResponse(response);

        return response;
    },
});
