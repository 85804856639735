<template>
    <div class="flex flex--100 poll-swiper-guide" :class="modifiers">
        <div class="flex flex--100 flex--x-align-center top-section">
            <h2 class="title">
                Swipe Up<br>
                To Navigate Polls
            </h2>
        </div>
        <div class="flex flex--100 flex--x-align-center flex--y-align-center bottom-section">
            <span class="swipe-up">
                <HandSwipeUpIcon/>
            </span>
        </div>
    </div>
</template>

<script>
import { defineComponent, } from "vue";
import HandSwipeDownIcon from "@/components/icons/HandSwipeDownIcon.vue";
import HandSwipeUpIcon from "@/components/icons/HandSwipeUpIcon.vue";

export default defineComponent({
    name: "PollSwiperGuide",
    components: {
        HandSwipeUpIcon,
        HandSwipeDownIcon,
    },
    data () {
        return {
            isOpen: false,
        };
    },
    computed: {
        modifiers () {
            return {
                "poll-swiper-guide--open": this.isOpen,
            };
        },
    },
    methods: {
        show () {
            this.isOpen = true;
        },
        hide () {
            this.isOpen = false;
        },
    },
});
</script>

<style lang="scss" scoped>
.poll-swiper-guide {
    transition: opacity 333ms;

    pointer-events: none;
    position: fixed;
    left: 0;
    top: 0;

    width: 100%;
    height: 100vh;

    z-index: 2147483647;

    background-color: rgba(0, 0, 0, 0.9);

    opacity: 0;

    &--open {
        opacity: 1;
    }
}

.top-section,
.bottom-section {
    position: relative;

    height: 50%;
}

.hand-swipe-up-icon {
    width: 51px;

    color: rgb(255, 255, 255);
}

.swipe-up {
    will-change: top;
    animation: swipe-up 2.8s ease-in-out infinite;

    position: absolute;
    top: 0;
    opacity: 0;

    @keyframes swipe-up {
        0% {
            opacity: 0;
            top: 33%;
        }
        10% {
            opacity: 1;
        }
        90% {
            opacity: 0;
        }
        100% {
            top: 0;
        }
    }
}

.title {
    position: absolute;
    bottom: 10%;

    max-width: 80%;

    letter-spacing: 0.01rem;
    font-size: 31px;
    line-height: 37px;
    font-weight: 800;
    color: rgb(255, 255, 255);
    text-align: center;
}
</style>
