<template>
    <span class="flex simple-button">
        {{ text }}
    </span>
</template>

<script>
import { defineComponent, } from "vue";

export default defineComponent({
    name: "SimpleButton",
    props: {
        text: {
            type: String,
            required: true,
        },
    },
});
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.simple-button {
    cursor: pointer;

    margin: 0;
    padding: 8px 20px;
    border-radius: 8px;

    background-color: $brand-color;

    font-weight: 500;
    font-size: 14px;
    color: rgb(250, 250, 250);

    letter-spacing: 0.008rem;
}
</style>
