<template>
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 32 32" class="flex live-icon" xml:space="preserve">
        <path d="M8.933,24.068c-0.256,0-0.512-0.098-0.707-0.293C6.146,21.694,5,18.933,5,16c0-2.935,1.146-5.695,3.226-7.774c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414C7.937,11.341,7,13.6,7,16c0,2.399,0.937,4.658,2.64,6.361c0.391,0.391,0.391,1.023,0,1.414C9.444,23.971,9.188,24.068,8.933,24.068z M23.774,23.774C25.854,21.695,27,18.935,27,16c0-2.934-1.146-5.694-3.226-7.774c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414C24.062,11.342,25,13.601,25,16c0,2.4-0.938,4.659-2.64,6.36c-0.391,0.391-0.391,1.023,0,1.414c0.195,0.195,0.451,0.293,0.707,0.293S23.579,23.97,23.774,23.774zM12.467,20.948c0.391-0.391,0.391-1.023,0-1.414C11.521,18.588,11,17.333,11,16s0.521-2.588,1.466-3.533c0.391-0.391,0.391-1.023,0-1.414s-1.023-0.391-1.414,0C9.729,12.376,9,14.133,9,16s0.729,3.625,2.053,4.948c0.195,0.195,0.451,0.293,0.707,0.293S12.271,21.144,12.467,20.948z M20.948,20.947C22.271,19.624,23,17.867,23,16s-0.729-3.625-2.053-4.948c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414C20.479,13.412,21,14.667,21,16s-0.521,2.588-1.466,3.533c-0.391,0.391-0.391,1.023,0,1.414c0.195,0.195,0.451,0.293,0.707,0.293S20.753,21.143,20.948,20.947zM19,16c0-1.654-1.346-3-3-3s-3,1.346-3,3s1.346,3,3,3S19,17.654,19,16z"/>
    </svg>
</template>

<script>
export default {
    name: "LiveIcon",
};
</script>
