<template>
    <div class="flex flex--100 simple-poll-comment" :class="modifiers" @click="onPressed">
        <div class="flex flex--100">
            <div class="flex owner-avatar-section">
                <UserAvatar :user="createdBy" class="owner-avatar" @click="onUserAvatarPressed"/>
            </div>
            <div class="flex main-section">
                <div class="flex flex--100">
                    <p class="header">
                        {{ ownerVisibleName }}
                        <span class="created-at">{{ visibleCreatedAt }} ago</span>
                    </p>
                </div>
                <div class="flex flex--100">
                    <p class="body" :class="bodyModifiers">
                        {{ body }}
                    </p>
                </div>
                <div class="flex flex--100 footer">
                    <div class="footer-label">{{ likesCounter }} likes</div>
                    <!-- <replies-button> -->
                    <div
                        class="footer-label reply-button"
                        @click="onReplyButtonPressed"
                        v-if="!isReply"
                    >
                        Reply
                    </div>
                    <div
                        class="footer-label reply-button"
                        @click="onShowRepliesButtonPressed"
                        v-if="repliesCounter > 0 && !repliesAreVisible"
                    >
                        Show Replies
                    </div>
                    <div
                        class="footer-label reply-button"
                        @click="onHideRepliesButtonPressed"
                        v-if="repliesAreVisible"
                    >
                        Hide Replies
                    </div>
                    <!-- </replies-button> -->
                    <div
                        class="footer-label delete-button"
                        @click="onDeleteButtonPressed"
                        v-if="deleteButtonIsVisible"
                    >
                        Delete
                    </div>
                </div>
            </div>
            <div class="flex like-section">
                <span class="flex like-button" :class="likeButtonModifiers" @click="onLikeButtonPressed">
                    <HeartIcon class="like-button__icon"/>
                    <FilledHeartIcon class="like-button__icon like-button__icon--selected"/>
                </span>
            </div>
        </div>
        <!-- <replies> -->
        <div class="flex flex--100 replies-section" v-if="repliesAreVisible">
            <SimplePollComment
                v-for="(reply, i) in replies"
                :key="reply.id"
                :poll-id="pollId"
                v-model:comment="replies[i]"
            />
        </div>
        <!-- </replies> -->
    </div>
</template>

<script>
import { timeToCompactString, } from "@/utilities/Date";
import { userManager } from "@/UserManager";
import UserAvatar from "@/components/UserAvatar.vue";
import { containsOnlyEmojis, countEmojis } from "@/utilities/Utilities";
import HeartIcon from "@/components/icons/HeartIcon.vue";
import FilledHeartIcon from "@/components/icons/FilledHeartIcon.vue";
import { Haptics, ImpactStyle } from "@capacitor/haptics";

export default {
    name: "SimplePollComment",
    components: {FilledHeartIcon, HeartIcon, UserAvatar},
    props: {
        pollId: {
            type: String,
            required: true,
        },
        comment: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            replies: undefined,
            repliesAreVisible: false,
        };
    },
    computed: {
        loggedUserIsOwner () {
            return this.comment?.$user?.isOwner === true;
        },

        loggedUserHasLiked () {
            return this.comment?.$user?.hasLiked === true;
        },

        isDeleted () {
            return !!this.comment.deletedAt;
        },

        body () {
            return this.isDeleted ? "deleted" : this.comment.body;
        },

        // Used to count emojis as one character
        bodyLength () {
            return [ ...this.body, ].length;
        },

        createdAt () {
            return new Date(this.comment.createdAt);
        },

        createdBy () {
            return this.comment.createdBy;
        },

        likesCounter () {
            return this.comment.likesCounter;
        },

        repliesCounter () {
            return this.comment.repliesCounter;
        },

        ownerVisibleName () {
            return this.createdBy.visibleName;
        },

        visibleCreatedAt () {
            return timeToCompactString(Date.now() - this.createdAt.getTime());
        },

        deleteButtonIsVisible () {
            return this.loggedUserIsOwner && !this.isDeleted;
        },

        isReply () {
            return !!this.comment.parentComment;
        },

        modifiers () {
            return {
                "simple-poll-comment--owner": this.loggedUserIsOwner,
                "simple-poll-comment--reply": this.isReply,
            };
        },

        bodyModifiers () {
            return {
                "body--deleted": this.isDeleted,
                "body--one-emoji": containsOnlyEmojis(this.body) && countEmojis(this.body) === 1,
                "body--two-emoji": containsOnlyEmojis(this.body) && countEmojis(this.body) === 2,
                "body--three-emoji": containsOnlyEmojis(this.body) && countEmojis(this.body) === 3,
            };
        },

        likeButtonModifiers () {
            return {
                "like-button--active": this.loggedUserHasLiked,
            };
        },
    },
    methods: {
        async vibrate () {
            try {
                await Haptics.impact({
                    style: ImpactStyle.Medium,
                });
            }
            catch {
                // Silence is golden
            }
        },

        async toggleLike () {
            const comment = await userManager.toggleCommentLike(this.pollId, this.comment.id);

            this.vibrate().then().catch((e) => e);
            this.$emit("update:comment", comment);
        },

        async deleteComment () {
            const comment = await userManager.deletePollComment(this.pollId, this.comment.id);

            this.$emit("update:comment", comment);
        },

        async fetchCompleteComment () {
            const comment = await userManager.getPollComment(this.pollId, this.comment.id);
            this.replies = comment.replies;

            console.log(this.replies);

            this.$emit("update:comment", comment);
        },

        async showReplies () {
            if (!this.replies) {
                await this.fetchCompleteComment();
            }

            this.repliesAreVisible = true;
        },

        async toggleRepliesVisibility () {
            if (!this.repliesAreVisible) {
                await this.showReplies();
            }
            else {
                this.hideReplies();
            }
        },

        hideReplies () {
            this.repliesAreVisible = false;
        },

        select () {
            this.$emit("selected", this.comment);
        },

        onPressed () {
            if (this.repliesCounter > 0) {
                this.toggleRepliesVisibility().then().catch();
            }
        },

        onLikeButtonPressed (e) {
            e.stopPropagation();
            this.toggleLike().then().catch((e) => e);
        },

        onDeleteButtonPressed (e) {
            e.stopPropagation();
            this.deleteComment().then().catch((e) => e);
        },

        onShowRepliesButtonPressed (e) {
            e.stopPropagation();
            this.showReplies().then().catch((e) => e);
        },

        onHideRepliesButtonPressed (e) {
            e.stopPropagation();
            this.hideReplies();
        },

        onReplyButtonPressed (e) {
            e.stopPropagation();
            this.select();
        },

        onUserAvatarPressed (e) {
            e.stopPropagation();
            this.toOwnerView();
        },

        toOwnerView () {
            this.$router.push(`/navigation/users/${this.createdBy.id}`);
        },
    },
    watch: {
        replies: {
            deep: true,
            handler (value) {
                this.$emit("update:comment", {
                    ...this.comment,
                    replies: value,
                });
            },
        }
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.simple-poll-comment {
    &--owner {
        // background-image: linear-gradient(45deg, rgb(255, 254, 233), rgb(255, 255, 255));
    }
}

.owner-avatar-section {

}

.owner-avatar {
    width: 43px;
    height: 43px;
}

.main-section {
    overflow-x: hidden;

    flex: 1;

    margin: 0 0 0 12px;
}

.like-section {

}

.like-button {
    cursor: pointer;

    margin-left: 11px;

    &__icon {
        width: 28px;

        color: rgb(66, 66, 66);
    }
    &__icon--selected {
        display: none;

        color: rgb(215, 11, 11);
    }

    &--active &__icon {
        display: none;
    }
    &--active &__icon--selected {
        display: initial;
    }
}

.header {
    margin: 0;
    padding: 0;

    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.02rem;
    color: rgb(22, 22, 22);
}

.created-at {
    margin: 0;
    padding: 0;

    font-size: 11px;
    font-weight: 400;
    letter-spacing: 0.02rem;
    color: rgba(91, 91, 91, 0.9);
}

.body {
    margin: 7px 0;
    padding: 0;

    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.02rem;
    color: $base-text-color;

    &--deleted {
        font-weight: 400;
        font-style: italic;
        color: rgba(99, 99, 99, 0.9);
    }

    &--one-emoji {
        font-size: 21px;
    }

    &--two-emoji,
    &--three-emoji {
        font-size: 15px;
    }
}

.footer {
    margin: 0;
    padding: 0;
}

.footer-label {
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.02rem;
    color: rgb(122, 122, 122);
}

.reply-button,
.delete-button {
    cursor: pointer;

    margin-left: 8px;

    color: rgb(102, 102, 102);
}

.replies-section {
    margin: 21px 0 0 0;

    border-top: 1px solid rgb(243, 243, 243);
}
</style>
