<template>
    <IonModal
        class="categories-modal"
        :initial-breakpoint="1"
        :breakpoints="[0, 1]"
        :is-open="isOpen"
        :keep-contents-mounted="true"
        @ionModalDidDismiss="dismissModal"
    >
    <IonHeader>
        <IonToolbar>
            <IonTitle>Categories</IonTitle>
        </IonToolbar>
    </IonHeader>
    <IonContent>
        <IonList>
            <IonItem
                v-for="{ id, } in categories"
                :key="id"
                :button="true"
                @click="toCategoryView(id)"
            >
                <SimpleCategory :id="id"/>
            </IonItem>
        </IonList>
        <hr class="safe-bottom-area"/>
    </IonContent>
    </IonModal>
</template>

<script>
import SimpleCategory from "@/components/categories/SimpleCategory.vue";
import {
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonModal,
    IonTitle,
    IonToolbar
} from "@ionic/vue";
import { defineComponent, } from "vue";

export default defineComponent({
    name: "CategoriesModal",
    components: {
        IonListHeader,
        IonLabel,
        IonItem,
        IonList,
        IonModal, SimpleCategory, IonContent, IonHeader, IonToolbar, IonTitle},
    data () {
        return {
            isOpen: false,
            categories: [],
        };
    },
    methods: {
        openModal (categories) {
            this.categories = categories;
            this.isOpen = true;
        },

        dismissModal () {
            this.isOpen = false;
            this.categories = [];
        },

        toCategoryView (categoryId) {
            this.$router.push(`/navigation/categories/${categoryId}`);
        },
    },
    watch: {
        $route () {
            this.dismissModal();
        },
    },
});
</script>

<style lang="scss" scoped>
.categories-modal {
    --height: 70%;

    &::part(handle) {
        outline: none;
    }
}
</style>
