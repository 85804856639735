import { pollsStore } from "@/main";
import { userManager } from "@/UserManager";
import { defineStore, } from "pinia";
import { ref, watch, } from "vue";

export const useFeedStore = defineStore("feed", () => {
    const forYouFeed = ref([]);
    const resultsFeed = ref([]);
    const trendingFeed = ref([]);

    watch(forYouFeed, (value) => pollsStore.updateMany(value));
    watch(resultsFeed, (value) => pollsStore.updateMany(value));
    watch(trendingFeed, (value) => pollsStore.updateMany(value));

    return {
        forYouFeed,
        resultsFeed,
        trendingFeed,

        async fetchForYouFeed () {
            const response = await userManager.feeds.fetchForYouFeed();
            forYouFeed.value = response.data.result.forYouFeed;
        },

        async fetchResultsFeed () {
            const response = await userManager.feeds.fetchResultsFeed();
            resultsFeed.value = response.data.result.resultsFeed;
        },

        async fetchTrendingFeed () {
            const response = await userManager.feeds.fetchSearchFeed();
            trendingFeed.value = response.data.result.searchFeed;
        },
    };
});
