<template>
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" class="flex responsible-consumption-production-icon" viewBox="0 0 100 100">
        <path d="M69.2 32.22c-5.347 0-10.218 2.573-14.476 7.649a1.499 1.499 0 1 0 2.298 1.927c3.713-4.426 7.697-6.577 12.178-6.577 7.949 0 13.5 5.952 13.5 14.475S77.149 64.17 69.2 64.17c-8.897 0-13.945-8.551-16.961-13.659a73.163 73.163 0 0 0-.966-1.609c-.279-.448-.587-.971-.928-1.548C47.222 42.064 41.41 32.22 30.8 32.22c-9.561 0-16.5 7.35-16.5 17.475 0 10.126 6.939 17.476 16.5 17.476 4.827 0 9.134-2.009 13.075-6.099l-.362 5.103a1.5 1.5 0 1 0 2.992.213l.691-9.749a1.5 1.5 0 0 0-1.76-1.583l-9.524 1.7a1.501 1.501 0 0 0 .527 2.953l5.528-.987c-3.432 3.656-7.11 5.449-11.168 5.449-7.949 0-13.5-5.952-13.5-14.476 0-8.522 5.551-14.475 13.5-14.475 8.897 0 13.946 8.551 16.962 13.659.354.6.675 1.144.965 1.608.279.448.587.971.928 1.548C52.778 57.325 58.59 67.17 69.2 67.17c9.561 0 16.5-7.35 16.5-17.476 0-10.125-6.939-17.474-16.5-17.474z"/>
    </svg>
</template>

<script>
export default {
    name: "ResponsibleConsumptionProductionIcon",
}
</script>
