<template>
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 32 32" class="flex hand-swipe-down-icon">
        <path d="M26.86328,20.55469l-1.14941-4.28711c-.35156-1.31543-1.53809-2.22363-2.89551-2.22363h-.05176l-3.84277.06543-1.02734-3.83496c-.39258-1.46289-1.90137-2.33691-3.36816-1.94434-1.46387.39355-2.33594,1.9043-1.94434,3.36816l1.87695,7.00293-1.17383-.4707c-1.37695-.55176-2.97363-.00098-3.7168,1.2832l-.54688.94727c-.26074.45215-.12988,1.03027.30078,1.3252l6.09082,4.16992c.84082.5752,1.82422.87402,2.82129.87402.43359,0,.86914-.05566,1.29688-.1709l4.50098-1.20605c2.13086-.57031,3.39941-2.76758,2.8291-4.89844ZM24.73145,22.58984c-.26758.46191-.69824.79297-1.21484.93164l-4.50098,1.20605c-.84961.22949-1.74805.07422-2.4707-.42188l-5.32617-3.64648.08301-.14453c.24805-.42871.77832-.6123,1.24023-.42871l3.03027,1.21582c.34863.1416.75195.07129,1.03516-.17969.28418-.25098.40137-.64062.30273-1.00684l-2.39453-8.93457c-.10645-.39941.13184-.81152.53125-.91895.40137-.1084.81152.13281.91797.53027l1.22949,4.58887c.11914.44336.54004.72949.98242.74121l4.62305-.07812c.42969-.01172.86426.29688.98242.74121l1.14941,4.28711c.1377.51562.06738,1.05469-.2002,1.51758ZM9.20703,16.70703c-.19531.19531-.45117.29297-.70703.29297s-.51172-.09766-.70703-.29297l-2.42871-2.42871c-.39062-.39062-.39062-1.02344,0-1.41406s1.02344-.39062,1.41406,0l.72168.72168v-7.58594c0-.55273.44727-1,1-1s1,.44727,1,1v7.58594l.72168-.72168c.39062-.39062,1.02344-.39062,1.41406,0s.39062,1.02344,0,1.41406l-2.42871,2.42871Z"/>
    </svg>
</template>

<script>
import { defineComponent, } from "vue";

export default defineComponent({
    name: "HandSwipeDownIcon",
});
</script>
