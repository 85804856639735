<template>
    <svg class="flex chart-icon" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path d="M21.2,26.2h-10.5c-2.8,0-5-2.2-5-5v-10.5c0-2.8,2.2-5,5-5h10.5c2.8,0,5,2.2,5,5v10.5c0,2.8-2.2,5-5,5ZM10.8,7.8c-1.6,0-3,1.4-3,3v10.5c0,1.6,1.4,3,3,3h10.5c1.6,0,3-1.4,3-3v-10.5c0-1.6-1.4-3-3-3h-10.5ZM17,20.8v-9.5c0-.6-.5-1-1-1s-1,.4-1,1v9.5c0,.5.4,1,1,1s1-.5,1-1ZM12.8,20.8v-7c0-.6-.4-1-1-1s-1,.4-1,1v7c0,.5.4,1,1,1s1-.5,1-1ZM21.2,20.8v-4.5c0-.6-.5-1-1-1s-1,.4-1,1v4.5c0,.5.5,1,1,1s1-.5,1-1Z"/>
    </svg>
</template>

<script>
import { defineComponent, } from "vue";

export default defineComponent({
    name: "ChartIcon",
});
</script>
