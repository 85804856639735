<template>
    <IonPage>
    <IonHeader>
        <IonToolbar>
            <IonButtons slot="start">
                <IonBackButton/>
            </IonButtons>
            <IonTitle>Vottify for Creators</IonTitle>
        </IonToolbar>
    </IonHeader>
    <IonContent class="ion-content">
        <div class="flex flex--100 flex--y-align-center main">
            <div class="flex flex--100 flex--x-align-center">
                <div class="creator-type-button" @click="toOrganizationSignUpView">
                    I'm a Company or<br>
                    Organization
                </div>
            </div>
            <div class="flex flex--100 flex--x-align-center">
                <span class="or-text">or</span>
            </div>
            <div class="flex flex--100 flex--x-align-center">
                <div class="creator-type-button" @click="toPublicFigureSignUpView">
                    I'm a Content Creator or<br>
                    Public Figure
                </div>
            </div>
        </div>
    </IonContent>
    </IonPage>
</template>

<script>
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonDatetime,
    IonDatetimeButton,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonItemGroup,
    IonLabel,
    IonModal,
    IonNote,
    IonPage,
    IonProgressBar,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
} from "@ionic/vue";

import ApplicationLogo from "@/components/ApplicationLogo.vue";
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
import HeartIcon from "@/components/icons/HeartIcon.vue";

export default {
    name: "CreatorSignUpView",
    components: {
        HeartIcon,
        ApplicationLogo,
        IonPage,
        IonContent,
        IonItem,
        IonLabel,
        IonInput,
        IonDatetime,
        IonDatetimeButton,
        IonModal,
        IonSelect,
        IonSelectOption,
        IonItemGroup,
        IonProgressBar,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonButton,
        IonBackButton,
        IonButtons,
        IonIcon,
        IonNote,
    },
    props: {
        selectedPlan: {
            type: String,
        },
    },
    data () {
        return {

        };
    },
    computed: {
        ...mapStores(useUserStore),
    },
    methods: {
        toPublicFigureSignUpView () {
            this.$router.push("/sign-up/public-figure");
        },

        toOrganizationSignUpView () {
            this.$router.push("/sign-up/organization");
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.ion-content {
    &::part(background) {
        background-image: linear-gradient(
            45deg,
            rgb(255, 255, 255),
            rgba($brand-color, 0.15) 50%,
            rgb(255, 255, 255)
        );
    }
}

.main {
    height: 100%;
}

.title {
    margin: 33px 0;
    padding: 0;

    font-size: 21px;
    font-weight: 600;
    letter-spacing: -0.02rem;

    text-align: center;
}

.or-text {
    cursor: default;

    margin: 21px 0;

    font-size: 15px;
    font-weight: 500;
    color: rgba(130, 130, 130, 0.9);

    -webkit-user-select: none;
    user-select: none;
}

.creator-type-button {
    cursor: pointer;

    width: 253px;

    margin: 0;
    padding: 51px 21px;

    background-color: rgb(0, 0, 0);
    background-image: linear-gradient(to right, rgb(0, 0, 0) 60%, $brand-color);
    border-radius: 13px;

    font-size: 17px;
    font-weight: 700;
    text-align: center;

    color: rgb(255, 255, 255);
}
</style>
