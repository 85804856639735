<template>
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" class="flex affordable-clean-energy-icon" xml:space="preserve" viewBox="0 0 100 100">
        <path d="M50 69.5c-10.752 0-19.5-8.748-19.5-19.5S39.248 30.5 50 30.5 69.5 39.248 69.5 50 60.752 69.5 50 69.5zm0-36c-9.098 0-16.5 7.402-16.5 16.5S40.902 66.5 50 66.5 66.5 59.098 66.5 50 59.098 33.5 50 33.5zm1.5-7.5v-9.6a1.5 1.5 0 1 0-3 0V26a1.5 1.5 0 1 0 3 0zm-12.75 4.514a1.5 1.5 0 0 0 .549-2.049l-4.8-8.314a1.5 1.5 0 1 0-2.598 1.5l4.8 8.314a1.5 1.5 0 0 0 2.049.549zm-8.236 8.236a1.5 1.5 0 0 0-.549-2.049l-8.314-4.8a1.5 1.5 0 1 0-1.5 2.598l8.314 4.8a1.501 1.501 0 0 0 2.049-.549zM27.5 50a1.5 1.5 0 0 0-1.5-1.5h-9.6a1.5 1.5 0 1 0 0 3H26a1.5 1.5 0 0 0 1.5-1.5zm-5.849 18.099 8.314-4.8a1.5 1.5 0 0 0-1.5-2.598l-8.314 4.8a1.5 1.5 0 0 0 1.5 2.598zm12.848 11.75 4.8-8.314a1.5 1.5 0 1 0-2.598-1.5l-4.8 8.314a1.5 1.5 0 1 0 2.598 1.5zM51.5 83.6V74a1.5 1.5 0 1 0-3 0v9.6a1.5 1.5 0 1 0 3 0zm16.05-3.203a1.5 1.5 0 0 0 .549-2.049l-4.8-8.314a1.5 1.5 0 1 0-2.598 1.5l4.8 8.314a1.5 1.5 0 0 0 2.049.549zM80.397 67.55a1.5 1.5 0 0 0-.549-2.049l-8.314-4.8a1.5 1.5 0 1 0-1.5 2.598l8.314 4.8a1.5 1.5 0 0 0 2.049-.549zM85.1 50a1.5 1.5 0 0 0-1.5-1.5H74a1.5 1.5 0 1 0 0 3h9.6a1.5 1.5 0 0 0 1.5-1.5zM71.535 39.299l8.314-4.8a1.5 1.5 0 0 0-1.5-2.598l-8.314 4.8a1.5 1.5 0 0 0 1.5 2.598zm-8.236-9.334 4.8-8.314a1.5 1.5 0 1 0-2.598-1.5l-4.8 8.314a1.5 1.5 0 1 0 2.598 1.5zM61.5 50c0-3.638-1.676-6.986-4.598-9.185a1.5 1.5 0 0 0-1.804 2.398C57.26 44.839 58.5 47.313 58.5 50c0 4.687-3.813 8.5-8.5 8.5s-8.5-3.813-8.5-8.5c0-2.687 1.24-5.161 3.402-6.788a1.5 1.5 0 0 0-1.804-2.398C40.176 43.014 38.5 46.362 38.5 50c0 6.341 5.159 11.5 11.5 11.5S61.5 56.341 61.5 50zm-10-5v-7a1.5 1.5 0 1 0-3 0v7a1.5 1.5 0 1 0 3 0z"/>
    </svg>
</template>

<script>
export default {
    name: "AffordableCleanEnergyIcon",
};
</script>
