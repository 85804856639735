<template>
    <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonBackButton/>
                </IonButtons>
                <IonTitle>Change Password</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <IonItemGroup class="input-section">
                <!-- <mobile-number> -->
                <MobileNumberInput
                    v-model:mobile-number="localMobileNumber"
                    v-model:country-iso="countryIso"
                    v-model:dial-code="dialCode"
                    :readonly="isReadonly"
                ></MobileNumberInput>
                <IonItem lines="none" v-show="validations.mobileNumberIsMissing" class="input-error-section">
                    <span class="input-error-text">Mobile number is required</span>
                </IonItem>
                <IonItem lines="none" v-show="validations.mobileNumberIsNaN" class="input-error-section">
                    <span class="input-error-text">Mobile number must be a number</span>
                </IonItem>
                <!-- </mobile-number> -->
                <!-- <password> -->
                <IonItem>
                    <IonInput
                        type="password"
                        label="New Password"
                        label-placement="floating"
                        autocomplete="new-password"
                        v-model="user.password"
                    ></IonInput>
                </IonItem>
                <IonItem lines="none" v-show="validations.passwordIsMissing" class="input-error-section">
                    <span class="input-error-text">Password is required</span>
                </IonItem>
                <IonItem lines="none" v-show="validations.passwordIsMissingRequirements" class="input-error-section">
                    <span class="input-error-text">Password must have at least 6 characters</span>
                </IonItem>
                <!-- </password> -->
                <!-- <mobile-number-code> -->
                <div class="flex flex--100 flex--x-align-center" v-show="sendMobileNumberVerificationRemainingSeconds === 0">
                    <IonButton
                        class="simple-button"
                        fill="outline"
                        :disabled="isWaitingServerResponse"
                        @click="sendMobileNumberVerificationCode"
                    >
                        Send Mobile Number Code
                    </IonButton>
                </div>
                <div class="flex flex--100" v-show="sendMobileNumberVerificationRemainingSeconds > 0">
                <span class="send-verification-code-text">
                    We have sent a code to your mobile number<br>
                    You can send another code
                    in {{ sendMobileNumberVerificationRemainingSeconds }} seconds
                </span>
                </div>
                <IonItem v-show="mobileNumberVerificationCodeSentAt">
                    <IonInput
                        label="Verification Code"
                        label-placement="floating"
                        autocomplete="one-time-code"
                        v-model="mobileNumberVerificationCode"
                    ></IonInput>
                </IonItem>
                <div class="flex flex--100 flex--x-align-center" v-show="mobileNumberVerificationCodeSentAt">
                    <IonButton
                        class="simple-button"
                        fill="outline"
                        :disabled="!mobileNumberVerificationCode || isWaitingServerResponse"
                        @click="verifyMobileNumberByCode"
                    >
                        Change Password
                    </IonButton>
                </div>
                <!-- </mobile-number-code> -->
            </IonItemGroup>
        </IonContent>
    </IonPage>
</template>

<script>
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonDatetime,
    IonDatetimeButton,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonItemGroup,
    IonLabel,
    IonModal,
    IonNote,
    IonPage,
    IonProgressBar,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
} from "@ionic/vue";
import { userManager, } from "@/UserManager";
import { chevronForward, } from "ionicons/icons";
import ApplicationLogo from "@/components/ApplicationLogo.vue";
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
import MobileNumberInput from "@/components/inputs/MobileNumberInput.vue";
import { presentMessageModal } from "@/utilities/Utilities";
import SimpleProgressBar from "@/components/mixed/SimpleProgressBar.vue";
import { parsePhoneNumber } from "libphonenumber-js/mobile";

const MIN_MOBILE_NUMBER_VERIFICATION_S = 20;

export default {
    name: "ChangePasswordView",
    components: {
        SimpleProgressBar,
        ApplicationLogo,
        IonPage,
        IonContent,
        IonItem,
        IonLabel,
        IonInput,
        IonDatetime,
        IonDatetimeButton,
        IonModal,
        IonSelect,
        IonSelectOption,
        IonItemGroup,
        IonProgressBar,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonButton,
        IonBackButton,
        IonButtons,
        IonIcon,
        IonNote,
        MobileNumberInput,
    },

    data () {
        return {
            isWaitingServerResponse: false,
            isReadonly: false,
            user: {
                mobileNumber: "",
                mobileNumberVerificationId: "",
                password: "",
            },
            localMobileNumber: "",
            countryIso: userManager.preferredCountry.iso2,
            dialCode: userManager.preferredCountry.dialCode,

            mobileNumberVerificationCode: "",
            mobileNumberVerificationCodeSentAt: undefined,
            sendMobileNumberVerificationRemainingSeconds: 0,

            validations: {
                mobileNumberIsMissing: undefined,
                mobileNumberIsNaN: undefined,
                passwordIsMissing: undefined,
                passwordIsMissingRequirements: undefined,
            },
        };
    },

    computed: {
        ...mapStores(useUserStore),

        loggedUser () {
            return this.userStore.user;
        },

        mobileNumber () {
            return `+${this.dialCode}${this.localMobileNumber}`;
        },

        chevronForward () {
            return chevronForward;
        },
    },

    methods: {
        async sendMobileNumberVerificationCode () {
            await userManager.sendMobileNumberVerificationCode(this.mobileNumber);

            this.mobileNumberVerificationCodeSentAt = Date.now();
            this.sendMobileNumberVerificationRemainingSeconds = MIN_MOBILE_NUMBER_VERIFICATION_S;

            const intervalId = setInterval(() => {
                this.sendMobileNumberVerificationRemainingSeconds = this.getRemainingSeconds();

                if (this.sendMobileNumberVerificationRemainingSeconds === 0) {
                    clearInterval(intervalId);
                }
            }, 1000);
        },

        getRemainingSeconds () {
            if (!this.mobileNumberVerificationCodeSentAt) {
                return 0;
            }

            const elapsedSeconds =
                ((Date.now() - this.mobileNumberVerificationCodeSentAt) / 1000) | 0;

            if (elapsedSeconds >= MIN_MOBILE_NUMBER_VERIFICATION_S) {
                return 0;
            }

            return MIN_MOBILE_NUMBER_VERIFICATION_S - elapsedSeconds;
        },
        async verifyMobileNumberByCode () {
            if (!this.mobileNumberVerificationCode) {
                return;
            }

            const response =
                await userManager.verifyMobileNumberByCode(this.mobileNumber, this.mobileNumberVerificationCode);

            switch (response.status) {
                case "error": {
                    switch (response.meta.type) {
                        case "InvalidDataError": {
                            //await presentInvalidDataAlert();

                            break;
                        }
                        case "MobileAlreadyUsedError": {
                            //await presentMobileAlreadyUsedAlert();

                            break;
                        }
                    }

                    break;
                }
                case "ok": {
                    this.user.mobileNumberVerificationId =
                        response.result.verificationId;

                    await this.changePassword();

                    break;
                }
            }
        },

        async changePassword () {
            const response = await userManager.user.changePassword({
                ...this.user,
            });
            const { user, } = response.data;

            this.userStore.update(user);

            await presentMessageModal("Password changed successfully!");
            this.$router.back();
        },
    },
    watch: {
        mobileNumber (value) {
            this.user.mobileNumber = value;
        },

        localMobileNumber (value) {
            this.validations.mobileNumberIsMissing = !value;
            this.validations.mobileNumberIsNaN = !Number.isFinite(Number(value));
        },

        "user.password" (value) {
            this.validations.passwordIsMissing = !value;
            this.validations.passwordIsMissingRequirements = (
                value
                && value.length < 6
            );
        },
    },
    created () {
        this.user.mobileNumber = this.mobileNumber;

        if (this.loggedUser && userManager.isLogged) {
            try {
                const parsedMobileNumber = parsePhoneNumber(this.loggedUser.mobileNumber);

                this.localMobileNumber = parsedMobileNumber.nationalNumber;
                this.countryIso = parsedMobileNumber.country;
                this.dialCode = parsedMobileNumber.countryCallingCode;
                this.isReadonly = true;
            }
            catch {
                // Silence is golden
            }
        }
    },
    ionViewDidLeave () {
        this.$resetState();
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";
@import "@/theme/utilities.scss";

.send-verification-code-text {
    margin: 32px 16px 16px 16px;
    padding: 0;

    font-size: 14px;
    font-weight: 400;
    color: $base-text-color;
}

.simple-button {
    margin: 32px 0 16px 0;

    min-width: 320px;
    max-width: 80vw;

    font-weight: 600;
}

.input-section {
    padding-top: 21px;

    background-color: transparent;
}
</style>
