<template>
    <IonPage>
    <IonHeader>
        <IonToolbar>
            <IonButtons slot="start">
                <IonBackButton/>
            </IonButtons>
            <IonTitle>My Categories</IonTitle>
        </IonToolbar>
    </IonHeader>
    <IonContent>
        <div class="flex flex--100 flex--x-align-center discover-categories-section">
            <IonButton fill="clear" @click="toDiscoverCategoriesView">
                Discover Categories
                <IonIcon :icon="chevronForward"/>
            </IonButton>
        </div>
        <div class="flex flex--100">
            <SimpleCategory
                v-for="{ id, } in localVisibleCategories"
                :key="id"
                :id="id"
            />
        </div>
        <hr class="safe-bottom-area"/>
    </IonContent>
    </IonPage>
</template>

<script>
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonPage,
    IonTitle,
    IonToolbar,
} from "@ionic/vue";
import UserAvatar from "@/components/UserAvatar.vue";
import SimpleCategory from "@/components/categories/SimpleCategory.vue";
import { chevronForward, } from "ionicons/icons";
import { categoriesStore, } from "@/main";

export default {
    name: "MyCategoriesView",
    components: {
        IonTitle, IonBackButton,
        SimpleCategory,
        UserAvatar,
        IonPage,
        IonHeader,
        IonToolbar,
        IonContent,
        IonButton,
        IonButtons,
        IonIcon,
    },
    data () {
        return {
            localVisibleCategories: [],
        };
    },
    computed: {
        enabledCategories () {
            return categoriesStore.all.filter((category) => !category.isArchived);
        },

        followedCategories () {
            return this.enabledCategories.filter((category) => category.$user.isFollowing);
        },

        chevronForward () {
            return chevronForward;
        },
    },
    methods: {
        async fetch () {
            await categoriesStore.fetch();
            this.resetVisibleCategories();
        },

        toDiscoverCategoriesView () {
            this.$router.push("/categories");
        },

        resetVisibleCategories () {
            this.localVisibleCategories =
                this.followedCategories.map((category) => category);
        },
    },
    async ionViewWillEnter () {
        await this.fetch();
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.discover-categories-section {
    border-bottom: $base-border;
}
</style>
