<template>
    <div class="flex flex--100 flex--x-align-center flex--y-align-center website-light-header">
        <div class="flex flex--y-align-center traction-section">
            <div class="flex flex--100">
                <div class="flex flex--100">
                    <h1 class="title" v-html="title"></h1>
                </div>
                <div class="flex flex--100">
                    <span class="subtitle" v-html="subtitle"></span>
                </div>
                <div class="flex flex--100">
                    <span
                        class="request-demo-button"
                        @click="toRequestDemoView"
                    >
                        Request Demo
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SimplePoll from "@/components/polls/SimplePoll.vue";
import ApplicationMarketplaceButtons from "@/components/website/ApplicationMarketplaceButtons.vue";

export default {
    name: "WebsiteLightHeader",
    components: {
        ApplicationMarketplaceButtons,
        SimplePoll,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            required: true,
        },
        featuredPolls: {
            type: Array,
        },
    },
    methods: {
        toRequestDemoView () {
            this.$router.push("/request-demo");
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";
@import "@/theme/poll-website.scss";

.website-light-header {
    margin: 0;
    padding: 0;

    background-color: rgb(0, 0, 0);
    background-image: linear-gradient(-15deg, rgb(241, 241, 241) 80%, $brand-color);
}

.traction-section {
    width: 100%;
    max-width: 1039px;

    padding: 201px 71px;

    @media (max-width: $mobile-breaking-point) {
        padding: 101px 31px;
    }
}

.title {
    width: 100%;

    margin: 0;
    padding: 0;

    font-size: 71px;
    font-weight: 700;
    letter-spacing: 0.02rem;

    line-height: 1;

    color: rgb(0, 0, 0);

    @media (max-width: $mobile-breaking-point) {
        font-size: 61px;
        line-height: 67px;
    }
}

.subtitle {
    width: 100%;
    max-width: 771px;

    margin: 29px 0;

    font-size: 29px;
    font-weight: 300;
    letter-spacing: -0.01rem;
    color: rgba(9, 9, 9, 0.9);
}

.request-demo-button {
    cursor: pointer;

    transition: opacity 180ms;
    will-change: opacity;

    padding: 15px 30px;
    margin: 0;

    background-image: linear-gradient(45deg, rgb(0, 0, 0) 60%, $brand-color);
    border-radius: 999px;

    font-size: 19px;
    font-weight: 500;
    color: rgb(255, 255, 255);

    &:hover {
        opacity: 0.5;
    }
}
</style>
