<template>
    <IonAccordionGroup class="flex flex--100" value="0">
        <IonAccordion
            v-for="({ question, answer, }, i) in faqItems"
            :key="i"
            :value="i"
            class="accordion"
        >
            <IonItem slot="header" color="light">
                <IonLabel>{{ question }}</IonLabel>
            </IonItem>
            <div class="answer" slot="content">
                {{ answer }}
            </div>
        </IonAccordion>
    </IonAccordionGroup>
</template>

<script>
import { defineComponent, } from "vue";
import { IonAccordion, IonAccordionGroup, IonItem, IonLabel } from "@ionic/vue";

const faqItems = [
    {
        question: "Who can see my vote and what do they see?",
        answer: `The principal concept of Vottify is to enable anonymous voting by the majority of social media users.
Your vote is not visible to the creator of the poll (i.e. the person or organization who posts the poll).
However, the specific categorization of the groups the voters represent may be made visible to the creator
or as part of the result to the public. The categorizations may associate but are not limited to voters'
income bracket, educational level, etc.`,
    },
    {
        question: "What information is visible to Vottify subscribers?",
        answer: `Anyone subscribed to see the poll can see the total aggregate responses in a percentage format during and
after the poll is closed under the section “Live” and "Result”. Voters will be able to see the total aggregate
responses in a percentage format once they vote simultaneously as well as the number of votes has been collected.`,
    },
    {
        question: "Do polls I have voted on show up on my profile?",
        answer: `No, your vote is guaranteed anonymous, votes will not show on any public section of your profile by default,
it is privately saved for the user only under the “My Vote” section of the individual profile page.
However, subscribers have the option to share their vote via comment function or by sharing their views
directly on their other social media applications or any message channels within or outside the Vottify platform.`,
    },
    {
        question: "Can polls be created both on mobile and desktop?",
        answer: "Yes, polls can be created on mobile and desktop.",
    },
    {
        question: "How many options can a poll have?",
        answer: "A poll can have a maximum of four options to choose from.",
    },
    {
        question: "How many times can a member vote?",
        answer: "A member can submit only one vote on a poll at a time.",
    },
    {
        question: "Is there a character limit?",
        answer: `Yes, poll descriptions are limited to a maximum of 260 characters, poll questions are limited to a maximum
of 80 characters, and options are limited to a maximum of 30 characters.`,
    },
    {
        question: "Can a poll be edited?",
        answer: `No, a poll cannot be edited once it’s created. This is designed to ensure all votes are being given the same
questions and options to conduct the purpose of the poll. However, a poll can be canceled within 15 minutes
after it is created. It is strongly advised that creators perform all necessary checks prior to the poll being published,
and a reminder is embedded in the application to allow creators to be reminded each time on this design prior
to the publication of their polls.`,
    },
    {
        question: "Can I reopen a poll after the closing date?",
        answer: "No, a poll cannot be reopened once it’s closed. The creators are advised to open a new poll instead.",
    },
    {
        question: "Can a public figure, companies, and organizations vote on a poll?",
        answer: "Yes, they may choose to vote, however, their vote will be made public in order to enhance transparency.",
    },
    {
        question: "Can the author of the poll vote on their own poll?",
        answer: "Yes, the author of the poll can vote on their own poll and will be counted as one vote.",
    },
    {
        question: "Can I change my vote?",
        answer: `Poll voters can change their votes up until the close of the poll by clicking/tapping a different option on the poll.
The change will be made simultaneously. After a poll is closed, voters cannot remove their votes.`,
    },
    {
        question: "Will the author be notified of poll responses? Can the notification be turned off?",
        answer: `Yes, the author will be notified in the application about the first vote and subsequent votes.
Vote notifications can be turned off via the application settings.`,
    },
    {
        question: "Who’ll be notified when you create a poll?",
        answer: "Your followers will get notified that you created a poll.",
    },
    {
        question: "Is there any content that’s off-limits?",
        answer: `Off-limit rules for polls include prohibiting hate speech, discrimination, and harassment based on race,
ethnicity, religion, gender, or sexual orientation. Explicit or inappropriate content, spreading false information,
cyberbullying, and promoting illegal activities are strictly prohibited. By following these guidelines, Vottify aims
to create a positive and respectful online community.`,
    },
    {
        question: "Can inappropriate or offensive polls be reported?",
        answer: `Yes, if inappropriate or offensive behavior is observed via the poll, we want to make it easy for people to report it to us.
Reports can be done in the form of an email via report@vottify.com.`,
    },
];

export default defineComponent({
    name: "PollFaq",
    components: {
        IonAccordionGroup,
        IonAccordion,
        IonItem,
        IonLabel,
    },
    computed: {
        faqItems () {
            return faqItems;
        },
    },
});
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.accordion {
    &:first-child {
        border-radius: 9px 9px 0 0;
    }

    &:last-child {
        border-radius: 0 0 9px 9px;
    }
}

.answer {
    margin: 0;
    padding: 11px;

    background-color: rgb(250, 250, 250);

    color: $base-text-color;
}
</style>
