<template>
    <div class="flex flex--100 flex--y-align-center simple-category">
        <div class="flex flex--70">
            <div class="flex flex--100">
                <h2 class="name">{{ name }}</h2>
            </div>
            <div class="flex flex--100">
                <FollowButton
                    :value="isFollowed"
                    @click="onFollowButtonPressed"
                />
            </div>
            <div class="flex flex--100">
                <div class="description">{{ description }}</div>
            </div>
        </div>
        <div class="flex flex--30 flex--x-align-end">
            <SimpleCategoryIcon :alternative-id="category.alternativeId"/>
        </div>
    </div>
</template>

<script>
import { mapStores, } from "pinia";
import { useUserStore, } from "@/stores/UserStore";
import { userManager, } from "@/UserManager";
import SimpleCategoryIcon from "@/components/categories/SimpleCategoryIcon.vue";
import FollowButton from "@/components/buttons/FollowButton.vue";
import { useCategoriesStore } from "@/stores/CategoriesStore";

export default {
    name: "SimpleCategory",
    components: {
        FollowButton,
        SimpleCategoryIcon,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            fullDescriptionIsVisible: false,
        };
    },
    computed: {
        ...mapStores(useCategoriesStore),
        ...mapStores(useUserStore),

        loggedUser () {
            return this.userStore.user;
        },

        category () {
            return this.categoriesStore.categories[this.id];
        },

        name () {
            return this.category.name;
        },

        description () {
            return this.category.description;
        },

        isFollowed () {
            return this.category?.$user?.isFollowing === true;
        },
    },
    methods: {
        async onFollowButtonPressed (e) {
            e.stopImmediatePropagation();

            await this.toggleFollow();
        },

        async toggleFollow () {
            if (!this.isFollowed) {
                await this.follow(this.id);
            }
            else {
                await this.unfollow(this.id);
            }
        },

        async follow () {
            const response = await userManager.categories.follow(this.id);
            const {
                user,
                category,
            } = response.data.result;

            this.userStore.update(user);
            this.categoriesStore.update(category);
        },

        async unfollow () {
            const response = await userManager.categories.unfollow(this.id);
            const {
                user,
                category,
            } = response.data.result;

            this.userStore.update(user);
            this.categoriesStore.update(category);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.simple-category {
    padding: 24px 32px;

    & + & {
        border-top: $base-border;
    }
}

.name {
    margin: 0;

    font-weight: 600;
    font-size: 16px;
    color: $base-text-color;

    letter-spacing: 0.008rem;
}

.description {
    margin: 0;

    font-weight: 400;
    font-size: 14px;
    color: rgb(90, 90, 90);

    letter-spacing: 0.006rem;
}

.follow-button {
    margin: 9px 0;
}

.read-more-button {
    cursor: pointer;

    font-weight: 400;
    font-size: 14px;
    color: rgb(160, 160, 160);

    letter-spacing: 0.006rem;

    &:hover {
        opacity: 0.5;
    }
}

.simple-category-icon {
    width: 64px;

    ::v-deep(.category-icon) {
        width: 64px;
        height: 64px;
    }
}
</style>

<style lang="scss">
ion-item {
    .simple-category {
        margin: 13px 0;
        padding: 0 !important;

        .simple-category-icon {
            width: 53px;
            margin-right: 7px;

            .category-icon {
                width: 53px;
                height: 53px;
            }
        }
    }
}
</style>
