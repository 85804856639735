<template>
    <div class="flex flex--100 flex--x-align-center website-main-footer">
        <div class="flex flex--80 flex--x-align-between main-section">
            <div class="flex secondary-section">
                <div class="flex flex--100 flex--y-align-center">
                    <ApplicationLogo @click="toHomePage"/>
                </div>
            </div>
            <div class="flex flex--direction-column secondary-section">
                <h2 class="title">For Business</h2>
                <a
                    href="mailto:enquiry@vottify.com"
                    target="_blank"
                    class="link"
                >
                    enquiry@vottify.com
                </a>
            </div>
            <div class="flex flex--direction-column secondary-section">
                <h2 class="title">Documents</h2>
                <a
                    href="https://www.iubenda.com/privacy-policy/32870526"
                    target="_blank"
                    class="link"
                >
                    Privacy Policy
                </a>
                <a
                    href="https://www.iubenda.com/terms-and-conditions/32870526"
                    target="_blank"
                    class="link"
                >
                    Terms & Conditions
                </a>
            </div>
            <div class="flex flex--direction-column secondary-section">
                <h2 class="title">Company</h2>
                <p class="paragraph">
                    Vottify Ltd<br>
                    Company Number 14847009<br><br>
                    Brentford, TW8 9ES<br>
                    London, United Kingdom
                </p>
            </div>
        </div>
        <div class="flex flex--100 flex--x-align-center">
            <div class="flex flex--80 trademarks-disclaimer-section">
                <p class="trademarks-disclaimer-text">
                    Vottify Ltd acknowledges and respects all trademark laws.
                    We do not claim any rights to third-party trademarks mentioned on this platform.<br>
                    Any reference to these trademarks is for illustrative purposes
                    only and does not imply any affiliation with or endorsement by
                    the respective trademark owners.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import ApplicationLogo from "@/components/ApplicationLogo";
import { IonSpinner, } from "@ionic/vue";

export default {
    name: "MainFooter",
    components: {
        ApplicationLogo,
        IonSpinner,
    },
    methods: {
        toHomePage () {
            this.$router.push("/");
            window.document.querySelector("ion-content")?.scrollToTop();
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.website-main-footer {
    margin: 0;
    padding: 111px 0;

    background-image: linear-gradient(45deg, rgb(231, 231, 231), rgb(235, 235, 235), rgb(231, 231, 231));
    //border-top: 0.5px solid rgb(220, 220, 220);
}

.secondary-section {
    @media (max-width: $mobile-breaking-point) {
        width: 100%;

        & + & {
            margin-top: 31px;
        }
    }
}

.main-section {
    padding: 0 111px;

    @media (max-width: $mobile-breaking-point) {
        padding: 0;
    }
}

.title {
    font-size: 13px;
    color: rgb(23, 23, 23);
    font-weight: 700;
    margin: 0;
}

.link {
    cursor: pointer;
    transition: opacity 180ms;

    margin: 11px 0 0 0;

    font-size: 13px;
    font-weight: 500;

    color: rgb(23, 23, 23);

    text-decoration: underline;
    text-decoration-color: rgba(201, 201, 201, 0.9);
    text-decoration-thickness: 1px;
    text-underline-offset: 2px;

    &:hover {
        opacity: 0.5;
    }
}

.paragraph {
    max-width: 33vw;

    font-size: 13px;
    font-weight: 400;

    color: rgb(23, 23, 23);
}

.application-logo {
    cursor: pointer;

    width: 151px;
}

.trademarks-disclaimer-section {
    margin-top: 51px;
    padding-top: 51px;

    border-top: 0.5px solid rgba(201, 201, 201, 0.9);
}
.trademarks-disclaimer-text {
    margin: 0;
    padding: 0;

    font-size: 13px;
    font-weight: 400;

    color: rgba(23, 23, 23, 0.8);
}
</style>
