<template>
    <IonPage>
        <IonContent ref="content">
            <MainNavigationBar/>
            <div class="flex flex--100">
                <WebsiteMainHeader/>
                <div class="flex flex--100 flex--x-align-center features-section">
                    <FeatureOverview
                        v-for="(feature, i) in presentation.features.slice(0, 2)"
                        :key="i"
                        :presentation="feature"
                    />
                </div>
                <!--
                <div class="flex flex--100 trending-section">
                    <SearchFeed/>
                </div>
                -->
                <CategorisationIntroduction/>
                <div class="flex flex--100 flex--x-align-center features-section">
                    <FeatureOverview
                        v-for="(feature, i) in presentation.features.slice(2, 4)"
                        :key="i"
                        :presentation="feature"
                    />
                </div>
                <CreatorPricingPreview/>
            </div>
            <MainFooter/>
        </IonContent>
    </IonPage>
</template>

<script>
import { IonContent, IonPage, } from "@ionic/vue";
import CreatorPricingPreview from "@/components/website/CreatorPricingPreview.vue";
import MainFooter from "@/components/website/WebsiteMainFooter.vue";
import MainNavigationBar from "@/components/website/WebsiteMainNavigationBar.vue";
import CategorisationIntroduction from "@/components/website/ContentCategorisation.vue";
import { mapStores } from "pinia";
import { usePollsStore } from "@/stores/PollsStore";
import FeatureOverview from "@/components/website/FeatureOverview.vue";
import WebsiteMainHeader from "@/components/website/WebsiteMainHeader.vue";
import SearchFeed from "@/components/TrendingFeed.vue";
import { sharingFeature, } from "@/utilities/ProductSheet";

const features = [
    {
        screenshotUri: "/assets/mockups/public-figure-poll.png",
        screenshotPosition: "left",
        title: "A Different Social Media",
        subtitle: `
            Where opinion meets impact.
        `,
        paragraphs: [
            {
                title: "Redefining Social Engagement",
                body: `
                    Polls are Vottify's way of delivering content. Engage with polls created by verified creators,
                    where you can vote, comment, and take meaningful action on topics that matter to you.
                `,
            },
            {
                title: "Tailored Content, Your Algorithm",
                body: `
                    Vottify's cutting-edge algorithm delivers content tailored to your interests.
                    Take control of your feed and fine-tune it, ensuring you only see what's relevant and important to you.
                `,
            },
            {
                title: "Verified, Authentic Interactions",
                body: `
                    We guarantee the highest quality and authenticity in our community.
                    Vottify rigorously verifies all users and content, preventing bots and fostering genuine, meaningful engagement.
                `,
            },
        ],
    },
    {
        screenshotUri: "/assets/mockups/ngo-poll.png",
        screenshotPosition: "right",
        title: "Elevate Your Voice",
        subtitle: `
            Transform your opinions into impactful actions.
        `,
        paragraphs: [
            {
                title: "Create and Share Polls",
                body: `
                    Seamlessly design your own polls to solicit feedback or stimulate insightful discussions.
                    Distribute them to your followers and receive prompt, meaningful responses from a vibrant and engaged community.
                `,
            },
            {
                title: "Take Action Beyond Voting",
                body: `
                    With Vottify’s built-in call-to-action tools, your engagement doesn’t stop at voting.
                    Support causes, sign petitions, and make tangible change through the platform.
                `,
            },
            {
                title: "Stay Engaged with Trending Topics",
                body: `
                    Keep up with the latest polls and trending issues.
                    Be part of the conversation on hot topics and see what's capturing public interest
                    and industry-specific trends.
                `,
            },
        ],
    },
    sharingFeature(),
];

export default {
    name: "WebsiteHomeView",
    components: {
        SearchFeed,
        WebsiteMainHeader,
        FeatureOverview,
        CategorisationIntroduction,
        MainNavigationBar,
        MainFooter,
        CreatorPricingPreview,
        IonPage,
        IonContent,
    },
    computed: {
        ...mapStores(usePollsStore),

        presentation () {
            return {
                features,
            };
        },
    },
    ionViewWillLeave () {
        this.$refs.content.$el.scrollToTop();
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.features-section {
    margin: 111px 0;
}

.creator-pricing-preview {
    margin: 0 0 111px 0;
}

::v-deep(.search-feed) {
    background-color: rgb(0, 0, 0);

    .header {
        display: none;
    }

    .title,
    .trending-icon {
        color: rgb(213, 213, 213);
    }

    .simple-poll-square-preview {
        background-color: transparent;
        background-image: none;
        border: none;

        .creator-name {
            color: rgba(255, 255, 255, 0.9);
        }

        .question {
            padding: 19px 7px;

            background-image: linear-gradient(45deg, black 0%, rgba($brand-color, 0.15) 100%);

            color: rgb(255, 255, 255);
        }
    }
}
</style>
