<template>
    <IonPage>
    <IonHeader>
        <IonToolbar>
            <IonButtons slot="start">
                <IonBackButton v-if="actualStep === 1"/>
                <IonBackButton default-href="#" :disabled="isWaitingServerResponse" @click="onBackButtonClick" v-else/>
            </IonButtons>
            <IonTitle>Sign Up</IonTitle>
            <IonButtons slot="end">
                <IonButton :disabled="!canNext" @click="nextStep" v-show="actualStep < totalSteps">
                    Next
                    <IonIcon :icon="chevronForward"/>
                </IonButton>
            </IonButtons>
        </IonToolbar>
        <IonToolbar>
            <!-- <progress-bar> -->
            <div class="flex flex--100">
                <SimpleProgressBar :progress="progress"/>
            </div>
            <!-- </progress-bar> -->
        </IonToolbar>
    </IonHeader>
    <IonContent>
        <IonItemGroup class="input-section" v-show="actualStep === 1">
            <!-- <first-name> -->
            <IonItem>
                <IonInput
                    v-model="user.firstName"
                    label="First name"
                    label-placement="floating"
                    autocomplete="given-name"
                ></IonInput>
            </IonItem>
            <IonItem lines="none" class="input-error-section" v-show="validations.firstNameIsMissing">
                <span class="input-error-text">First name is required</span>
            </IonItem>
            <!-- </first-name> -->
            <!-- <last-name> -->
            <IonItem>
                <IonInput
                    v-model="user.lastName"
                    label="Last name"
                    label-placement="floating"
                    autocomplete="family-name"
                ></IonInput>
            </IonItem>
            <IonItem lines="none" class="input-error-section" v-show="validations.lastNameIsMissing">
                <span class="input-error-text">Last name is required</span>
            </IonItem>
            <!-- </last-name> -->
            <!-- <age> -->
            <IonItem>
                <IonInput
                    label="Age"
                    label-placement="floating"
                    :maxlength="2"
                    v-model="user.age"
                    @focusout="onAgeInputFocusedOut"
                >
                </IonInput>
            </IonItem>
            <IonItem lines="none" v-show="validations.ageIsMissing" class="input-error-section">
                <span class="input-error-text">Age is required</span>
            </IonItem>
            <IonItem lines="none" v-show="validations.ageIsNaN" class="input-error-section">
                <span class="input-error-text">Age must be a number</span>
            </IonItem>
            <IonItem lines="none" v-show="validations.ageIsUnder12" class="input-error-section">
                <span class="input-error-text">You must be at least 12 years old</span>
            </IonItem>
            <!-- </age> -->
        </IonItemGroup>
        <IonItemGroup class="input-section" v-show="actualStep === 2">
            <!-- <gender> -->
            <IonItem>
                <IonSelect v-model="user.genderType" label="Gender" label-placement="floating" interface="action-sheet">
                    <IonSelectOption
                        v-for="value in Object.values(GenderType)"
                        :key="value"
                        :value="value"
                    >
                        {{ $t(`genderType.${value}`) }}
                    </IonSelectOption>
                </IonSelect>
            </IonItem>
            <!-- </gender> -->
            <!-- <income-range> -->
            <IonItem>
                <IonSelect
                    v-model="user.incomeRangeType"
                    label="Income Range"
                    label-placement="floating"
                    interface="action-sheet"
                >
                    <IonSelectOption
                        v-for="value in Object.values(IncomeRangeType)"
                        :key="value"
                        :value="value"
                    >
                        {{ $t(`incomeRangeType.${value}`) }}
                    </IonSelectOption>
                </IonSelect>
            </IonItem>
            <!-- </income-range> -->
            <!-- <education> -->
            <IonItem>
                <IonSelect v-model="user.educationType" label="Education" label-placement="floating" interface="action-sheet">
                    <IonSelectOption
                        v-for="value in Object.values(EducationType)"
                        :key="value"
                        :value="value"
                    >
                        {{ $t(`educationType.${value}`) }}
                    </IonSelectOption>
                </IonSelect>
            </IonItem>
            <!-- </education> -->
            <!-- <religion> -->
            <IonItem>
                <IonSelect v-model="user.religionType" label="Religion" label-placement="floating" interface="action-sheet">
                    <IonSelectOption
                        v-for="value in Object.values(ReligionType)"
                        :key="value"
                        :value="value"
                    >
                        {{ $t(`religionType.${value}`) }}
                    </IonSelectOption>
                </IonSelect>
            </IonItem>
            <!-- </religion> -->
            <!-- <industry> -->
            <IonItem>
                <IonSelect v-model="user.industryType" label="Industry" label-placement="floating" interface="action-sheet">
                    <IonSelectOption
                        v-for="value in Object.values(IndustryType)"
                        :key="value"
                        :value="value"
                    >
                        {{ $t(`industryType.${value}`) }}
                    </IonSelectOption>
                </IonSelect>
            </IonItem>
            <!-- </industry> -->
        </IonItemGroup>
        <IonItemGroup class="input-section" v-show="actualStep === 3">
            <!-- <mobile-number> -->
            <MobileNumberInput
                v-model:mobile-number="localMobileNumber"
                v-model:country-iso="countryIso"
                v-model:dial-code="dialCode"
            ></MobileNumberInput>
            <IonItem lines="none" v-show="validations.mobileNumberIsMissing" class="input-error-section">
                <span class="input-error-text">Mobile number is required</span>
            </IonItem>
            <IonItem lines="none" v-show="validations.mobileNumberIsNaN" class="input-error-section">
                <span class="input-error-text">Mobile number must be a number</span>
            </IonItem>
            <!-- </mobile-number> -->
            <!-- <password> -->
            <IonItem>
                <IonInput
                    type="password"
                    label="Password"
                    label-placement="floating"
                    autocomplete="new-password"
                    v-model="user.password"
                ></IonInput>
            </IonItem>
            <IonItem lines="none" v-show="validations.passwordIsMissing" class="input-error-section">
                <span class="input-error-text">Password is required</span>
            </IonItem>
            <IonItem lines="none" v-show="validations.passwordIsMissingRequirements" class="input-error-section">
                <span class="input-error-text">Password must have at least 6 characters</span>
            </IonItem>
            <!-- </password> -->
            <!-- <mobile-number-code> -->
            <div class="flex flex--100 flex--x-align-center" v-show="sendMobileNumberVerificationRemainingSeconds === 0">
                <IonButton
                    class="simple-button"
                    fill="outline"
                    :disabled="!canNext || isWaitingServerResponse"
                    @click="sendMobileNumberVerificationCode"
                >
                    Send Mobile Number Code
                </IonButton>
            </div>
            <div class="flex flex--100" v-show="sendMobileNumberVerificationRemainingSeconds > 0">
                <span class="send-verification-code-text">
                    We have sent a code to your mobile number<br>
                    You can send another code
                    in {{ sendMobileNumberVerificationRemainingSeconds }} seconds
                </span>
            </div>
            <IonItem v-show="mobileNumberVerificationCodeSentAt">
                <IonInput
                    label="Verification Code"
                    label-placement="floating"
                    autocomplete="one-time-code"
                    v-model="mobileNumberVerificationCode"
                ></IonInput>
            </IonItem>
            <div class="flex flex--100 flex--x-align-center" v-show="mobileNumberVerificationCodeSentAt">
                <IonButton
                    class="simple-button"
                    fill="outline"
                    :disabled="!mobileNumberVerificationCode || isWaitingServerResponse"
                    @click="verifyMobileNumberByCode"
                >
                    Confirm & Sign Up
                </IonButton>
                <div class="flex flex--100 flex--x-align-center">
                    <span class="legal-confirmation-text">
                        By Signing Up, you agree to Vottify's
                        <span class="legal-link" @click="openPrivacyPolicy">Privacy Policy</span>
                        and
                        <span class="legal-link" @click="openTermsAndConditions">Terms and Conditions</span>.
                    </span>
                </div>
            </div>
            <!-- </mobile-number-code> -->
        </IonItemGroup>
    </IonContent>
    </IonPage>
</template>

<script>
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonDatetime,
    IonDatetimeButton,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonItemGroup,
    IonLabel,
    IonModal,
    IonNote,
    IonPage,
    IonProgressBar,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
} from "@ionic/vue";
import { userManager, } from "@/UserManager";
import { chevronForward, } from "ionicons/icons";
import ApplicationLogo from "@/components/ApplicationLogo.vue";
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
import MobileNumberInput from "@/components/inputs/MobileNumberInput.vue";
import {
    EducationType,
    GenderType,
    getDeviceLanguageIso,
    IncomeRangeType,
    IndustryType,
    ReligionType
} from "@/utilities/Utilities";
import SimpleProgressBar from "@/components/mixed/SimpleProgressBar.vue";
import { mobileNumberIsValid } from "@/utilities/MobileNumberUtilities";
import { Browser } from "@capacitor/browser";

const MIN_MOBILE_NUMBER_VERIFICATION_S = 20;

export default {
    name: "SignUpView",
    components: {
        SimpleProgressBar,
        ApplicationLogo,
        IonPage,
        IonContent,
        IonItem,
        IonLabel,
        IonInput,
        IonDatetime,
        IonDatetimeButton,
        IonModal,
        IonSelect,
        IonSelectOption,
        IonItemGroup,
        IonProgressBar,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonButton,
        IonBackButton,
        IonButtons,
        IonIcon,
        IonNote,
        MobileNumberInput,
    },

    data () {
        return {
            isWaitingServerResponse: false,
            actualStep: 1,
            totalSteps: 3,
            user: {
                mobileNumber: "",
                mobileNumberVerificationId: "",
                password: "",
                firstName: "",
                lastName: "",
                genderType: "",
                age: "",
                incomeRangeType: "",
                educationType: "",
                religionType: "",
                industryType: "",
                languageIso: "",
            },
            localMobileNumber: "",
            countryIso: userManager.preferredCountry.iso2,
            dialCode: userManager.preferredCountry.dialCode,

            mobileNumberVerificationCode: "",
            mobileNumberVerificationCodeSentAt: undefined,
            sendMobileNumberVerificationRemainingSeconds: 0,

            ageValidationIsEnabled: false,

            validations: {
                firstNameIsMissing: undefined,
                lastNameIsMissing: undefined,
                ageIsMissing: undefined,
                ageIsNaN: undefined,
                ageIsUnder12: undefined,

                mobileNumberIsMissing: undefined,
                mobileNumberIsNaN: undefined,
                passwordIsMissing: undefined,
                passwordIsMissingRequirements: undefined,
            },
        };
    },

    computed: {
        IndustryType () {
            return IndustryType
        },

        ...mapStores(useUserStore),

        mobileNumber () {
            return `+${this.dialCode}${this.localMobileNumber}`;
        },

        progress () {
            return this.actualStep / this.totalSteps;
        },

        chevronForward () {
            return chevronForward;
        },

        GenderType () {
            return GenderType;
        },

        IncomeRangeType () {
            return IncomeRangeType;
        },

        EducationType () {
            return EducationType;
        },

        ReligionType () {
            return ReligionType;
        },

        canNext () {
            if (this.isWaitingServerResponse) {
                return false;
            }

            switch (this.actualStep) {
                case 1: {
                    return (
                        this.user.firstName
                        && this.user.lastName
                        && Number.isFinite(Number(this.user.age))
                        && this.user.age >= 12
                    );
                }
                case 2: {
                    return (
                        Number.isFinite(this.user.genderType)
                        && Number.isFinite(this.user.incomeRangeType)
                        && Number.isFinite(this.user.educationType)
                        && Number.isFinite(this.user.religionType)
                        && Number.isFinite(this.user.industryType)
                    );
                }
                case 3: {
                    return (
                        mobileNumberIsValid(this.user.mobileNumber)
                        && this.user.password
                        && this.user.password.length >= 6
                    );
                }
            }

            return false;
        },
    },

    methods: {
        onBackButtonClick (event) {
            event.stopImmediatePropagation();

            if (this.actualStep > 1) {
                --this.actualStep;
            }
            else {
                this.$router.back();
            }
        },

        toHomeView () {
            this.$router.push("/navigation/feed");
        },

        async sendMobileNumberVerificationCode () {
            await userManager.sendMobileNumberVerificationCode(this.mobileNumber);

            this.mobileNumberVerificationCodeSentAt = Date.now();
            this.sendMobileNumberVerificationRemainingSeconds = MIN_MOBILE_NUMBER_VERIFICATION_S;

            const intervalId = setInterval(() => {
                this.sendMobileNumberVerificationRemainingSeconds = this.getRemainingSeconds();

                if (this.sendMobileNumberVerificationRemainingSeconds === 0) {
                    clearInterval(intervalId);
                }
            }, 1000);
        },

        getRemainingSeconds () {
            if (!this.mobileNumberVerificationCodeSentAt) {
                return 0;
            }

            const elapsedSeconds =
                ((Date.now() - this.mobileNumberVerificationCodeSentAt) / 1000) | 0;

            if (elapsedSeconds >= MIN_MOBILE_NUMBER_VERIFICATION_S) {
                return 0;
            }

            return MIN_MOBILE_NUMBER_VERIFICATION_S - elapsedSeconds;
        },
        async verifyMobileNumberByCode () {
            if (!this.mobileNumberVerificationCode) {
                return;
            }

            const response =
                await userManager.verifyMobileNumberByCode(this.mobileNumber, this.mobileNumberVerificationCode);

            switch (response.status) {
                case "error": {
                    switch (response.meta.type) {
                        case "InvalidDataError": {
                            //await presentInvalidDataAlert();

                            break;
                        }
                        case "MobileAlreadyUsedError": {
                            //await presentMobileAlreadyUsedAlert();

                            break;
                        }
                    }

                    break;
                }
                case "ok": {
                    this.user.mobileNumberVerificationId =
                        response.result.verificationId;

                    await this.nextStep();

                    break;
                }
            }
        },
        async nextStep () {
            if (this.actualStep === this.totalSteps) {
                await this.signUp();
            }
            else {
                ++this.actualStep;
            }
        },
        onAgeInputFocusedOut () {
            this.enableAgeValidation();
            this.validateAge();
        },
        async signUp () {
            if (this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;

            try {
                const response = await userManager.signUp(this.user);
                const loggedUser = response?.result?.user;
                this.isWaitingServerResponse = false;

                switch (response.status) {
                    case "error": {
                        switch (response.meta.type) {
                            case "InvalidDataError": {
                                //await presentInvalidDataAlert();

                                break;
                            }
                            case "MobileAlreadyUsedError": {
                                //await presentMobileAlreadyUsedAlert();

                                break;
                            }
                        }

                        break;
                    }
                    case "ok": {
                        userManager.setPreferredCountryIso(this.countryIso);
                        this.userStore.update(loggedUser);
                        this.toHomeView();

                        break;
                    }
                }
            }
            catch (e) {
                console.log(e);

                this.isWaitingServerResponse = false;
            }
        },

        enableAgeValidation () {
            this.ageValidationIsEnabled = true;
        },

        validateAge () {
            if (this.ageValidationIsEnabled) {
                this.validations.ageIsUnder12 = this.user.age && this.user.age < 12;
            }
        },

        async openPrivacyPolicy () {
            await Browser.open({
                url: "https://www.iubenda.com/privacy-policy/32870526",
            });
        },

        async openTermsAndConditions () {
            await Browser.open({
                url: "https://www.iubenda.com/terms-and-conditions/32870526",
            });
        },
    },
    watch: {
        mobileNumber (value) {
            this.user.mobileNumber = value;
        },

        "user.firstName" (value) {
            this.validations.firstNameIsMissing = !value;
        },

        "user.lastName" (value) {
            this.validations.lastNameIsMissing = !value;
        },

        "user.age" (value) {
            this.validations.ageIsMissing = !value;
            this.validations.ageIsNaN = !Number.isFinite(Number(value));

            this.validateAge();
        },

        localMobileNumber (value) {
            this.validations.mobileNumberIsMissing = !value;
            this.validations.mobileNumberIsNaN = !Number.isFinite(Number(value));
        },

        "user.password" (value) {
            this.validations.passwordIsMissing = !value;
            this.validations.passwordIsMissingRequirements = (
                value
                && value.length < 6
            );
        },
    },
    created () {
        this.user.mobileNumber = this.mobileNumber;
    },
    async mounted () {
        this.user.languageIso = await getDeviceLanguageIso();
    },
    ionViewDidLeave () {
        this.$resetState();
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";
@import "@/theme/utilities.scss";

.send-verification-code-text {
    margin: 32px 16px 16px 16px;
    padding: 0;

    font-size: 14px;
    font-weight: 400;
    color: $base-text-color;
}

.simple-button {
    margin: 32px 0 16px 0;

    min-width: 320px;
    max-width: 80vw;

    font-weight: 600;
}

.input-section {
    padding-top: 21px;

    background-color: transparent;
}

.legal-confirmation-text {
    margin: 0 15px;

    font-size: 11px;
    color: $base-text-color;
}

.legal-link {
    cursor: pointer;
    transition: opacity 180ms;

    margin: 11px 0 0 0;

    font-size: inherit;
    font-weight: 500;

    color: rgb(23, 23, 23);

    text-decoration: underline;
    text-decoration-color: rgba(201, 201, 201, 0.9);
    text-decoration-thickness: 1px;
    text-underline-offset: 2px;

    &:hover {
        opacity: 0.5;
    }
}
</style>
