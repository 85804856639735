<template>
    <svg class="flex three-dots-icon" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path d="M18,16c0,1.1-.9,2-2,2s-2-.9-2-2,.9-2,2-2,2,.9,2,2ZM23.75,14c-1.1,0-2,.9-2,2s.9,2,2,2,2-.9,2-2-.9-2-2-2ZM6.25,16c0,1.1.9,2,2,2s2-.9,2-2-.9-2-2-2-2,.9-2,2Z"/>
    </svg>
</template>

<script>
import { defineComponent, } from "vue";

export default defineComponent({
    name: "ThreeDotsIcon",
});
</script>
