<template>
    <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonBackButton/>
                </IonButtons>
                <IonTitle>Privacy & Security</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <IonList>

                <IonListHeader>
                    <IonLabel>Third-Party Integrations</IonLabel>
                </IonListHeader>

                <IonItem :button="true" @click="toMyIntegrationsView">
                    <IonLabel>My Integrations</IonLabel>
                </IonItem>

            </IonList>
            <IonList>

                <IonListHeader>
                    <IonLabel>Policies & Agreements</IonLabel>
                </IonListHeader>
                <IonItem :button="true" @click="openPrivacyPolicy">
                    <IonLabel>Privacy Policy</IonLabel>
                </IonItem>
                <IonItem :button="true" @click="openTermsAndConditions">
                    <IonLabel>Terms & Conditions</IonLabel>
                </IonItem>

            </IonList>

            <IonList>

                <IonListHeader>
                    <IonLabel>Account</IonLabel>
                </IonListHeader>
                <IonItem :button="true" @click="deleteAccount">
                    <IonLabel color="danger">Delete Account</IonLabel>
                </IonItem>

            </IonList>

        </IonContent>
    </IonPage>
</template>

<script>
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonTitle,
    IonToolbar,
} from "@ionic/vue";
import { Browser } from "@capacitor/browser";
import { presentConfirmationModal, presentMessageModal } from "@/utilities/Utilities";
import { userManager } from "@/UserManager";

export default {
    name: "PrivacySecurityView",
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonList,
        IonListHeader,
        IonItem,
        IonLabel,
        IonButtons,
        IonBackButton,
        IonButton,
    },
    methods: {
        async openPrivacyPolicy () {
            await Browser.open({
                url: "https://www.iubenda.com/privacy-policy/32870526",
            });
        },

        async openTermsAndConditions () {
            await Browser.open({
                url: "https://www.iubenda.com/terms-and-conditions/32870526",
            });
        },

        toMyIntegrationsView () {
            this.$router.push("/user/integrations");
        },

        signOut () {
            userManager.signOut();
            this.$router.push("/sign-in");
        },

        async deleteAccount () {
            if (!await presentConfirmationModal("Are you sure you want to delete this account?")) {
                return;
            }

            await presentMessageModal("Your account will be deleted in 7 days, sign in back to cancel the deletion");
            await userManager.markAccountDeletion();
            this.signOut();
        },
    },
};
</script>
