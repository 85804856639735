<template>
    <IonPage>
    <IonTabs :key="primaryKey">
        <IonRouterOutlet/>
        <IonTabBar
            slot="bottom"
            class="flex flex--100 flex--x-align-center flex--y-align-center navigation"
            :class="navigationModifiers"
        >
            <IonTabButton class="tab-button" tab="feed" href="/navigation/feed">
                <div class="flex">
                    <HomeIcon class="tab-icon"/>
                </div>
            </IonTabButton>
            <IonTabButton class="tab-button" tab="search" href="/navigation/search">
                <div class="flex">
                    <SearchIcon class="tab-icon"/>
                </div>
            </IonTabButton>
            <IonTabButton class="tab-button" @click="toNewPollView">
                <div class="flex">
                    <AddItemIcon class="tab-icon"/>
                </div>
            </IonTabButton>
            <IonTabButton class="tab-button notifications-button" tab="notifications" href="/navigation/notifications">
                <div class="flex">
                    <NotificationsIcon class="tab-icon"/>
                    <span
                        class="notifications-button__light"
                        v-show="unseenNotificationsCounter > 0"
                    ></span>
                </div>
            </IonTabButton>
            <IonTabButton class="tab-button" tab="user" :href="loggedUserViewLocation">
                <div class="flex">
                    <UserIcon class="tab-icon"/>
                </div>
            </IonTabButton>
        </IonTabBar>
    </IonTabs>
    </IonPage>
</template>

<script>
import {IonPage, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs,} from "@ionic/vue";
import HomeIcon from "@/components/icons/HomeIcon.vue";
import SearchIcon from "@/components/icons/SearchIcon.vue";
import NotificationsIcon from "@/components/icons/NotificationsIcon.vue";
import UserIcon from "@/components/icons/UserIcon.vue";
import AddItemIcon from "@/components/icons/PlusIcon.vue";
import {mapStores} from "pinia";
import {useUserStore} from "@/stores/UserStore";

export default {
    name: "MainTabView",
    components: {
        IonPage,
        IonRouterOutlet,
        IonTabBar,
        IonTabButton,
        IonTabs,
        HomeIcon,
        SearchIcon,
        NotificationsIcon,
        UserIcon,
        AddItemIcon,
    },
    data () {
        return {
            // Used to reset the tab bar when navigating outside of it
            primaryKey: Math.random().toString(),
        };
    },
    computed: {
        ...mapStores(useUserStore),

        loggedUser () {
            return this.userStore.user;
        },

        isCreator () {
            return this.loggedUser.isCreator;
        },

        unseenNotificationsCounter () {
            return this.loggedUser.$user.unseenNotifications.length ?? 0;
        },

        inFeedView () {
            return this.$route.path === "/navigation/feed";
        },

        inPollView () {
            return this.$route.path.startsWith("/navigation/polls");
        },

        navigationModifiers () {
            return {
                "navigation--in-feed": this.inFeedView || this.inPollView,
            };
        },

        loggedUserViewLocation () {
            return `/navigation/users/${this.loggedUser.id}`;
        },
    },
    methods: {
        toNewPollView () {
            this.$router.push("/poll/new");
        },
    },
    ionViewDidLeave () {
        this.primaryKey = Math.random().toString();
    },
}
</script>

<style lang="scss" scoped>
@import "@/theme/globals.scss";
@import "@/theme/palette.scss";

.navigation {
    transition:
        background-color 190ms,
        border-top-color 190ms;

    position: sticky;
    left: 0;
    bottom: 0;

    height: $main-bottom-navigation-height;
    padding-bottom: env(safe-area-inset-bottom);

    background-color: rgb(255, 255, 255);
    border-top: 0.5px solid rgb(220, 220, 220);

    z-index: 10;

    // <translucent-background>
    &::before {
        $offset: 14px;

        transition: opacity 190ms, border-color 190ms;

        content: "";

        position: absolute;
        left: 50%;
        top: 0 + $offset / 2;

        width: calc(100% - 23px);
        height: $main-bottom-navigation-height - $offset;

        background-color: rgba(255, 255, 255, 0.055);
        backdrop-filter: blur(21px);
        border: 1.5px solid transparent;
        border-radius: 999px;

        transform: translateX(-50%);

        opacity: 0;
    }
    // </translucent-background>

    &--in-feed {
        position: absolute;

        background-color: transparent;
        border-top-color: transparent;

        // <translucent-background>
        &::before {
            border-color: rgba(38, 38, 40, 0.3);

            opacity: 1;
        }
        // </translucent-background>
    }
}

.tab-button {
    background-color: transparent;
}

.tab-icon {
    transition: color 190ms;

    width: 33px;

    color: rgb(122, 122, 122);
}

.tab-selected {
    .tab-icon {
        color: rgb(22, 22, 22);
    }
}

.notifications-button {
    position: relative;

    &__light {
        position: absolute;
        left: calc(50% + 6px);
        top: calc(50% - 8px);

        width: 13px;
        height: 13px;

        background-color: rgb(255, 255, 255);
        border: 5px solid rgb(230, 34, 34);
        box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.002);
        border-radius: 50%;

        transform: translate(-50%, -50%);
    }
}

.navigation--in-feed {
    .tab-icon {
        color: rgb(202, 202, 202);
    }

    .tab-selected {
        .tab-icon {
            color: rgb(202, 202, 202);
        }
    }
}
</style>
