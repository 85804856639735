<template>
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 32 32" class="flex heart-icon">
        <path d="M16,26.25c-1.19922,0-11.23047-6.78223-11.49902-12.95654-.08887-2.0415.57324-3.90869,1.86426-5.25732,1.25684-1.31299,2.99219-2.03613,4.88477-2.03613,2.2998,0,3.83789,1.08545,4.75,2.22266.91211-1.13721,2.4502-2.22266,4.75-2.22266,1.89258,0,3.62793.72314,4.88477,2.03613,1.29102,1.34863,1.95312,3.21582,1.86426,5.25732-.26855,6.17432-10.2998,12.95654-11.49902,12.95654ZM16.27148,24.29346h.01074-.01074ZM11.25,8c-1.3418,0-2.56348.50391-3.43945,1.41943-.92383.96436-1.37695,2.27393-1.31152,3.78711.19922,4.58887,7.71484,9.96777,9.50098,10.95215,1.78613-.98438,9.30176-6.36328,9.50098-10.95215.06543-1.51318-.3877-2.82275-1.31152-3.78711-.87598-.91553-2.09766-1.41943-3.43945-1.41943-2.97656,0-3.78613,2.51855-3.79395,2.54395-.12891.41992-.5166.70605-.95605.70605s-.82715-.28613-.95605-.70605c-.03125-.09717-.8584-2.54395-3.79395-2.54395Z"/>
    </svg>
</template>

<script>
import { defineComponent, } from "vue";

export default defineComponent({
    name: "HeartIcon",
});
</script>
