<template>
    <IonPage>
        <IonContent>
            <MainNavigationBar/>
            <WebsiteMainHeader title="Mission" subtitle=""/>
            <div class="flex flex--100 flex--x-align-center">
                <div class="flex flex--80">
                    <div class="flex flex--100">
                        <h2 class="title">Vottify's Mission</h2>
                    </div>
                    <div class="flex flex--100">
                        <p class="paragraph">
                            Vottify is on a bold mission, revolutionising how NGOs and the influencer community harness
                            poll data—the cornerstone of our business. Picture a dynamic platform, resonating with the
                            vigour of your voice, passionately advocating causes in lockstep with the UN SDGs.
                            <br><br>
                            We don't just discuss change; we're sparking a transformative blaze, empowering NGOs, charitable
                            foundations, corporations, public figures and influencers, think tanks, and international
                            organisations to collaborate and sculpt a sustainable future. For instance, many new
                            influencers run their own donations and philanthropic activities, much like traditional NGOs.
                            <br><br>
                            As the dedicated platform for these emerging communities, Vottify acts as the nerve centre
                            where your voice, amplified by cutting-edge tech, yields monumental impacts—fostering
                            collaboration and maximising collective influences across.
                            <br><br>
                            Yet, our commitment runs deeper. Vottify isn't just a platform; it's a
                            potent catalyst for action. Through
                            seamless integration, users express opinions in polls and drive change via donations and
                            petitions. Imagine your voice translating into tangible results—financial support for causes
                            and community mobilisation through impactful actions.
                            <br><br>
                            Looking ahead, Vottify aspires to extend its influence beyond NGOs and the influencer
                            community, envisioning a
                            future where public poll data catalyses meaningful actions—from corporate boardrooms
                            to government chambers. By enhancing direct corporate collaboration with SDG aligned
                            philanthropic champions across the entire ecosystem, Vottify seeks to create powerful
                            infrastructure and synergies that drive impactful changes.
                            <br><br>
                            In a business landscape where
                            every decision shapes the future, Vottify is not just on a journey for the next generation;
                            we're sparking a revolution—a relentless force for change where every decision matters, and change
                            is not just an aspiration but an inescapable reality.
                        </p>
                    </div>
                    <div class="flex flex--100 flex--x-align-center">
                        <img src="/assets/site/graph2.png" alt="" class="sdgs-image"/>
                    </div>
                </div>
            </div>
            <MainFooter/>
        </IonContent>
    </IonPage>
</template>

<script>
import { IonContent, IonPage, } from "@ionic/vue";
import MainFooter from "@/components/website/WebsiteMainFooter.vue";
import MainNavigationBar from "@/components/website/WebsiteMainNavigationBar.vue";
import WebsiteMainHeader from "@/components/website/WebsiteMainHeader.vue";

export default {
    name: "MissionView",
    components: {
        WebsiteMainHeader,
        MainNavigationBar,
        MainFooter,
        IonPage,
        IonContent,
    },
};
</script>

<style lang="scss" scoped>
.title {
    max-width: 671px;
    margin: 111px 0 0 0;

    font-size: 45px;
    font-weight: 800;
    color: rgb(23, 23, 23);
}

.paragraph {
    max-width: 1071px;
    margin: 3px 0 0 0;

    font-size: 26px;
    font-weight: 300;
    letter-spacing: -0.01rem;

    color: rgb(23, 23, 23);
}

.sdgs-image {
    width: 100%;
    max-width: 671px;
    margin-top: 31px;
}
</style>
