<template>
    <IonPage v-if="!isLoading">
    <IonHeader>
        <IonToolbar class="ion-toolbar">
            <IonButtons slot="start">
                <IonBackButton/>
            </IonButtons>
            <IonTitle>Poll</IonTitle>
        </IonToolbar>
    </IonHeader>
    <IonContent class="ion-content">
        <div class="flex flex--100 top-section">
            <SimpleButton text="Statistics" @click="toPollStatisticsView"/>
            <SimpleButton text="QR Code & Link" @click="showSharePollOverlay"/>
        </div>
        <div class="flex flex--100 flex--x-align-center pending-section" v-if="isPending">
            <span class="pending-text">{{ pendingPollMessage }}</span>
        </div>
        <SimplePoll :id="poll.id"/>
        <span class="flex flex--100 bottom-navigation-area"></span>
        <hr class="safe-bottom-area"/>
    </IonContent>
    </IonPage>
</template>

<script>
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonTextarea,
    IonTitle,
    IonToolbar, isPlatform,
} from "@ionic/vue";
import { userManager, } from "@/UserManager";
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
import SimplePoll from "@/components/polls/SimplePoll.vue";
import { pollsStore, } from "@/main";
import { chevronForward, } from "ionicons/icons";
import SimpleButton from "@/components/buttons/SimpleButton.vue";
import { useUtilityStore, } from "@/stores/UtilityStore";
import { APP_STORE_URI, PLAY_STORE_URI } from "@/utilities/Utilities";

export default {
    name: "PollView",
    components: {
        SimpleButton,
        IonIcon,
        SimplePoll,
        IonPage,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonList,
        IonListHeader,
        IonItem,
        IonLabel,
        IonButtons,
        IonBackButton,
        IonButton,
        IonTextarea,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            isLoading: false,
        };
    },
    computed: {
        ...mapStores(useUserStore),
        ...mapStores(useUtilityStore),

        sharedComponents () {
            return this.utilityStore.sharedComponents;
        },

        loggedUser () {
            return this.userStore.user;
        },

        isCreator () {
            return this.loggedUser.isCreator;
        },

        poll () {
            return pollsStore.get(this.id);
        },

        publishAt () {
            return new Date(this.poll.publishAt);
        },

        isPending () {
            return this.publishAt.getTime() > Date.now();
        },

        chevronForward () {
            return chevronForward;
        },

        pendingPollMessage () {
            const publishAt = this.publishAt;
            const timeParts = publishAt.toLocaleTimeString().split(":");
            const localeDate = publishAt.toLocaleDateString();
            let message = "Poll opens";

            if ((new Date()).toLocaleDateString() !== localeDate) {
                message += ` on ${localeDate}`;
            }
            else {
                message += " today"
            }

            message += ` at ${timeParts[0]}:${timeParts[1]}`;

            return message;
        },
    },
    methods: {
        async fetch () {
            this.isLoading = true;

            const poll = await userManager.getPoll(this.id);

            pollsStore.update(poll);

            this.isLoading = false;
        },

        toPollStatisticsView () {
            this.$router.push(`/navigation/polls/${this.poll.id}/statistics`);
        },

        showSharePollOverlay () {
            this.sharedComponents.sharePollOverlay.show(this.poll);
        },
    },
    created () {
        if (!userManager.isLogged) {
            if (isPlatform("desktop") || isPlatform("mobileweb")) {
                if (isPlatform("ios") || isPlatform("iphone")) {
                    window.location.href = APP_STORE_URI;
                }
                else {
                    window.location.href = PLAY_STORE_URI;
                }
            }

            return;
        }

        if (!this.poll) {
            this.fetch();
        }
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";
@import "@/theme/globals.scss";

.ion-toolbar {
    --background: rgb(0, 0, 0);
    --border-color: rgb(33, 33, 33);
    --ion-text-color: rgb(255, 255, 255);
    --ion-text-color-rgb: 255, 255, 255;
}

.ion-content {
    &::part(background) {
        background-color: rgb(0, 0, 0);
    }
}

.top-section {
    margin: 0 0 13px 0;
    padding: 13px 21px;

    border-bottom: 0.5px solid rgb(33, 33, 33);
}

.simple-button {
    & + & {
        margin-left: 7px;
    }
}

.pending-section {
    margin: 0;
    padding: 15px;

    border-bottom: 0.5px solid rgba(0, 0, 0, 0.05);
}

.pending-text {
    padding: 11px 15px;

    font-size: 11px;
    font-weight: 600;
    letter-spacing: normal;
    text-transform: uppercase;

    background-image:
        linear-gradient(75deg, rgb(233, 233, 233), rgb(243, 243, 243));
    border-radius: 999px;

    color: rgba(0, 0, 0, 0.9);
}

.bottom-navigation-area {
    height: $main-bottom-navigation-height;
}
</style>
