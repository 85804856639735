<template>
    <svg fill="currentColor" class="flex user-activity-icon" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.5,7.5h-3.67188l-1.41406-1.41406c-.7793-.78027-2.04883-.78027-2.82812,0l-1.41406,1.41406h-3.67188c-1.6543,0-3,1.3457-3,3v12c0,1.6543,1.3457,3,3,3h13c1.6543,0,3-1.3457,3-3v-12c0-1.6543-1.3457-3-3-3ZM11.83008,23.5c-.17871,0-.26855-.1333-.29102-.17432-.07422-.12939-.02344-.21387-.00684-.24219.38867-.65283,1.60156-2.17139,4.46777-2.17139s4.0791,1.51855,4.46777,2.17188c.0166.02783.06738.1123-.00684.2417-.02246.04102-.1123.17432-.29102.17432h-8.33984ZM23.5,22.5c0,.55127-.44824,1-1,1h-.03937c.06958-.49219-.00916-.99481-.27411-1.43896-1.17285-1.97168-3.48535-3.14893-6.18652-3.14893s-5.01367,1.17725-6.18652,3.14844c-.26495.44446-.34369.94727-.27411,1.43945h-.03937c-.55176,0-1-.44873-1-1v-12c0-.55127.44824-1,1-1h4.08594c.26562,0,.51953-.10547.70703-.29297l1.70703-1.70703,1.70703,1.70703c.1875.1875.44141.29297.70703.29297h4.08594c.55176,0,1,.44873,1,1v12ZM16,10.81201c-2.0957,0-3.7998,1.70459-3.7998,3.7998,0,2.0957,1.7041,3.80029,3.7998,3.80029s3.7998-1.70459,3.7998-3.80029c0-2.09521-1.7041-3.7998-3.7998-3.7998ZM16,16.41211c-.99219,0-1.7998-.80762-1.7998-1.80029,0-.99219.80762-1.7998,1.7998-1.7998s1.7998.80762,1.7998,1.7998c0,.99268-.80762,1.80029-1.7998,1.80029Z"/>
    </svg>
</template>

<script>
import { defineComponent, } from "vue";

export default defineComponent({
    name: "UserActivityIcon",
});
</script>
