<template>
    <div class="flex flex--100 simple-poll-comments">
        <div class="flex flex--100">
            <!-- <logged-user-comments> -->
            <SimplePollComment
                v-for="(comment, i) in loggedUserComments"
                :key="comment.id"
                :poll-id="pollId"
                v-model:comment="loggedUserComments[i]"
                @selected="onCommentSelected"
            />
            <!-- </logged-user-comments> -->
            <!-- <comments> -->
            <SimplePollComment
                v-for="(comment, i) in comments"
                :key="comment.id"
                :poll-id="pollId"
                v-model:comment="comments[i]"
                @selected="onCommentSelected"
            />
            <!-- </comments> -->
        </div>
    </div>
</template>

<script>
import { userManager, } from "@/UserManager";
import { IonInfiniteScroll, IonInfiniteScrollContent, } from "@ionic/vue";
import SimplePollComment from "@/components/polls/comments/SimplePollComment.vue";
import { pollsStore } from "@/main";
import { cloneDeep } from "lodash/lang";

export default {
    name: "SimplePollComments",
    components: {
        SimplePollComment,
        IonInfiniteScroll,
        IonInfiniteScrollContent,
    },
    props: {
        pollId: {
            type: String,
            required: true,
        },
        page: {
            type: Number,
            required: true,
        },
        selectedComment: {
            type: Object,
        },
    },
    data () {
        return {
            isLoading: false,
            comments: [],
            loggedUserComments: [],
        };
    },
    methods: {
        onCommentSelected (comment) {
            this.$emit("update:selected-comment", comment);
        },

        async fetchComments () {
            if (this.isLoading) {
                return;
            }

            this.isLoading = true;
            const { page, userComments,} =
                await userManager.getPollComments(this.pollId, this.page);
            this.isLoading = false;

            this.comments = [ ...this.comments, ...page, ];
            this.loggedUserComments = [ ...this.loggedUserComments, ...userComments, ]
                .filter((comment => !comment.parentComment));
        },

        async postComment (commentBody, parentCommentId = undefined) {
            if (this.isLoading) {
                return;
            }

            this.isLoading = true;
            const { poll, comment, } =
                await userManager.commentPoll(this.pollId, commentBody, parentCommentId);
            this.isLoading = false;

            pollsStore.update(poll);

            const existingIndex =
                this.loggedUserComments.findIndex((e) => e.id === comment.id);

            if (existingIndex === -1) {
                this.loggedUserComments = [ comment, ...this.loggedUserComments, ];
            }
            else {
                const commentsCopy = cloneDeep(this.loggedUserComments);

                commentsCopy[existingIndex] = comment;

                this.loggedUserComments = commentsCopy;
            }
        },
    },
    computed: {
        allComments () {
            return [
                ...this.loggedUserComments,
                ...this.comments,
            ];
        },
    },
    created () {
        this.fetchComments();
    },
};
</script>
