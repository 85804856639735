<template>
    <div class="flex feature-overview" ref="root">
        <div class="flex screenshot-section" v-if="presentation.screenshotPosition === 'left'">
            <img
                :src="presentation.screenshotUri"
                class="screenshot"
                alt=""
            />
        </div>
        <div class="flex card-section">
            <div class="flex flex--100">
                <h2 class="title">{{ presentation.title }}</h2>
            </div>
            <div class="flex flex--100 flex--x-align-center" v-if="presentation.subtitle">
                <span class="subtitle" v-html="presentation.subtitle"></span>
            </div>
            <div
                class="flex flex--100 card"
                v-for="(paragraph, i) in presentation.paragraphs"
                :key="i"
            >
                <div class="flex flex--100 flex--y-align-center">
                    <ApplicationIcon/>
                    <h2 class="card-title" v-html="paragraph.title"></h2>
                </div>
                <div class="flex flex--100">
                    <p class="card-text" v-html="paragraph.body"></p>
                </div>
            </div>
        </div>
        <div class="flex screenshot-section" v-if="presentation.screenshotPosition === 'right'">
            <img
                :src="presentation.screenshotUri"
                class="screenshot"
                alt=""
            />
        </div>
    </div>
</template>

<script>
import { defineComponent, } from "vue";
import ApplicationIcon from "@/components/ApplicationIcon.vue";

export default defineComponent({
    name: "FeatureOverview",
    props: {
        presentation: {
            type: Object,
        },
    },
    components: {
        ApplicationIcon,
    },
    data () {
        return {
            intersectionObserver: undefined,
        };
    },
    mounted () {
        this.intersectionObserver = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting === true) {
                this.$emit("viewport-entered", this.presentation.id);
            }
            else {
                this.$emit("viewport-exited", this.presentation.id);
            }
        }, {
            root: window.document.body,
            threshold: 0.3,
        });

        this.intersectionObserver.observe(this.$refs.root);
    },
    unmounted() {
        this.intersectionObserver.disconnect();

        this.intersectionObserver = undefined;
    },
});
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.feature-overview {
    position: relative;

    max-width: 1177px;

    padding-top: 111px;
    border-top: 0.5px solid rgb(220, 220, 220);

    & + & {
        margin-top: 111px;
    }

    &:nth-child(odd) {
        .screenshot-section {
            margin: 0 51px 0 0;
        }
    }

    &:nth-child(even) {
        .screenshot-section {
            margin: 0 0 0 51px;
        }
    }

    @media (max-width: $mobile-breaking-point) {
        &:nth-child(odd) {
            .screenshot-section {
                margin: 0 31px 51px 31px;
            }
        }

        &:nth-child(even) {
            .screenshot-section {
                margin: 51px 31px 0 31px;
            }
        }
    }
}

.title {
    width: 100%;

    text-align: center;

    margin: 0;
    font-size: 51px;
    font-weight: 800;
    color: rgb(23, 23, 23);

    @media (max-width: $mobile-breaking-point) {
        margin: 0 31px 0 31px;
    }
}

.subtitle {
    margin: 10px 0;

    width: 100%;

    max-width: 771px;
    text-align: center;

    font-size: 27px;
    font-weight: 300;
    letter-spacing: -0.01rem;

    color: rgb(23, 23, 23);

    @media (max-width: $mobile-breaking-point) {
        margin: 10px 31px 0 31px;
    }
}

.card-section {
    flex: 1;

    z-index: 2;
}

.screenshot-section {
    @media (max-width: $mobile-breaking-point) {
        width: 100%;

        justify-content: center;
    }
}

.card {
    min-height: 173px;
    margin: 27px 17px 0 17px;
    padding: 21px;

    background-color: rgb(243, 243, 243);
    border-radius: 12px;
}

.card-title {
    margin: 0;
    padding: 0;

    font-size: 25px;
    font-weight: 700;
    letter-spacing: 0.02rem;
    color: rgb(23, 23, 23);
}

.card-text {
    margin: 11px 0 0 0;
    padding: 0;

    font-size: 19px;
    font-weight: 400;

    color: rgb(88, 88, 88);
}

.screenshot {
    width: 100%;
    max-width: 371px;
    height: auto;
    margin: 0;

    z-index: 3;
}

.application-icon {
    width: 21px;
    margin-right: 11px;

    transform: scaleX(-1);
}
</style>
