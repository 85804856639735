<template>
    <div class="flex flex--100 poll-swiper" ref="swiper">
        <!-- <hidden-upper-poll> -->
        <SimplePoll
            :key="previousPoll.id"
            :id="previousPoll.id"
            :collect-metrics="false"
            :description-max-length="pollDescriptionMaxLength"
            :class="previousPollModifiers"
            :style="pollStyle"
            v-if="previousPoll"
        />
        <!-- </hidden-upper-poll> -->
        <!-- <visible-poll> -->
        <SimplePoll
            :key="currentPoll.id"
            :id="currentPoll.id"
            :description-max-length="pollDescriptionMaxLength"
            :class="currentPollModifiers"
            :style="pollStyle"
            v-if="currentPoll"
            @voted="onPollVoted"
        />
        <!-- </visible-poll> -->
        <!-- <hidden-lower-poll> -->
        <SimplePoll
            :key="upcomingPoll.id"
            :id="upcomingPoll.id"
            :collect-metrics="false"
            :description-max-length="pollDescriptionMaxLength"
            :class="upcomingPollModifiers"
            :style="pollStyle"
            v-if="upcomingPoll"
        />
        <!-- </hidden-lower-poll> -->
    </div>
</template>

<script>
import { createGesture, } from "@ionic/core";
import SimplePoll from "@/components/polls/SimplePoll.vue";

const SWIPE_TRANSITION_MS = 900;
const ACCELERATION_ENABLED = false;

export default {
    name: "PollSwiper",
    components: {
        SimplePoll,
    },
    props: {
        polls: {
            type: Array,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    data () {
        return {
            isSwipingUp: false,
            isSwipingDown: false,
            swipingGesture: undefined,
            swipingGestureIsActive: false,
            swipeTransitionMs: SWIPE_TRANSITION_MS,
            swipeTransitionRestoreTimeoutId: undefined,
            pollDescriptionMaxLength: 150,
        };
    },
    computed: {
        isSwiping () {
            return this.isSwipingUp || this.isSwipingDown;
        },

        previousPoll () {
            return this.polls[this.index - 1];
        },

        currentPoll () {
            return this.polls[this.index];
        },

        upcomingPoll () {
            return this.polls[this.index + 1];
        },

        previousPollModifiers () {
            return {
                "previous-poll": true,
                "previous-poll--swiping-down": this.isSwipingDown,
            };
        },

        currentPollModifiers () {
            return {
                "current-poll": true,
                "current-poll--swiping-up": this.isSwipingUp,
                "current-poll--swiping-down": this.isSwipingDown,
            };
        },

        upcomingPollModifiers () {
            return {
                "upcoming-poll": true,
                "upcoming-poll--swiping-up": this.isSwipingUp,
            };
        },

        pollStyle () {
            return {
                "transition": `opacity ${this.swipeTransitionMs / 1.5}ms, transform ${this.swipeTransitionMs}ms`,
            };
        },
    },
    methods: {
        swipeDown () {
            if (this.index === 0) {
                return;
            }

            if (this.isSwiping) {
                return;
            }

            this.isSwipingDown = true;
            this.$emit("swiped");

            setTimeout(() => {
                this.$emit("update:index", this.index - 1);

                this.isSwipingDown = false;

                this.onSwiped();
            }, this.swipeTransitionMs + 20);
        },
        swipeUp () {
            if (this.index === this.polls.length - 1) {
                return;
            }

            if (this.isSwiping) {
                return;
            }

            this.isSwipingUp = true;
            this.$emit("swiped");

            setTimeout(() => {
                this.$emit("update:index", this.index + 1);

                this.isSwipingUp = false;

                this.onSwiped();
            }, this.swipeTransitionMs + 20);
        },

        onPollVoted () {
            this.$emit("poll-voted");
        },

        onSwiped () {
            if (ACCELERATION_ENABLED) {
                // Used to increase swiping speed as the user keeps swiping
                this.swipeTransitionMs = Math.max(this.swipeTransitionMs - 100, 500);

                // Used to reset the swipe speed to default if the user didn't swipe for more than 1.5s
                this.swipeTransitionRestoreTimeoutId = setTimeout(() => {
                    this.swipeTransitionMs = SWIPE_TRANSITION_MS;
                }, 1500);
            }
        },
    },
    mounted () {
        const gesture = createGesture({
            el: this.$refs.swiper,
            gesturePriority: 100,
            direction: "y",
            threshold: 50,
            gestureName: "swipe",
            onMove: (e) => {
                if (this.swipingGestureIsActive) {
                    return;
                }

                if (this.swipeTransitionRestoreTimeoutId) {
                    clearTimeout(this.swipeTransitionRestoreTimeoutId);
                }

                this.swipingGestureIsActive = true;
                const { startY, currentY, } = e;

                if (currentY < startY) {
                    this.swipeUp();
                }
                else {
                    this.swipeDown();
                }
            },
            onEnd: () => {
                this.swipingGestureIsActive = false;
            },
        });

        gesture.enable(true);

        this.swipingGesture = gesture;
    },
    unmounted () {
        this.swipingGesture.destroy();
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/poll-feed.scss";

.poll-swiper {
    overflow: visible;
    position: relative;

    height: 100%;
}

.simple-poll {
    will-change: transform, opacity;

    position: absolute;
    left: 0;
    top: 50%;

    transform: translateY(-50%);

    z-index: 0;
}

.previous-poll,
.current-poll--swiping-up {
    pointer-events: none;

    transform: translateY(-150%) scale(0.8);
    opacity: 0;
}
.upcoming-poll,
.current-poll--swiping-down {
    pointer-events: none;

    transform: translateY(50%) scale(0.8);
    opacity: 0;
}

// Previous poll moving back as current poll
.previous-poll--swiping-down {
    opacity: 1;

    transform: translateY(-50%);
}
// Upcoming poll moving back as current poll
.upcoming-poll--swiping-up {
    opacity: 1;

    transform: translateY(-50%);
}
</style>
