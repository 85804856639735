<template>
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 32 32" class="flex search-icon" xml:space="preserve">
        <path d="M25.707,24.293l-3.682-3.682C23.258,19.071,24,17.122,24,15c0-4.962-4.038-9-9-9s-9,4.038-9,9s4.038,9,9,9c2.122,0,4.071-0.742,5.611-1.975l3.682,3.682C24.488,25.902,24.744,26,25,26s0.512-0.098,0.707-0.293C26.098,25.316,26.098,24.684,25.707,24.293z M15,22c-3.86,0-7-3.14-7-7s3.14-7,7-7s7,3.14,7,7S18.86,22,15,22z"/>
    </svg>
</template>

<script>
export default {
    name: "SearchIcon",
};
</script>
