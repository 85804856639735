<template>
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 32 32" class="flex send-message-icon">
        <path d="M25.31494,6.68506c-.39014-.39014-.9624-.53369-1.49219-.37646L7.41406,11.23145c-.81982.24658-1.37451.95801-1.4126,1.81348s.45117,1.61328,1.24561,1.93115l6.9834,2.79346,2.79346,6.98291c.30664.76611,1.02148,1.24805,1.83838,1.24805.03076,0,.06152-.00049.09277-.00195.85547-.03809,1.56689-.59277,1.81348-1.4126l4.92285-16.40967c.1582-.5293.01416-1.10059-.37646-1.49121ZM7.98926,13.14746l13.49927-4.05005-6.73077,6.73077-6.76849-2.68073ZM18.88135,24.00977l-2.70776-6.76929,6.73663-6.73663-4.02887,13.50592Z"/>
    </svg>
</template>

<script>
import { defineComponent, } from "vue";

export default defineComponent({
    name: "SendMessageIcon",
});
</script>
