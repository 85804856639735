export type ProductFeature = {
    id: string;
    screenshotUri?: string;
    screenshotPosition: "left" | "right";
    title?: string;
    shortTitle?: string;
    subtitle?: string;
    paragraphs: ProductFeatureParagraph[],
};

export type ProductFeatureParagraph = {
    title: string;
    body: string;
};

export const analyticsFeature = (): ProductFeature => ({
    id: "analytics",
    screenshotUri: "/assets/mockups/public-figure-analytics.png",
    screenshotPosition: "right",
    title: "Unlock Powerful Insights and Analytics",
    shortTitle: "Unlock Analytics",
    paragraphs: [
        {
            title: "Granular Audience Insights by Demographics",
            body: `
                Gain valuable insights from votes mapped by key demographics such
                as Religion, Education, Income Range, Gender, Country, and Industry. Use this detailed data
                to tailor your products, messaging, and campaigns to specific audience segments.
            `,
        },
        {
            title: "Insightful Analytics",
            body: `
                Access detailed analytics to understand audience sentiment, behavior, and preferences.
                Use these insights to drive smarter marketing strategies, product developments, and service improvements.
            `,
        },
    ],
});

export const sharingFeature = (): ProductFeature => ({
    id: "sharing",
    screenshotUri: "/assets/mockups/ngo-qr.png",
    screenshotPosition: "left",
    title: "Expand Your Reach with Easy Sharing",
    shortTitle: "Easy Sharing",
    paragraphs: [
        {
            title: "QR Codes & Links",
            body: `
                Effortlessly share your polls through QR Codes or Direct Links for easy access.
            `,
        },
        {
            title: "Cross-Platform",
            body: `
                Vottify polls are designed to work seamlessly across all platforms, whether on Web, Android, or iOS.
            `,
        },
    ],
});

export const integrationsFeature = (): ProductFeature => ({
    id: "integrations",
    screenshotUri: "/assets/mockups/voted-poll.png",
    screenshotPosition: "right",
    title: "Social Media Integrations",
    shortTitle: "Social Media Integrations",
    paragraphs: [
        {
            title: "Integrations",
            body: `
                Vottify seamlessly integrates with LinkedIn, Instagram, Facebook and X (formerly Twitter),
                making it easier than ever to connect with your audience across multiple platforms.
            `,
        },
        {
            title: "Automation & Centralization",
            body: `
                Automatically share your polls across all your social media accounts and centralize
                audience opinion data in one place for streamlined insights.
            `,
        },
    ],
});
