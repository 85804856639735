<template>
    <div class="flex flex--100 mobile-creator-pricing-table">
        <div class="flex flex--100 flex--x-align-center pricing-cards-section">
            <Swiper
                :slides-per-view="1.26"
                :center-insufficient-slides="true"
                :centered-slides="true"
                @swiper="onSwiper"
                @slide-change="onSlideChanged"
            >
                <SwiperSlide>
                    <LightCreatorPlan/>
                </SwiperSlide>
                <SwiperSlide>
                    <StandardCreatorPlan/>
                </SwiperSlide>
                <SwiperSlide>
                    <PremiumCreatorPlan/>
                </SwiperSlide>
            </Swiper>
        </div>
    </div>
</template>

<script>
import ApplicationIcon from "@/components/ApplicationIcon.vue";
import { defineComponent, } from "vue";
import { Swiper, SwiperSlide, } from 'swiper/vue';
import StandardCreatorPlan from "@/components/creators/plans/StandardCreatorPlan.vue";
import LightCreatorPlan from "@/components/creators/plans/LightCreatorPlan.vue";
import PremiumCreatorPlan from "@/components/creators/plans/PremiumCreatorPlan.vue";
import "swiper/css";

export default defineComponent({
    name: "CreatorPlanSwiper",
    components: {
        PremiumCreatorPlan,
        LightCreatorPlan,
        StandardCreatorPlan,
        ApplicationIcon,
        Swiper,
        SwiperSlide,
    },
    props: {
        activeIndex: {
            type: Number,
        },
    },
    data () {
        return {
            swiper: undefined,
        };
    },
    methods: {
        onSwiper (swiper) {
            this.swiper = swiper;

            swiper.slideTo(this.activeIndex);
        },

        onSlideChanged () {
            this.$emit("update:active-index", this.swiper.activeIndex);
        },
    },
    watch: {
        activeIndex (value) {
            this.swiper.slideTo(value);
        },
    },
});
</script>

<style lang="scss" scoped>
::v-deep(.plan) {
    .info-button {
        display: none;
    }
}
</style>
