<template>
    <svg fill="currentColor" class="flex good-health-well-being-icon" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 100 100">
        <path d="M76 68.991c-.307 0-.613-.094-.875-.282l-3.07-2.205C64.5 61.079 64.5 57.067 64.5 55.749a6.77 6.77 0 0 1 6.763-6.763c1.789 0 3.489.724 4.737 1.943a6.8 6.8 0 0 1 4.737-1.943 6.77 6.77 0 0 1 6.763 6.763c0 1.318 0 5.33-7.555 10.756l-3.07 2.205a1.496 1.496 0 0 1-.875.281zm-4.737-17.005a3.767 3.767 0 0 0-3.763 3.763c0 2.515 2.121 5.314 6.305 8.319L76 65.645l2.195-1.576c4.184-3.005 6.305-5.804 6.305-8.319a3.767 3.767 0 0 0-3.763-3.763 3.74 3.74 0 0 0-3.388 2.123 1.5 1.5 0 0 1-2.7 0 3.736 3.736 0 0 0-3.386-2.124zM55.491 75.837l12.706-49.301 6.382 18.807a1.501 1.501 0 0 0 2.841-.964l-6.922-20.396a2.48 2.48 0 0 0-2.461-1.695 2.484 2.484 0 0 0-2.327 1.874L53.272 72.427l-4.045-37.483a2.483 2.483 0 0 0-2.393-2.23 2.487 2.487 0 0 0-2.552 2.046l-4.368 23.713-3.559-17.2a2.488 2.488 0 0 0-2.396-1.993h-.053a2.487 2.487 0 0 0-2.424 1.894l-3.654 14.615H14a1.5 1.5 0 1 0 0 3h14.219a2.497 2.497 0 0 0 2.425-1.894l3.218-12.869 3.633 17.559c.246 1.187 1.243 1.978 2.475 1.994a2.474 2.474 0 0 0 2.432-2.047l4.21-22.857 3.973 36.81a2.47 2.47 0 0 0 2.307 2.224 2.468 2.468 0 0 0 2.599-1.872z"/>
    </svg>
</template>

<script>
export default {
    name: "GoodHealthWellBeingIcon",
};
</script>
