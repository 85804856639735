<template>
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" class="flex vottify-icon" viewBox="0 0 100 100">
        <path d="M74.326 27.029c-1.346-4.593-5.767-9.228-12.609-9.228-3.081 0-4.888 1.032-6.48 1.943-1.501.857-2.796 1.598-5.236 1.598s-3.735-.74-5.236-1.598c-1.593-.911-3.399-1.943-6.48-1.943-6.843 0-11.264 4.635-12.609 9.228C22.741 37.035 33.684 54.666 47.665 63c-.3.179-.692.366-1.215.545a1.487 1.487 0 0 0-.933 1.862 1.51 1.51 0 0 0 1.559 1.046C44.13 72.021 37.6 78.33 35.456 79.342a1.5 1.5 0 1 0 1.279 2.712c2.579-1.216 10.511-8.623 13.581-15.398.262.081.538.18.829.301a1.497 1.497 0 0 0 1.857-.624 1.506 1.506 0 0 0-.351-1.937c-.008-.007-.517-.434-.856-1.082 14.238-8.196 25.502-26.151 22.531-36.285zM50.213 60.768a1.56 1.56 0 0 0-.283-.015c-.046.002-.09.013-.135.019-14.113-8.036-23.6-24.855-21.242-32.899.77-2.626 3.686-7.071 9.73-7.071 2.284 0 3.539.717 4.992 1.548 1.635.935 3.487 1.993 6.725 1.993s5.09-1.059 6.725-1.993c1.453-.831 2.708-1.548 4.992-1.548 6.045 0 8.961 4.445 9.73 7.071 2.357 8.042-7.126 24.857-21.234 32.895z"/>
    </svg>
</template>

<script>
export default {
    name: "VottifyIcon",
}
</script>
