<template>
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 32 32" class="flex add-item-icon" xml:space="preserve">
        <path d="M20,6h-8c-3.309,0-6,2.691-6,6v8c0,3.309,2.691,6,6,6h8c3.309,0,6-2.691,6-6v-8C26,8.691,23.309,6,20,6z M24,20c0,2.206-1.794,4-4,4h-8c-2.206,0-4-1.794-4-4v-8c0-2.206,1.794-4,4-4h8c2.206,0,4,1.794,4,4V20z M21.5,16c0,0.553-0.448,1-1,1H17v3.5c0,0.553-0.448,1-1,1s-1-0.447-1-1V17h-3.5c-0.552,0-1-0.447-1-1s0.448-1,1-1H15v-3.5c0-0.553,0.448-1,1-1s1,0.447,1,1V15h3.5C21.052,15,21.5,15.447,21.5,16z"/>
    </svg>
</template>

<script>
export default {
    name: "AddItemIcon",
};
</script>
