<template>
    <div class="flex flex--100 flex--x-align-center content-categorisation">
        <div class="flex flex--70 flex--x-align-center header">
            <div class="flex flex--100">
                <h2 class="title">Content Categorisation</h2>
            </div>
            <div class="flex flex--100">
                <span class="subtitle">
                    Vottify's cutting-edge algorithm curates high-quality, relevant content by aligning it with the
                    <a class="link" href="https://sdgs.un.org/goals" target="_blank">United Nations Sustainable Development Goals</a>
                    (SDGs) and current Social Media Trends.
                </span>
            </div>
        </div>
        <div class="flex flex--100 categories-section">
            <div class="flex flex--100 flex--direction-column icons-track">
                <!--TRACK 1-->
                <div class="flex flex--100 flex--y-align-center track track--1">
                    <div class="flex flex--y-align-center category-section">
                        <AffordableCleanEnergyIcon class="category-icon"/>
                        <span class="category-name">Affordable & Clean Energy</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <CleanWaterSanitationIcon class="category-icon"/>
                        <span class="category-name">Clean Water & Sanitation</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <ClimateActionIcon class="category-icon"/>
                        <span class="category-name">Climate Action</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <DecentWorkEconomicGrowthIcon class="category-icon"/>
                        <span class="category-name">Decent Work & Economic Growth</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <ResponsibleConsumptionProductionIcon class="category-icon"/>
                        <span class="category-name">Responsible Consumption & Production</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <AffordableCleanEnergyIcon class="category-icon"/>
                        <span class="category-name">Affordable & Clean Energy</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <CleanWaterSanitationIcon class="category-icon"/>
                        <span class="category-name">Clean Water & Sanitation</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <ClimateActionIcon class="category-icon"/>
                        <span class="category-name">Climate Action</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <DecentWorkEconomicGrowthIcon class="category-icon"/>
                        <span class="category-name">Decent Work & Economic Growth</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <ResponsibleConsumptionProductionIcon class="category-icon"/>
                        <span class="category-name">Responsible Consumption & Production</span>
                    </div>
                </div>
                <!--/TRACK 1-->

                <!--TRACK 2-->
                <div class="flex flex--100 flex--y-align-center track track--2">
                    <div class="flex flex--y-align-center category-section">
                        <GenderEqualityIcon class="category-icon"/>
                        <span class="category-name">Gender Equality</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <GoodHealthWellBeingIcon class="category-icon"/>
                        <span class="category-name">Good Health & Well-Being</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <IndustryInnovationInfrastructureIcon class="category-icon"/>
                        <span class="category-name">Industry-Innovation & Infrastructure</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <LifeBelowWaterIcon class="category-icon"/>
                        <span class="category-name">Life Below Water</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <LifeOnLandIcon class="category-icon"/>
                        <span class="category-name">Life On Land</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <GenderEqualityIcon class="category-icon"/>
                        <span class="category-name">Gender Equality</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <GoodHealthWellBeingIcon class="category-icon"/>
                        <span class="category-name">Good Health & Well-Being</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <IndustryInnovationInfrastructureIcon class="category-icon"/>
                        <span class="category-name">Industry-Innovation & Infrastructure</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <LifeBelowWaterIcon class="category-icon"/>
                        <span class="category-name">Life Below Water</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <LifeOnLandIcon class="category-icon"/>
                        <span class="category-name">Life On Land</span>
                    </div>
                </div>
                <!--/TRACK 2-->

                <!--TRACK 3-->
                <div class="flex flex--100 flex--y-align-center track track--3">
                    <div class="flex flex--y-align-center category-section">
                        <NoPovertyIcon class="category-icon"/>
                        <span class="category-name">No Poverty</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <PartnershipForTheGoalsIcon class="category-icon"/>
                        <span class="category-name">Partnership For The Goals</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <PeaceJusticeStrongInstitutionIcon class="category-icon"/>
                        <span class="category-name">Peace-Justice & Strong Institution</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <QualityEducationIcon class="category-icon"/>
                        <span class="category-name">Quality Education</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <ReducedInequalitiesIcon class="category-icon"/>
                        <span class="category-name">Reduced Inequalities</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <NoPovertyIcon class="category-icon"/>
                        <span class="category-name">No Poverty</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <PartnershipForTheGoalsIcon class="category-icon"/>
                        <span class="category-name">Partnership For The Goals</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <PeaceJusticeStrongInstitutionIcon class="category-icon"/>
                        <span class="category-name">Peace-Justice & Strong Institution</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <QualityEducationIcon class="category-icon"/>
                        <span class="category-name">Quality Education</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <ReducedInequalitiesIcon class="category-icon"/>
                        <span class="category-name">Reduced Inequalities</span>
                    </div>
                </div>
                <!--/TRACK 3-->

                <!--TRACK 4-->
                <div class="flex flex--100 flex--y-align-center track track--4">
                    <div class="flex flex--y-align-center category-section">
                        <SocialMediaTrendingIcon class="category-icon"/>
                        <span class="category-name">Social Media Trending</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <SustainableCitiesCommunitiesIcon class="category-icon"/>
                        <span class="category-name">Sustainable Cities & Communities</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <VottifyIcon class="category-icon"/>
                        <span class="category-name">Vottify</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <ZeroHungerIcon class="category-icon"/>
                        <span class="category-name">Zero Hunger</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <SocialMediaTrendingIcon class="category-icon"/>
                        <span class="category-name">Social Media Trending</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <SustainableCitiesCommunitiesIcon class="category-icon"/>
                        <span class="category-name">Sustainable Cities & Communities</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <VottifyIcon class="category-icon"/>
                        <span class="category-name">Vottify</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <ZeroHungerIcon class="category-icon"/>
                        <span class="category-name">Zero Hunger</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <SocialMediaTrendingIcon class="category-icon"/>
                        <span class="category-name">Social Media Trending</span>
                    </div>
                    <div class="flex flex--y-align-center category-section">
                        <SustainableCitiesCommunitiesIcon class="category-icon"/>
                        <span class="category-name">Sustainable Cities & Communities</span>
                    </div>
                </div>
                <!--/TRACK 4-->
            </div>
        </div>

    </div>
</template>

<script>
import AffordableCleanEnergyIcon from "@/components/icons/AffordableCleanEnergyIcon";
import CleanWaterSanitationIcon from "@/components/icons/CleanWaterSanitationIcon";
import ClimateActionIcon from "@/components/icons/ClimateActionIcon";
import DecentWorkEconomicGrowthIcon from "@/components/icons/DecentWorkEconomicGrowthIcon";
import GenderEqualityIcon from "@/components/icons/GenderEqualityIcon";
import GoodHealthWellBeingIcon from "@/components/icons/GoodHealthWellBeingIcon";
import IndustryInnovationInfrastructureIcon from "@/components/icons/IndustryInnovationInfrastructureIcon";
import LifeBelowWaterIcon from "@/components/icons/LifeBelowWaterIcon";
import LifeOnLandIcon from "@/components/icons/LifeOnLandIcon";
import NoPovertyIcon from "@/components/icons/NoPovertyIcon";
import PartnershipForTheGoalsIcon from "@/components/icons/PartnershipForTheGoalsIcon";
import PeaceJusticeStrongInstitutionIcon from "@/components/icons/PeaceJusticeStrongInstitutionIcon";
import QualityEducationIcon from "@/components/icons/QualityEducationIcon";
import ReducedInequalitiesIcon from "@/components/icons/ReducedInequalitiesIcon";
import ResponsibleConsumptionProductionIcon from "@/components/icons/ResponsibleConsumptionProductionIcon";
import SdgIcon from "@/components/icons/SdgIcon";
import SocialMediaTrendingIcon from "@/components/icons/SocialMediaTrendingIcon";
import SustainableCitiesCommunitiesIcon from "@/components/icons/SustainableCitiesCommunitiesIcon";
import VottifyIcon from "@/components/icons/VottifyIcon";
import ZeroHungerIcon from "@/components/icons/ZeroHungerIcon";

export default {
    name: "ContentCategorisation",
    components: {
        AffordableCleanEnergyIcon,
        CleanWaterSanitationIcon,
        ClimateActionIcon,
        DecentWorkEconomicGrowthIcon,
        GenderEqualityIcon,
        GoodHealthWellBeingIcon,
        IndustryInnovationInfrastructureIcon,
        LifeBelowWaterIcon,
        LifeOnLandIcon,
        NoPovertyIcon,
        PartnershipForTheGoalsIcon,
        PeaceJusticeStrongInstitutionIcon,
        QualityEducationIcon,
        ReducedInequalitiesIcon,
        ResponsibleConsumptionProductionIcon,
        SdgIcon,
        SocialMediaTrendingIcon,
        SustainableCitiesCommunitiesIcon,
        VottifyIcon,
        ZeroHungerIcon,
    },
}
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";


.content-categorisation {
    position: relative;

    padding: 131px 0;
    margin: 0;

    background-color: rgb(0, 0, 0);
}

.title {
    width: 100%;

    margin: 0;
    font-size: 52px;
    font-weight: 800;
    color: rgb(255, 255, 255);
}

.subtitle {
    margin: 21px 0 51px 0;
    padding: 0;

    width: 100%;
    max-width: 1011px;

    font-size: 32px;
    font-weight: 300;
    letter-spacing: -0.01rem;

    color: rgb(255, 255, 255);
}


.category-section {
    flex-wrap: nowrap;
    width: 10%;
}

.category-icon {
    width: 61px;
}
.category-name {
    margin: 21px 11px;
    padding: 0;

    font-weight: 400;
    font-size: 25px;
    color: rgb(255, 255, 255);
}
// Icons slideshow
.icons-track {

}

.categories-section {
    overflow: hidden;

    margin: 31px 0 0 0;

    ::v-deep(svg) {
        fill: rgb(255, 255, 255);
    }
}

.track {
    $animation-duration: 120s;

    will-change: transform;

    width: 4000px;
    height: 80px;

    & + & {
        margin-top: 42px;
    }

    &--1 {
        animation: icons-slide $animation-duration linear infinite;
    }

    &--2 {
        animation: icons-slide $animation-duration linear infinite;

        position: relative;
        left: -100px;
    }

    &--3 {
        animation: icons-slide $animation-duration linear infinite;
    }

    &--4 {
        animation: icons-slide $animation-duration linear infinite;

        position: relative;
        left: -100px;
    }
}

@keyframes icons-slide {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-50%);
    }
    100% {
        transform: translateX(0);
    }
}

@media (max-width: $mobile-breaking-point) {
    .header {
        width: 100%;
    }

    .title {
        margin: 10px 40px 0 40px;

        font-size: 44px;
    }
    .subtitle {
        margin: 10px 40px 40px 40px;
    }
}

.link {
    position: relative;
    cursor: pointer;
    transition: color 300ms, text-decoration-color 300ms;

    margin: 11px 0 0 0;

    text-decoration: underline;
    text-decoration-style: solid;
    text-underline-offset: 7px;
    text-decoration-color: rgb(181, 181, 181);
    color: inherit;

    &:hover {
        color: rgb(111, 111, 111);
        text-decoration-color: rgb(111, 111, 111);
    }
}
</style>
