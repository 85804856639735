<template>
    <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonTitle>Vottify for Creators</IonTitle>
                <IonButtons slot="end">
                    <IonButton @click="toUserView">
                        Next
                        <IonIcon :icon="chevronForward"/>
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>
        <IonContent class="ion-content">
            <div class="flex flex--100 flex--y-align-center main">
                <div class="flex flex--100 flex--x-align-center">
                    <span class="congrats-text">
                        Congratulations!<br>
                        You're a Creator
                    </span>
                </div>
            </div>
        </IonContent>
    </IonPage>
</template>

<script>
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonDatetime,
    IonDatetimeButton,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonItemGroup,
    IonLabel,
    IonModal,
    IonNote,
    IonPage,
    IonProgressBar,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
} from "@ionic/vue";

import ApplicationLogo from "@/components/ApplicationLogo.vue";
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
import HeartIcon from "@/components/icons/HeartIcon.vue";
import { drawFireworks, } from "@/utilities/Utilities";
import { chevronForward } from "ionicons/icons";

export default {
    name: "CreatorSignUpCongratsView",
    components: {
        HeartIcon,
        ApplicationLogo,
        IonPage,
        IonContent,
        IonItem,
        IonLabel,
        IonInput,
        IonDatetime,
        IonDatetimeButton,
        IonModal,
        IonSelect,
        IonSelectOption,
        IonItemGroup,
        IonProgressBar,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonButton,
        IonBackButton,
        IonButtons,
        IonIcon,
        IonNote,
    },
    computed: {
        ...mapStores(useUserStore),

        loggedUser () {
            return this.userStore.user;
        },

        loggedUserViewLocation () {
            return `/navigation/users/${this.loggedUser.id}`;
        },

        chevronForward () {
            return chevronForward;
        },
    },
    methods: {
        toUserView () {
            this.$router.push(this.loggedUserViewLocation);
        },
    },
    mounted () {
        drawFireworks(3500);
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.ion-content {
    &::part(background) {
        background-image: linear-gradient(
            45deg,
            rgb(255, 255, 255),
            rgba($brand-color, 0.15) 50%,
            rgb(255, 255, 255)
        );
    }
}

.main {
    height: 100%;
}

.congrats-text {
    cursor: default;

    margin: 21px 0;

    font-size: 37px;
    font-weight: 700;
    color: rgb(3, 3, 3);

    text-align: center;

    -webkit-user-select: none;
    user-select: none;
}
</style>
