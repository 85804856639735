import { loadStripe, } from "@stripe/stripe-js/pure";

let stripeClient;

export async function getStripeClient () {
    if (stripeClient) {
        return stripeClient;
    }

    stripeClient =
        await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);

    return stripeClient;
}
