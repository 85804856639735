<template>
    <IonPage>
    <IonHeader>
        <IonToolbar>
            <IonButtons slot="start">
                <IonBackButton/>
            </IonButtons>
            <IonTitle>My Votes</IonTitle>
        </IonToolbar>
    </IonHeader>
    <IonContent>
        <!-- <today> -->
        <IonList v-show="todayPolls.length > 0">
            <IonListHeader>
                <IonLabel>Today</IonLabel>
            </IonListHeader>
            <IonItem
                v-for="{ id, } in todayPolls"
                :key="id"
                :button="true"
                @click="toPollView(id)"
            >
                <SimplePollPreview :id="id"/>
            </IonItem>
        </IonList>
        <!-- </today> -->
        <!-- <yesterday> -->
        <IonList v-show="yesterdayPolls.length > 0">
            <IonListHeader>
                <IonLabel>Yesterday</IonLabel>
            </IonListHeader>
            <IonItem
                v-for="{ id, } in yesterdayPolls"
                :key="id"
                :button="true"
                @click="toPollView(id)"
            >
                <SimplePollPreview :id="id"/>
            </IonItem>
        </IonList>
        <!-- </yesterday> -->
        <!-- <older> -->
        <IonList v-show="olderPolls.length > 0">
            <IonListHeader>
                <IonLabel>Older</IonLabel>
            </IonListHeader>
            <IonItem
                v-for="{ id, } in olderPolls"
                :key="id"
                :button="true"
                @click="toPollView(id)"
            >
                <SimplePollPreview :id="id"/>
            </IonItem>
        </IonList>
        <!-- </older> -->
        <!-- <no-polls> -->
        <div
            class="flex flex--100 flex--x-align-center flex--y-align-center zero-polls-section"
            v-if="polls.length === 0 && !isLoading"
        >
            <IonButton fill="outline" @click="toFeedView">
                Explore Feed
                <IonIcon :icon="chevronForward"/>
            </IonButton>
        </div>
        <!-- </no-polls> -->
        <hr class="safe-bottom-area"/>
    </IonContent>
    </IonPage>
</template>

<script>
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonListHeader,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonTitle,
    IonToolbar,
    IonList,
} from "@ionic/vue";
import { mapStores } from "pinia";
import Poll from "@/components/polls/SimplePoll.vue";
import SimplePoll from "@/components/polls/SimplePoll.vue";
import LiveIcon from "@/components/icons/LiveIcon.vue";
import ApplicationIcon from "@/components/ApplicationIcon.vue";
import { userManager } from "@/UserManager";
import { usePollsStore } from "@/stores/PollsStore";
import { chevronForward } from "ionicons/icons";
import SimplePollPreview from "@/components/polls/SimplePollPreview.vue";

export default {
    name: "MyVotesView",
    components: {
        SimplePollPreview,
        IonButton,
        SimplePoll,
        IonTitle,
        IonButtons, IonBackButton,
        ApplicationIcon,
        LiveIcon,
        Poll,
        IonPage,
        IonContent,
        IonRefresher,
        IonRefresherContent,
        IonIcon,
        IonToolbar,
        IonHeader,
        IonItem,
        IonListHeader,
        IonLabel,
        IonList,
    },
    data () {
        return {
            isLoading: false,
            polls: [],
        };
    },
    computed: {
        ...mapStores(usePollsStore),

        chevronForward () {
            return chevronForward;
        },

        todayPolls () {
            return this.polls.filter((poll) => {
                const votedAt =
                    new Date(poll.$user.vote.at).getTime();
                const todayMidnight = new Date();

                todayMidnight.setHours(0, 0, 0, 0);

                return votedAt >= todayMidnight.getTime();
            });
        },

        yesterdayPolls () {
            return this.polls.filter((poll) => {
                const votedAt =
                    new Date(poll.$user.vote.at).getTime();
                const todayMidnight = new Date();

                todayMidnight.setHours(0, 0, 0, 0);

                const yesterdayMidnight = new Date(todayMidnight);

                yesterdayMidnight.setDate(todayMidnight.getDate() - 1);

                return (
                    votedAt >= yesterdayMidnight
                    && votedAt < todayMidnight
                );
            });
        },

        olderPolls () {
            return this.polls.filter((poll) => {
                const votedAt =
                    new Date(poll.$user.vote.at).getTime();
                const todayMidnight = new Date();

                todayMidnight.setHours(0, 0, 0, 0);

                const yesterdayMidnight = new Date(todayMidnight);

                yesterdayMidnight.setDate(todayMidnight.getDate() - 1);

                return votedAt < yesterdayMidnight;
            });
        },
    },
    methods: {
        async fetch () {
            this.isLoading = true;

            const {
                votedPolls,
            } = await userManager.getVotedPolls();

            this.pollsStore.updateMany(votedPolls);

            this.polls = votedPolls;

            this.isLoading = false;
        },

        toFeedView () {
            this.$router.push("/navigation");
        },

        toPollView (pollId) {
            this.$router.push(`/polls/${pollId}`);
        },
    },
    created () {
        this.fetch();
    },
};
</script>

<style lang="scss" scoped>
.zero-polls-section {
    height: 100%;
}
</style>
