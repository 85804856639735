import { userManager } from "@/UserManager";
import { CreatorSubscriptionType } from "@/utilities/Utilities";
import { defineStore, } from "pinia";
import { computed, ref, watch, } from "vue";

export const USER_STORAGE_KEY = "User";

export const useUserStore = defineStore("user", () => {
    const savedUser = window.localStorage.getItem(USER_STORAGE_KEY);
    const user = ref(savedUser ? JSON.parse(savedUser) : undefined);

    watch(user, () => {
        const { value, } = user;

        if (value) {
            window.localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(value));
        }
        else {
            window.localStorage.removeItem(USER_STORAGE_KEY);
        }
    });

    const metrics = computed(() => user?.value.metrics);
    const isLoggedIn = computed(() => user.value !== undefined);
    const creatorSubscriptionType = computed(() => user?.value?.billing.creatorSubscriptionType);
    const entitlements = computed(() => {
        return {
            analyticsVisibility: {
                byGender: true,
                byAgeRange: creatorSubscriptionType.value >= CreatorSubscriptionType.LEVEL_1,
                byIncomeRange: creatorSubscriptionType.value >= CreatorSubscriptionType.LEVEL_2,
                byEducation: creatorSubscriptionType.value >= CreatorSubscriptionType.LEVEL_2,
                byCountry: creatorSubscriptionType.value >= CreatorSubscriptionType.LEVEL_2,
                byReligion: creatorSubscriptionType.value >= CreatorSubscriptionType.LEVEL_3,
                byIndustry: creatorSubscriptionType.value >= CreatorSubscriptionType.LEVEL_3,
            },
        };
    });

    return {
        user,
        entitlements,

        update (value: any) {
            return user.value = {
                ...user.value ?? {},
                ...value,
            };
        },

        // IMPORTANT: Make sure that no active views/components are using this store before clearing it
        clear () {
            user.value = undefined;
        },

        getFollowedCategory (categoryId: string) {
            if (!isLoggedIn.value) {
                return undefined;
            }

            for (const followedCategory of metrics.value.followedCategories) {
                if (followedCategory.category === categoryId) {
                    return followedCategory;
                }
            }

            return undefined;
        },

        // Used to sync the local user with the server
        async fetchUser () {
            if (!user.value) {
                return;
            }

            const {
                user: updatedUser,
            } = await userManager.getUser(user.value.id);

            if (!updatedUser) {
                return;
            }

            this.update(updatedUser);

            return updatedUser;
        },
    };
});
