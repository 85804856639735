<template>
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 32 32" class="flex share-icon" xml:space="preserve">
        <path d="M25,18v4c0,2.206-1.794,4-4,4H10c-2.206,0-4-1.794-4-4V11c0-2.206,1.794-4,4-4h4c0.553,0,1,0.447,1,1s-0.447,1-1,1h-4c-1.103,0-2,0.897-2,2v11c0,1.103,0.897,2,2,2h11c1.103,0,2-0.897,2-2v-4c0-0.553,0.447-1,1-1S25,17.447,25,18z M24,6h-5c-0.553,0-1,0.447-1,1s0.447,1,1,1h3.586l-5.293,5.293c-0.391,0.391-0.391,1.023,0,1.414C17.488,14.902,17.744,15,18,15s0.512-0.098,0.707-0.293L24,9.414V13c0,0.553,0.447,1,1,1s1-0.447,1-1V8C26,6.897,25.103,6,24,6z"/>
    </svg>
</template>

<script>
import { defineComponent, } from "vue";

export default defineComponent({
    name: "ShareIcon",
});
</script>
