<template>
    <div class="flex flex--100 search-feed" v-if="trendingQueries.length > 0">
        <div class="flex flex--100 main">
            <div class="flex flex--100 queries-section">
                <span
                    class="flex flex--y-align-center query"
                    v-for="{ query, id } in topTrendingQueries"
                    :key="id"
                    @click="onQueryPressed(query)"
                >
                    <TrendingIcon/>
                    {{ query }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, } from "vue";
import { userManager, } from "@/UserManager";
import SimplePollSquarePreview from "@/components/polls/SimplePollSquarePreview.vue";
import { mapStores, } from "pinia";
import { usePollsStore, } from "@/stores/PollsStore";
import TrendingIcon from "@/components/icons/TrendingIcon.vue";
import { cloneDeep, } from "lodash/lang";

const MAX_TRENDING_QUERIES = 5;

export default defineComponent({
    name: "TrendingTopics",
    components: {TrendingIcon, SimplePollSquarePreview},
    data () {
        return {
            isWaitingServerResponse: false,
            trendingQueries: [],
        };
    },
    computed: {
        ...mapStores(usePollsStore),

        topTrendingQueries () {
            const trendingQueries = cloneDeep(this.trendingQueries);

            trendingQueries.sort((a, b) => b.searchesCounter - a.searchesCounter);

            return trendingQueries.slice(0, MAX_TRENDING_QUERIES);
        },
    },
    methods: {
        toPollView (pollId) {
            this.$router.push(`/navigation/polls/${pollId}`);
        },

        async fetch () {
            if (this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;
            const response = await userManager.trendingQueries();
            const { trendingQueries, } = response.data.result;
            this.isWaitingServerResponse = false;

            this.trendingQueries = trendingQueries;
        },

        onQueryPressed (query) {
            this.$emit("query-pressed", query);
        },
    },
    created () {
        this.fetch();
    },
});
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

::v-deep(.simple-poll-square-preview) {
    height: auto;

    .creator-section,
    .metrics-section {
        display: none;
    }

    .question-section {
        margin: 0;
    }
}

.trending-icon {
    width: 27px;

    color: $base-text-color;
}

.title {
    margin: 0 0 0 5px;
    padding: 0;

    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.01rem;
    color: $base-text-color;
}

.queries-section {
    margin: 0;
    padding: 11px 9px 0 9px;
}

.query {
    cursor: pointer;

    flex-wrap: nowrap;

    font-size: 13px;
    font-weight: 500;

    margin: 3px;
    padding: 5px 10px;
    background-image: linear-gradient(45deg, rgb(233, 233, 233), rgb(247, 247, 247));

    border-radius: 999px;

    color: rgba(11, 11, 11, 0.9);

    .trending-icon {
        width: 19px;
        margin-right: 3px;

        color: rgba(11, 11, 11, 0.9);
    }
}
</style>
