<template>
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" class="flex life-on-land-icon" viewBox="0 0 100 100">
        <path d="M25.528 42.606a7.783 7.783 0 0 0-1.063 3.929c0 4.312 3.508 7.82 7.82 7.82h4.277v7.7a1.5 1.5 0 0 0 3 0v-7.7h4.58c5.687 0 10.312-4.626 10.312-10.312 0-4.23-2.532-7.934-6.331-9.51a9.365 9.365 0 0 0 .352-2.547c0-5.226-4.252-9.478-9.478-9.478-4.446 0-8.26 3.137-9.231 7.359-3.731.331-6.668 3.475-6.668 7.291 0 2.105.903 4.076 2.43 5.448zm4.89-9.767c.159 0 .316.011.472.027a1.503 1.503 0 0 0 1.657-1.378c.254-3.354 3.088-5.98 6.451-5.98a6.486 6.486 0 0 1 6.478 6.478c0 .979-.222 1.932-.659 2.829a1.499 1.499 0 0 0 1.005 2.117 7.277 7.277 0 0 1 5.634 7.11c0 4.032-3.28 7.312-7.312 7.312H32.285a4.825 4.825 0 0 1-4.82-4.82c0-1.203.451-2.358 1.271-3.252a1.499 1.499 0 0 0-.386-2.33 4.325 4.325 0 0 1-2.252-3.794 4.325 4.325 0 0 1 4.32-4.319zm22.465 1.421a1.495 1.495 0 0 1 .351-2.08c1.405-1.005 4.578-2.162 7.467-.25 1.775-1.279 4.745-2.402 7.808-.676.722.406.978 1.321.57 2.043a1.498 1.498 0 0 1-2.043.57c-2.697-1.52-5.121.942-5.223 1.047a1.51 1.51 0 0 1-1.062.462h-.02a1.5 1.5 0 0 1-1.055-.428c-2.074-2.042-4.444-.504-4.707-.321a1.504 1.504 0 0 1-2.086-.367zm9.619-10.018a1.5 1.5 0 0 1 1.279-1.683c1.474-.201 4.216.049 5.627 2.045 1.676-.356 4.035-.205 5.963 1.806a1.5 1.5 0 0 1-2.166 2.076c-1.722-1.794-3.864-.724-3.884-.712a1.499 1.499 0 0 1-2.133-.931c-.449-1.616-2.901-1.325-3.007-1.311-.804.095-1.572-.472-1.679-1.29zm13.705 21.109a1.5 1.5 0 0 1-1.611 2.531c-3.409-2.172-6.567.815-6.7.943-.28.271-.657.423-1.045.423l-.059-.001a1.503 1.503 0 0 1-1.067-.502c-2.851-3.226-6.249-1.114-6.627-.864a1.503 1.503 0 0 1-2.078-.409 1.495 1.495 0 0 1 .396-2.074c1.836-1.253 5.98-2.598 9.525.354 2.106-1.44 5.691-2.677 9.266-.401zM23.098 69.098a1.5 1.5 0 0 1 1.5-1.5h50.803a1.5 1.5 0 0 1 0 3H24.598a1.5 1.5 0 0 1-1.5-1.5zm53.803 6.9a1.5 1.5 0 0 1-1.5 1.5H24.598a1.5 1.5 0 0 1 0-3h50.803a1.5 1.5 0 0 1 1.5 1.5z"/>
    </svg>
</template>

<script>
import { defineComponent, } from "vue";

export default defineComponent({
    name: "LifeOnLandIcon",
});
</script>
