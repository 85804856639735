<template>
    <IonPage>
        <IonContent>
            <MainNavigationBar/>
            <div class="flex flex--100 main-section">
                <div class="flex flex--100 flex--x-align-center request-demo-section">
                    <div class="flex flex--x-align-center flex--y-align-center logo-section">
                        <ApplicationIcon/>
                    </div>
                    <div class="flex form-section">
                        <template v-if="!demoRequestIsConfirmed">
                        <div class="flex flex--100">
                            <h2 class="title">Talk to our sales team</h2>
                        </div>
                        <div class="flex flex--100">
                            <span class="subtitle">
                                Learn more about our solutions, schedule a personalised demo and
                                discover the right plan for your needs.
                            </span>
                        </div>
                        <RequestDemoForm @confirmed="onDemoRequestConfirmed"/>
                        <div class="flex flex--100">
                            <p class="privacy-text">
                                By pressing «Request a Demo», I acknowledge that I am requesting to be contacted
                                regarding my inquiry and confirm that I have read Vottify's
                                <a href="https://www.iubenda.com/privacy-policy/32870526" target="_blank" class="link">Privacy Policy</a>.
                            </p>
                        </div>
                        </template>
                        <template v-else>
                        <div class="flex flex--100">
                            <h2 class="title">Thank you!</h2>
                        </div>
                        <div class="flex flex--100">
                            <span class="subtitle">
                                Thank you for your inquiry, our team will respond to you within 24 hours.
                            </span>
                        </div>
                        </template>
                    </div>
                </div>
            </div>
            <MainFooter/>
        </IonContent>
    </IonPage>
</template>

<script>
import { IonContent, IonPage, } from "@ionic/vue";
import MainFooter from "@/components/website/WebsiteMainFooter.vue";
import MainNavigationBar from "@/components/website/WebsiteMainNavigationBar.vue";
import RequestDemoForm from "@/components/mixed/RequestDemoForm.vue";
import ApplicationIcon from "@/components/ApplicationIcon.vue";

export default {
    name: "RequestDemoView",
    components: {
        ApplicationIcon,
        RequestDemoForm,
        MainNavigationBar,
        MainFooter,

        IonPage,
        IonContent,
    },
    data () {
        return {
            demoRequestIsConfirmed: false,
        };
    },
    methods: {
        onDemoRequestConfirmed () {
            this.demoRequestIsConfirmed = true;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.main-section {
    margin: 111px 0;
}

.request-demo-section {
    align-items: stretch;
}

.request-demo-form {
    margin: 21px 0;
}

.title {
    max-width: 671px;
    margin: 0;

    font-size: 45px;
    font-weight: 800;
    color: rgb(23, 23, 23);
}

.subtitle {
    max-width: 671px;
    margin: 3px 0 0 0;

    font-size: 26px;
    font-weight: 300;
    letter-spacing: -0.01rem;

    color: rgb(23, 23, 23);
}

.request-demo-form {
    max-width: 411px;
}

.privacy-text {
    max-width: 671px;

    margin: 0;
    padding: 0;

    font-size: 15px;
    font-weight: 400;

    color: rgb(88, 88, 88);
}

.link {
    cursor: pointer;
    transition: opacity 180ms;

    margin: 11px 0 0 0;

    font-size: inherit;
    font-weight: 500;

    color: rgb(23, 23, 23);

    text-decoration: underline;
    text-decoration-color: rgba(201, 201, 201, 0.9);
    text-decoration-thickness: 1px;
    text-underline-offset: 2px;

    &:hover {
        opacity: 0.5;
    }
}

.logo-section {
    width: 40%;
}

.form-section {
    width: 60%;
    padding: 21px;
}

.application-icon {
    width: 171px;

    transform: scaleX(-1);
}

@media (max-width: $mobile-breaking-point) {
    .logo-section {
        display: none;
    }

    .form-section {
        width: 100%;
    }
}
</style>
