import { CreatorSubscriptionType, } from "@/utilities/Utilities";

export const creatorPlans = [
    {
        name: "Light",
        levelType: CreatorSubscriptionType.LEVEL_1,
        monthlyPrice: 29.99,
        monthlyTestStripePriceId: "price_1PUUS72KtqCa1RC2NmFf4fKd",
        monthlyLiveStripePriceId: "price_1PPrSw2KtqCa1RC23SdeeW74",
        yearlyTestStripePriceId: "price_1PUUSu2KtqCa1RC2k58qsYjI",
        yearlyLiveStripePriceId: "price_1PPv7p2KtqCa1RC2OZTQs8Rl",
        appleProductId: "CreatorLightSubscription",
        googleProductId: "creator_light_subscription",
        monthlyGoogleProductId: "creator_light_subscription:creator-light-subscription-monthly",
    },
    {
        name: "Standard",
        levelType: CreatorSubscriptionType.LEVEL_2,
        monthlyPrice: 59.99,
        monthlyTestStripePriceId: "price_1PUUU12KtqCa1RC2GH1ABWXg",
        monthlyLiveStripePriceId: "price_1PUUGb2KtqCa1RC2nlKylzat",
        yearlyTestStripePriceId: "price_1PUVnZ2KtqCa1RC28BzK7ZSC",
        yearlyLiveStripePriceId: "price_1PUUHO2KtqCa1RC2eMJzCR7y",
        appleProductId: "CreatorStandardSubscription",
        googleProductId: "creator_standard_subscription",
        monthlyGoogleProductId: "creator_standard_subscription:creator-standard-subscription-monthly",
    },
    {
        name: "Premium",
        levelType: CreatorSubscriptionType.LEVEL_3,
        monthlyPrice: 99.99,
        monthlyTestStripePriceId: "price_1PUVo92KtqCa1RC2PRMXExEA",
        monthlyLiveStripePriceId: "price_1PUUIp2KtqCa1RC2hqhEG24i",
        yearlyTestStripePriceId: "price_1PUVot2KtqCa1RC2sJMUH0Cj",
        yearlyLiveStripePriceId: "price_1PUUJk2KtqCa1RC2NWW0FMGU",
        appleProductId: "CreatorPremiumSubscription",
        googleProductId: "creator_premium_subscription",
        monthlyGoogleProductId: "creator_premium_subscription:creator-premium-subscription-monthly",
    },
];

export function appleProducts () {
    return creatorPlans.map((pricingPlan) => pricingPlan.appleProductId);
}

export function googleProducts () {
    return creatorPlans.map((pricingPlan) => pricingPlan.googleProductId);
}
