<template>
    <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonTitle>Elon Musk</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent>

        </IonContent>
        <IonFooter>
            <IonToolbar>
                <IonInput placeholder="Message..."/>
            </IonToolbar>
        </IonFooter>
    </IonPage>
</template>

<script>
import { defineComponent, } from "vue";
import { IonPage, IonHeader, IonToolbar, IonTitle, IonFooter, IonInput, IonContent, } from "@ionic/vue";

export default defineComponent({
    name: "ChatView",
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonFooter,
        IonInput,
        IonContent
    },
    props: {
        recipientId: {
            type: String,
        },
    },
});
</script>

<style lang="scss" scoped>

</style>
