<template>
    <IonPage v-if="!isLoading">
        <IonContent class="ion-content">
            <div class="flex flex--100 flex--x-align-center flex--y-align-center main">
                <SimplePoll :id="poll.id"/>
            </div>
        </IonContent>
    </IonPage>
</template>

<script>
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonTextarea,
    IonTitle,
    IonToolbar,
} from "@ionic/vue";
import { userManager, } from "@/UserManager";
import SimplePoll from "@/components/polls/SimplePoll.vue";
import { pollsStore, } from "@/main";
import SimpleButton from "@/components/buttons/SimpleButton.vue";

export default {
    name: "PollScreenshotView",
    components: {
        SimpleButton,
        IonIcon,
        SimplePoll,
        IonPage,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonList,
        IonListHeader,
        IonItem,
        IonLabel,
        IonButtons,
        IonBackButton,
        IonButton,
        IonTextarea,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            isLoading: false,
        };
    },
    computed: {
        poll () {
            return pollsStore.get(this.id);
        },
    },
    methods: {
        async fetch () {
            this.isLoading = true;

            pollsStore.update(await userManager.getPoll(this.id));

            this.isLoading = false;
        },
    },
    created () {
        if (!this.poll) {
            this.fetch();
        }
    },
};
</script>

<style lang="scss" scoped>
.ion-content {
    &::part(background) {
        background-color: rgb(0, 0, 0);
    }
}

.main {
    height: 100%;
}

::v-deep(.simple-poll) {
    margin: 75px;
}
</style>
