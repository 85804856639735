<template>
    <div class="flex request-demo-form">
        <IonList class="flex flex--100">
            <IonItem class="flex flex--100">
                <IonInput
                    type="text"
                    label="First name"
                    label-placement="floating"
                    v-model="firstName"
                />
            </IonItem>
            <IonItem class="flex flex--100">
                <IonInput
                    type="text"
                    label="Last name"
                    label-placement="floating"
                    v-model="lastName"
                />
            </IonItem>
            <IonItem class="flex flex--100">
                <IonInput
                    type="text"
                    label="Business email"
                    label-placement="floating"
                    v-model="businessEmail"
                />
            </IonItem>
            <IonItem class="flex flex--100">
                <IonInput
                    type="text"
                    label="Company name"
                    label-placement="floating"
                    v-model="companyName"
                />
            </IonItem>
        </IonList>
        <div class="flex flex--100">
            <div class="flex request-button" @click="request">
                Request a Demo
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, } from "vue";
import { IonInput, IonItem, IonList, } from "@ionic/vue";
import { userManager, } from "@/UserManager";

export default defineComponent({
    name: "RequestDemoForm",
    components: {
        IonList,
        IonItem,
        IonInput,
    },
    data () {
        return {
            firstName: "",
            lastName: "",
            businessEmail: "",
            companyName: "",
        };
    },
    methods: {
        async request () {
            if (
                !this.firstName
                || !this.lastName
                || !this.businessEmail
                || !this.companyName
            ) {
                return;
            }

            try {
                const response = await userManager.requestDemo({
                    firstName: this.firstName,
                    lastName: this.lastName,
                    businessEmail: this.businessEmail,
                    companyName: this.companyName,
                });

                if (response.status === "ok") {
                    this.$emit("confirmed");
                }
            }
            catch {
                // Silence is golden
            }
        },
    }
});
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.request-button {
    cursor: pointer;

    transition: opacity 180ms;
    will-change: opacity;

    padding: 15px 30px;
    margin: 31px 0 0 0;

    background-image: linear-gradient(45deg, rgb(0, 0, 0) 60%, $brand-color);
    border-radius: 999px;

    font-size: 19px;
    font-weight: 500;
    color: rgb(255, 255, 255);

    &:hover {
        opacity: 0.5;
    }
}
</style>
