<template>
    <div class="flex flex--100 user-subscription">
        <div class="flex flex--100">
            <h2 class="title">{{ subscriptionName }}</h2>
        </div>
        <div class="flex flex--100">
            <p class="description">
                €{{ subscriptionPrice }} per {{ subscriptionInterval }}<br>

                <template v-if="willEnd">
                    Active until {{ visibleUpcomingRenewAt }}
                </template>
                <template v-else>
                    Next renewal on {{ visibleUpcomingRenewAt }}
                </template>
            </p>
        </div>
        <div class="flex flex--100">
            <IonButton
                color="warning"
                class="cancel-subscription-button"
                @click="cancelSubscription"
                v-if="!willEnd"
            >
                Cancel Subscription
            </IonButton>
        </div>
    </div>
</template>

<script>
import { defineComponent, } from "vue";
import { IonButton } from "@ionic/vue";
import { userManager } from "@/UserManager";

export default defineComponent({
    name: "UserSubscription",
    components: {
        IonButton,
    },
    props: {
        // The user Stripe subscription
        subscription: {
            type: Object,
            required: true,
        },
    },
    computed: {
        subscriptionId () {
            return this.subscription.id;
        },

        subscriptionName () {
            return this.subscription.$product.name;
        },

        subscriptionPrice () {
            return this.subscription.plan.amount / 100;
        },

        subscriptionInterval () {
            return this.subscription.plan.interval;
        },

        willEnd () {
            return this.subscription["cancel_at_period_end"] === true;
        },

        upcomingRenewAt () {
            return new Date(this.subscription["current_period_end"] * 1000);
        },

        visibleUpcomingRenewAt () {
            return this.upcomingRenewAt.toLocaleDateString();
        },
    },
    methods: {
        async cancelSubscription () {
            // TODO: Open modal and ask also reason for cancellation (send to the server)
            const {
                subscription,
            } = await userManager.cancelSubscription(this.subscriptionId);

            subscription.$product = this.subscription.$product;
            this.subscription = subscription;

            this.$emit("update:subscription", subscription);
        },
    },
});
</script>

<style lang="scss" scoped>
.user-subscription {
    margin: 0 0 7px 0;
    padding: 13px 17px;

    background-color: rgb(241, 241, 241);
    border-radius: 11px;
}

.title {
    margin: 0;
    padding: 0;

    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.008rem;
    color: rgb(33, 33, 33);
}

.description {
    margin: 9px 0 0 0;

    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.008rem;
    color: rgb(113, 113, 113);
}

.cancel-subscription-button {
    margin-top: 9px;
}
</style>
