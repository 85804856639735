<template>
    <div class="flex flex--100 flex--x-align-center country-input">

        <IonItemGroup class="flex flex--100">
            <IonItem class="flex flex--100 dial-code-input" @click="openCountryPickerModal">
                <IonInput
                    :label="label"
                    label-placement="floating"
                    :disabled="true"
                    :value="selectedCountryName"
                ></IonInput>
            </IonItem>
        </IonItemGroup>

        <IonModal
            class="country-picker-modal"
            :initial-breakpoint="0.6"
            :breakpoints="[0, 0.6, 0.8]"
            :is-open="countryPickerModalIsOpen"
            :keep-contents-mounted="true"
            @ionModalDidDismiss="dismissCountryPickerModal"
        >
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton @click="dismissCountryPickerModal">
                            Dismiss
                        </IonButton>
                    </IonButtons>
                    <IonButtons slot="end">
                        <IonButton
                            :disabled="visibleCountries.length === 0"
                            @click="confirmCountryPickerModal"
                        >
                            Confirm
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonSearchbar ref="dialCodeSearchBar" v-model="searchQuery" placeholder="Search"></IonSearchbar>
                <IonPicker :key="searchQuery" v-show="visibleCountries.length > 0">
                    <IonPickerColumn :value="localSelectedCountryIso" @ionChange="onCountryIsoChanged">
                        <IonPickerColumnOption
                            v-for="country in visibleCountries"
                            :key="country.iso2"
                            :value="country.iso2"
                        >
                            {{ country.emojiIcon }} {{ country.name }}
                        </IonPickerColumnOption>
                    </IonPickerColumn>
                </IonPicker>
            </IonContent>
        </IonModal>

    </div>
</template>

<script>
import Fuse from "fuse.js";
import {
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonPicker,
    IonPickerColumn,
    IonPickerColumnOption,
    IonSearchbar,
    IonModal,
    IonItemGroup,
    IonContent, IonIcon, IonToolbar, IonBackButton, IonHeader, IonButton, IonButtons, IonTitle,
} from "@ionic/vue";
import { countries } from "@/utilities/Countries";

export default {
    name: "CountryInput",
    emits: [
        "update:countryIso",
    ],
    components: {
        IonTitle, IonButtons, IonButton, IonHeader, IonBackButton, IonToolbar, IonIcon,
        IonContent,
        IonList,
        IonItem,
        IonLabel,
        IonPicker,
        IonPickerColumn,
        IonPickerColumnOption,
        IonInput,
        IonSearchbar,
        IonModal,
        IonItemGroup,
    },
    props: {
        countryIso: {
            type: String,
        },
        label: {
            type: String,
            default: "Country",
        },
    },
    data () {
        return {
            countryPickerModalIsOpen: false,
            localSelectedCountryIso: this.countryIso,
            searchQuery: "",
            visibleCountries: countries,
        };
    },
    computed: {
        selectedCountry () {
            if (!this.countryIso) {
                return undefined;
            }

            return countries.find((country) => country.iso2.toUpperCase() === this.countryIso.toUpperCase());
        },

        selectedCountryName () {
            if (!this.selectedCountry) {
                return "";
            }

            return `${this.selectedCountry.emojiIcon} ${this.selectedCountry.name}`;
        },

        fuse () {
            return new Fuse(countries, {
                keys: [
                    {
                        name: "name",
                        getFn (country) {
                            return country.name;
                        },
                    },
                    {
                        name: "dialCode",
                        getFn (country) {
                            return `+${country.dialCode}`;
                        },
                    },
                ],
            });
        },
    },
    methods: {
        onCountryIsoChanged (e) {
            this.localSelectedCountryIso = e.detail.value;
        },

        openCountryPickerModal () {
            this.countryPickerModalIsOpen = true;
        },

        closeCountryPickerModal () {
            this.countryPickerModalIsOpen = false;
            this.searchQuery = "";
        },

        confirmCountryPickerModal () {
            this.$emit("update:countryIso", this.localSelectedCountryIso);

            this.closeCountryPickerModal();
        },

        dismissCountryPickerModal () {
            this.localSelectedCountryIso = this.countryIso;

            this.closeCountryPickerModal();
        },

        search () {
            if (!this.searchQuery) {
                this.visibleCountries = countries;

                return;
            }

            this.visibleCountries =
                this.fuse.search(this.searchQuery).map((item) => item.item);
        },
    },
    watch: {
        searchQuery () {
            this.search(); // TODO: Debounce
            this.localSelectedCountryIso = this.visibleCountries[0]?.iso2 ?? "";
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.country-input {

}

.dial-code-input {
    cursor: pointer;

    //--padding-start: 0;
    //--ion-background-color: rgb(242, 242, 242);
    //border-radius: 12px 0 0 12px;

    // font-size: 19px;

    &::v-deep(*) {
        //--padding-start: 16px;
        cursor: pointer;
        pointer-events: none;
        opacity: 1;
    }
}

.number-input {
    --padding-start: 0;

    //--ion-background-color: rgb(242, 242, 242);
    //border-radius: 0 12px 12px 0;

    // font-size: 19px;
}

.country-picker-modal {
    &::part(handle) {
        outline: none;
    }
}
</style>
