import { configureRevenueCat, } from "@/payments/RevenueCat";
import { useCategoriesStore } from "@/stores/CategoriesStore";
import { usePollsStore, } from "@/stores/PollsStore";
import { useUserStore, } from "@/stores/UserStore";
import { isDesktop, isInApp, splashScreenHasEnded, } from "@/UpperState";
import { userManager, } from "@/UserManager";
import { App, } from "@capacitor/app";
import { Keyboard, KeyboardResize, } from "@capacitor/keyboard";
import { StatusBar, Style } from "@capacitor/status-bar";
import { IonicVue, isPlatform, } from "@ionic/vue";
import { createPinia, } from "pinia";
import { createApp, } from "vue"
import { createI18n, } from "vue-i18n";
import Application from "./Application.vue"
import { mainRouter, } from "./routers/MainRouter";

import "@ionic/vue/css/core.css";
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";
//import "@/theme/ionic-colors.scss";
import "@/theme/base.scss";

const pinia = createPinia();
const application = createApp(Application);
const i18n = createI18n({
    locale: "en",
    messages: {
        en: require("./locales/en.json"),
    },
});

// Used to reset the state of any Vue component
application.config.globalProperties.$resetState = async function () {
    Object.assign(this.$data, this.$options.data.apply(this));
    // Used to wait for watchers and other effects to be triggered
    // they might change the state
    await this.$nextTick();
    // Used to reset the state for the final time
    // it shouldn't trigger watchers and other effects otherwise
    // they would have already caused an endless loop
    Object.assign(this.$data, this.$options.data.apply(this));
};

application.config.globalProperties.$isDesktop = isDesktop;
application.config.globalProperties.$isInApp = isInApp;

window.onerror = function (message, url, line, column, error) {
    //alert(message);
    //console.log(error);
}

window.addEventListener("unhandledrejection", function (event) {
    //alert(event.reason);
    //console.log(event);
});

application.use(mainRouter);
application.use(pinia);
application.use(i18n);
application.use(IonicVue, {
    mode: "ios",
    animated: isInApp,
});

export const pollsStore = usePollsStore();
export const userStore = useUserStore();
export const categoriesStore = useCategoriesStore();

App.addListener("resume", async () => {
    await onApplicationResumed();
}).then();

App.addListener("appUrlOpen", async function (e) {
    if (!splashScreenHasEnded) {
        return;
    }

    const openingUri = e.url;

    await mainRouter.push(openingUri);
}).then();

mainRouter.isReady().then(async () => {
    application.mount("#application");

    if (isPlatform("android")) {
        await StatusBar.setOverlaysWebView({ overlay: false, });
        await StatusBar.setBackgroundColor({
            color: "#000000",
        });
    }

    const userId = userStore.user?.id;

    if ((isPlatform("android") || isPlatform("ios")) && userId) {
        await configureRevenueCat(userId);
    }

    if (isPlatform("capacitor")) {
        await Keyboard.setResizeMode({
            mode: KeyboardResize.Body,
        });
    }
});

async function onApplicationResumed () {
    if (userManager.isLogged) {
        await Promise.all([
            userStore.fetchUser(),
        ]);
    }
}
