<template>
    <IonModal
        class="poll-comments-modal"
        :initial-breakpoint="1"
        :breakpoints="[0, 1]"
        :is-open="isOpen"
        :keep-contents-mounted="true"
        @ionModalDidDismiss="dismissModal"
    >
        <IonHeader>
            <IonToolbar>
                <IonTitle>Comments</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent ref="content">
            <!-- <comments> -->
            <div class="flex flex--100" v-if="pollId">
                <SimplePollComments
                    :key="pollId"
                    :poll-id="pollId"
                    :page="commentsPage"
                    v-model:selected-comment="selectedComment"
                    ref="pollComments"
                />
                <hr class="flex flex--100 safe-bottom-area"/>
            </div>
            <!-- </comments> -->
        </IonContent>
        <IonFooter>
            <IonToolbar>
                <div class="flex flex--100 emoji-section">
                    <span
                        class="emoji-button"
                        v-for="emoji in topEmojis"
                        :key="emoji"
                        @click="appendToCommentBody(emoji)"
                    >
                        {{ emoji }}
                    </span>
                </div>
            </IonToolbar>
            <IonToolbar>
                <!-- <comment-input> -->
                <div
                    class="flex flex--100 flex--y-align-center post-comment-section"
                >
                    <div class="flex flex--100 reply-section" v-if="selectedComment">
                        <span class="reply-text">
                            Replying to {{selectedComment.createdBy.visibleName}}
                        </span>
                    </div>
                    <IonTextarea
                        class="comment-input"
                        autocapitalize="on"
                        placeholder="Leave your thoughts here..."
                        :auto-grow="true"
                        :rows="1"
                        v-model="commentBody"
                    />
                    <div
                        class="flex flex--x-align-center flex--y-align-center post-comment-button"
                        @click="postComment"
                    >
                        <CommentIcon/>
                    </div>
                </div>
                <!-- <comment-input> -->
            </IonToolbar>
        </IonFooter>
    </IonModal>
</template>

<script>
import { IonContent, IonFooter, IonHeader, IonModal, IonTextarea, IonTitle, IonToolbar } from "@ionic/vue";
import { defineComponent, } from "vue";
import SimplePollComments from "@/components/polls/comments/SimplePollComments.vue";
import CommentIcon from "@/components/icons/CommentIcon.vue";
import { topEmojis, } from "@/utilities/Utilities";

export default defineComponent({
    name: "PollCommentsModal",
    components: {
        CommentIcon,
        IonTextarea,
        SimplePollComments, IonModal, IonContent, IonHeader, IonToolbar, IonTitle, IonFooter,},
    data () {
        return {
            pollId: undefined,
            isOpen: false,
            commentsPage: 0,
            commentBody: "",
            selectedComment: undefined,
        };
    },
    computed: {
        topEmojis () {
            return topEmojis;
        },
    },
    methods: {
        openModal (pollId) {
            this.pollId = pollId;
            this.isOpen = true;
        },

        dismissModal () {
            this.$resetState();
        },

        appendToCommentBody (text) {
            this.commentBody += text;
        },

        async postComment () {
            if (this.commentBody) {
                await this.$refs.pollComments.postComment(this.commentBody, this.selectedComment?.id);

                this.commentBody = "";
                this.selectedComment = undefined;

                await this.$refs.content.$el.scrollToTop(120);
            }
        },
    },
    watch: {
        $route () {
            // TODO: When changing route and coming bacl, reopen modal to the same scroll position
            this.dismissModal();
        },
    },
});
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.poll-comments-modal {
    --height: 90%;

    &::part(handle) {
        outline: none;
    }
}

.emoji-section {
    user-select: none;
    overflow-x: auto;
    flex-wrap: nowrap;

    &::-webkit-scrollbar {
        display: none;
    }
}

.emoji-button {
    cursor: pointer;

    margin: 7px;

    font-size: 21px;

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
}

::v-deep(.simple-poll-comments) {
    .simple-poll-comment {
        padding: 17px;

        border-bottom: 1px solid rgb(243, 243, 243);
    }

    .replies-section .simple-poll-comment {
        padding-right: 0;
    }

    .replies-section .simple-poll-comment:last-child {
        padding-bottom: 0;

        border-bottom: none;
    }
}

.comment-input {
    overflow-y: auto;

    flex: 1;

    max-height: 20vh;
    margin: 0;
    padding: 0;

    border: 1px solid rgb(220, 220, 220);
    border-radius: 21px;

    &::v-deep(.native-textarea) {
        padding: 13px 17px !important;

        font-size: 15px;
        color: $base-text-color;
    }

    &::-webkit-scrollbar {
        display: none;
    }
}

.post-comment-section {
    margin-bottom: 9px;
}

.post-comment-button {
    cursor: pointer;

    width: 42px;
    height: 42px;
    margin: 0 0 0 11px;

    background-color: $brand-color;
    border-radius: 50%;

    font-size: 15px;
    font-weight: 600;
    color: $base-text-color;

    .comment-icon {
        width: 34px;

        color: rgb(254, 254, 254);
    }
}

.reply-section {
    margin-bottom: 7px;
}

.reply-text {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.02rem;
    color: darken($brand-color, 17%);
}

.safe-bottom-area {
    margin: 0;
    padding: 0;

    height: 11px;
}
</style>
