<template>
    <IonPage class="flex flex--100 ion-page" :class="ionPageModifiers">
        <IonTabs class="ion-tabs" :key="primaryKey">
            <IonRouterOutlet class="ion-router-outlet"/>
            <IonTabBar slot="bottom" class="flex navigation">
                <div class="flex flex--100 flex--direction-column">
                    <IonTabButton class="tab-button" tab="feed" href="/d/n/feed">
                        <div class="flex flex--100 flex--y-align-center">
                            <HomeIcon class="tab-icon"/>
                            <span class="tab-button-text">Feed</span>
                        </div>
                    </IonTabButton>
                    <IonTabButton class="tab-button" tab="search" href="/d/n/search">
                        <div class="flex flex--100 flex--y-align-center">
                            <SearchIcon class="tab-icon"/>
                            <span class="tab-button-text">Explore</span>
                        </div>
                    </IonTabButton>
                    <IonTabButton class="tab-button" @click="toNewPollView">
                        <div class="flex flex--100 flex--y-align-center">
                            <AddItemIcon class="tab-icon"/>
                            <span class="tab-button-text">New</span>
                        </div>
                    </IonTabButton>
                    <IonTabButton class="tab-button notifications-button" tab="notifications" href="/navigation/notifications">
                        <div class="flex flex--100 flex--y-align-center">
                            <NotificationsIcon class="tab-icon"/>
                            <span
                                class="notifications-button__light"
                                v-show="unseenNotificationsCounter > 0"
                            ></span>
                            <span class="tab-button-text">Notifications</span>
                        </div>
                    </IonTabButton>
                    <IonTabButton class="tab-button" tab="user" :href="loggedUserViewLocation">
                        <div class="flex flex--100 flex--y-align-center">
                            <UserIcon class="tab-icon"/>
                            <span class="tab-button-text">Profile</span>
                        </div>
                    </IonTabButton>
                </div>
            </IonTabBar>
        </IonTabs>
    </IonPage>
</template>

<script>
import {IonPage, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs,} from "@ionic/vue";
import HomeIcon from "@/components/icons/HomeIcon.vue";
import SearchIcon from "@/components/icons/SearchIcon.vue";
import NotificationsIcon from "@/components/icons/NotificationsIcon.vue";
import UserIcon from "@/components/icons/UserIcon.vue";
import AddItemIcon from "@/components/icons/PlusIcon.vue";
import {mapStores} from "pinia";
import {useUserStore} from "@/stores/UserStore";

export default {
    name: "WebsiteMainTabView",
    components: {
        IonPage,
        IonRouterOutlet,
        IonTabBar,
        IonTabButton,
        IonTabs,
        HomeIcon,
        SearchIcon,
        NotificationsIcon,
        UserIcon,
        AddItemIcon,
    },
    data () {
        return {
            // Used to reset the tab bar when navigating outside of it
            primaryKey: Math.random().toString(),
        };
    },
    computed: {
        ...mapStores(useUserStore),

        loggedUser () {
            return this.userStore.user;
        },

        isCreator () {
            return this.loggedUser.isCreator;
        },

        unseenNotificationsCounter () {
            return this.loggedUser.$unseenNotifications.length ?? 0;
        },

        inFeedView () {
            return this.$route.path === "/d/n/feed";
        },

        ionPageModifiers () {
            return {
                "ion-page--in-feed": this.inFeedView,
            };
        },

        loggedUserViewLocation () {
            return `/d/n/users/${this.loggedUser.id}`;
        },
    },
    methods: {
        toNewPollView () {
            this.$router.push("/poll/new");
        },
    },
    ionViewDidLeave () {
        this.primaryKey = Math.random().toString();
    },
}
</script>

<style lang="scss" scoped>
@import "@/theme/globals.scss";
@import "@/theme/palette.scss";

$tab-bar-width: 231px;

.tab-button {
    width: 100%;

    margin-top: 51px;

    background-color: transparent;
}

.ion-router-outlet {
    margin-left: $tab-bar-width;
}

.ion-tabs {
    position: absolute;
    left: 50% !important;

    width: 100%;
    max-width: 1303px;

    transform: translateX(-50%);
}

.navigation {
    position: absolute;
    left: 0;
    top: 0;

    width: $tab-bar-width;
    height: 100%;
    max-height: initial;

    background-color: inherit;
    background-image: linear-gradient(to left, rgba(9, 9, 9, 0.9), rgb(0, 0, 0));
    border-right: 0.5px solid rgb(220, 220, 220);

    z-index: 10;
}

.ion-page {
    background-color: rgb(255, 255, 255);
}

.ion-page--in-feed {
    background-color: rgb(0, 0, 0);

    .navigation {
        border-right-color: rgb(33, 33, 33);
    }

    .tab-icon {
        color: rgb(223, 223, 223);
    }

    .tab-selected {
        .tab-icon {
            color: rgb(202, 202, 202);
        }
    }
}

.tab-icon {
    transition: color 190ms;

    width: 34px;

    color: rgb(122, 122, 122);
}

.tab-selected {
    .tab-icon {
        color: rgb(22, 22, 22);
    }
}

.notifications-button {
    position: relative;

    &__light {
        position: absolute;
        left: calc(50% + 6px);
        top: calc(50% - 8px);

        width: 13px;
        height: 13px;

        background-color: rgb(255, 255, 255);
        border: 5px solid rgb(230, 34, 34);
        box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.002);
        border-radius: 50%;

        transform: translate(-50%, -50%);
    }
}


.tab-button-text {
    margin: 0 0 0 13px;
    padding: 0;

    font-size: 19px;
    font-weight: 500;

    color: rgb(223, 223, 223);
}
</style>
