<template>
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 32 32" class="flex comment-icon" xml:space="preserve">
        <path d="M17,26c-0.552,0-1-0.447-1-1v-2h-2.5C8.813,23,5,19.187,5,14.5S8.813,6,13.5,6h5c4.687,0,8.5,3.813,8.5,8.5c0,3.429-2.064,6.504-5.193,7.824C21.222,24.486,19.287,26,17,26z M13.5,8C9.916,8,7,10.916,7,14.5S9.916,21,13.5,21H17c0.552,0,1,0.447,1,1v1.83c1.018-0.359,1.784-1.256,1.952-2.391c0.055-0.369,0.311-0.678,0.664-0.799C23.238,19.736,25,17.27,25,14.5c0-3.584-2.916-6.5-6.5-6.5H13.5z M20,13c0-0.553-0.448-1-1-1h-6c-0.552,0-1,0.447-1,1s0.448,1,1,1h6C19.552,14,20,13.553,20,13z M17,17c0-0.553-0.448-1-1-1h-3c-0.552,0-1,0.447-1,1s0.448,1,1,1h3C16.552,18,17,17.553,17,17z"/>
    </svg>
</template>

<script>
export default {
    name: "CommentIcon",
};
</script>
