<template>
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 32 32" class="flex user-icon" xml:space="preserve">
        <path d="M16,16c-2.757,0-5-2.243-5-5s2.243-5,5-5s5,2.243,5,5S18.757,16,16,16z M16,8c-1.654,0-3,1.346-3,3s1.346,3,3,3s3-1.346,3-3S17.654,8,16,8z M22.362,26H9.638c-1.087,0-2.099-0.591-2.64-1.544c-0.525-0.923-0.52-2.011,0.015-2.909C7.826,20.18,10.331,17,16,17s8.174,3.18,8.987,4.548c0.534,0.897,0.54,1.985,0.015,2.908C24.461,25.409,23.449,26,22.362,26zM16,19c-4.643,0-6.63,2.497-7.269,3.57c-0.233,0.392-0.074,0.758,0.005,0.897C8.883,23.725,9.196,24,9.638,24h12.725c0.441,0,0.755-0.275,0.901-0.532c0.079-0.139,0.238-0.506,0.005-0.897C22.63,21.497,20.643,19,16,19z"/>
    </svg>
</template>

<script>
export default {
    name: "UserIcon",
};
</script>
