<template>
    <svg xmlns="http://www.w3.org/2000/svg" class="flex application-icon" viewBox="0 0 660 750">
        <defs>
            <radialGradient :id="primaryGradientId" cx="50.000211%" cy="50%" r="50.2826128%" fx="50.000211%" fy="50%" gradientTransform="matrix(.988857 0 0 1 .00557152 0)">
                <stop offset="0%" stop-color="#FFF"/>
                <stop offset="0%" stop-color="#6DB6FF"/>
                <stop offset="100%" stop-color="#5AA9FA"/>
            </radialGradient>
            <radialGradient :id="secondaryGradientId" cx="50.000211%" cy="50%" r="62.1529176%" fx="50.000211%" fy="50%" gradientTransform="matrix(.8 0 0 1 .1000004 0)">
                <stop offset="0%" stop-color="#FFF"/>
                <stop offset="0%" stop-color="#6DB6FF"/>
                <stop offset="100%" stop-color="#5AA9FA"/>
            </radialGradient>
        </defs>
        <g fill="none" fill-rule="nonzero">
            <path fill="#5AA9FA" d="M241.317885 598.310581C132.997183 713.656728 25.60074 743.925139 4.46136181 748.957404c-2.02435661.484434-4.02946417-.830917-4.40161214-2.880941-.34648258-1.896028.84695744-3.737518 2.72373812-4.183456C22.7455472 737.166043 128.327147 707.382714 235.304202 593.803695"/>
            <path fill="#D8D8D8" d="M660 236.159965c0 85.982221-75.526787 158.044181-136.780415 208.656305-73.521681 60.749949-160.026834 104.506207-252.746217 127.216858-9.255576 2.268177-27.914305 6.156482-27.914305 6.156482s-31.395172-36.348591-35.398971-41.484873c-12.335422-15.81308-23.904091-32.22609-34.6450506-49.16203-21.6647863-34.160615-39.9577836-70.454667-54.4940116-108.202021-30.2755201-78.613049-52.3188707-175.807831-38.5173143-260.330333C90.6521136 50.7179927 154.6326898 0 222.774265 0c42.6334 0 80.932558 18.2929974 107.586052 47.4841554 30.140777 31.7416547 66.874348 52.1135478 111.621934 52.1135478 11.575084 0 22.8486-1.3442243 33.672972-3.9588844 12.713987-3.0702209 25.492136-5.2261123 38.600727-5.2261123C594.742572 90.4159144 660 155.670135 660 236.159965Z"/>
            <path :fill="`url(#${primaryGradientId})`" d="M584.704737 236.159965c0 85.982221-75.526787 158.044181-136.780415 208.656305-73.521681 60.749949-160.026834 104.506207-252.746217 127.216858-9.255576 2.268177-27.914305 6.156482-27.914305 6.156482s-31.395172-36.348591-35.398971-41.484873c-12.335422-15.81308-23.904091-32.22609-34.6450506-49.16203-21.6647863-34.160615-39.9577836-70.454667-54.4940116-108.202021C12.4502467 300.727637-9.5931039 203.532855 4.2084525 119.010353 15.3568506 50.7179927 79.3374268 0 147.479002 0c42.6334 0 80.932558 18.2929974 107.586052 47.4841554 30.140777 31.7416547 66.874348 52.1135478 111.621934 52.1135478 11.575084 0 22.8486-1.3442243 33.672972-3.9588844 12.713987-3.0702209 25.492136-5.2261123 38.600727-5.2261123 80.486622.0032079 145.74405 65.2574285 145.74405 145.7472585Z" transform="translate(75.295263)"/>
            <path fill="#D8D8D8" d="M243.4742425 575C237.5271496 602.1192471 254 623 254 623s-30.9805744-27.3171688-60-27.3171688c0 0 45.098455 1.1716949 49.4742425-20.6828312Z"/>
            <path :fill="`url(#${secondaryGradientId})`" d="M49.4742425 0C43.5271496 27.1192471 60 48 60 48S29.0194256 20.6828312 0 20.6828312c0 0 45.098455 1.1716949 49.4742425-20.6828312Z" transform="translate(194 575)"/>
        </g>
    </svg>
</template>

<script>
import { defineComponent, } from "vue";

// For some reason, when navigating views on Google Chrome
// the SVG loses its gradient if it doesn't have an unique ID
function randomId () {
    return Math.random().toString().replace("0.", "");
}

export default defineComponent({
    name: "ApplicationIcon",
    data () {
        return {
            primaryGradientId: `primary-gradient-${randomId()}`,
            secondaryGradientId: `secondary-gradient-${randomId()}`,
        };
    },
});
</script>
