<template>
    <div class="flex flex--100 simple-bar-chart">
        <div class="flex flex--100 flex--x-align-center bar-chart">
            <div class="flex flex--100 indicators">
                <span class="indicator">0%</span>
                <span class="indicator">25%</span>
                <span class="indicator">50%</span>
                <span class="indicator">75%</span>
                <span class="indicator">100%</span>
            </div>
            <div class="flex flex--100 segments">
                <span class="segment"></span>
                <span class="segment"></span>
                <span class="segment"></span>
            </div>
            <div
                class="flex flex--100 bar-section"
                v-for="(value, i) in data"
                :key="i"
            >
                <div class="flex bar" :style="barStyle(value)">
                    <span class="percentage" :style="percentageStyle(i)">
                        {{ getValuePercentage(value) }}%
                    </span>
                    <span class="label" :style="labelStyle(i)">
                        {{ labels[i] }} · {{ value }} votes
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, } from "vue";

export default defineComponent({
    name: "SimpleBarChart",
    props: {
        labels: {
            type: Array,
        },
        data: {
            type: Array,
        },
        baseColor: {
            type: String,
            default: "31, 108, 225"
        },
    },
    computed: {
        total () {
            return this.data.reduce((total, current) => total + current, 0);
        },
    },
    methods: {
        getValuePercentage (value) {
            if (this.total === 0) {
                return 0;
            }

            return Number((value / this.total * 100).toFixed(1));
        },

        barStyle (value) {
            return {
                "--background-width": `${this.getValuePercentage(value)}%`,
                "--background-color": `rgba(${this.baseColor}, ${value / this.total})`,
            };
        },

        percentageStyle () {
            return {
                "font-size": "15px",
            };
        },

        labelStyle () {
            return {
                "font-size": "13px",
            };
        },
    },
});
</script>

<style lang="scss" scoped>
.simple-bar-chart {
    margin: 0;
    padding: 0;
}

.bar-chart {
    position: relative;

    width: 100%;

    margin: 0;
    padding: 0;
}

.bar-section {
    overflow: hidden;

    background-color: rgba(33, 33, 33, 0.5);
    border-radius: 9px;

    & + & {
        margin-top: 3px;
    }
}

.bar {
    position: relative;

    width: 100%;
    height: auto;
    min-height: 59px;
    border-radius: 9px;

    padding: 9px;

    &::before {
        transition: width 190ms, background-color 190ms;

        content: "";
        position: absolute;
        left: 0;
        top: 0;

        width: var(--background-width);
        height: 100%;

        background-color: var(--background-color);

        z-index: 5;
    }
}

.percentage {
    width: 100%;

    margin: 0;
    padding: 0;

    font-size: 11px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.95);

    z-index: 10;
}

.label {
    width: 100%;

    margin: 1px 0 0 0;
    padding: 0;

    font-size: 11px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.7);

    z-index: 10;
}

$indicators-height: 20px;

.indicators {
    position: relative;

    height: $indicators-height;

    z-index: 6;

    .indicator:nth-child(1) {
        left: 0;
    }

    .indicator:nth-child(2) {
        left: 25%;
    }

    .indicator:nth-child(3) {
        left: 50%;
    }

    .indicator:nth-child(4) {
        left: 75%;
    }

    .indicator:nth-child(5) {
        left: 100%;

        transform: translateX(-100%);
    }
}

.indicator {
    position: absolute;
    top: 0;

    font-size: 11px;
    font-weight: 400;

    color: rgba(77, 77, 77, 0.9);
}

.segments {
    z-index: 6;
}

.segment {
    $top: $indicators-height - 5px;

    position: absolute;
    left: 0;
    top: $top;

    width: 1px;
    height: calc(100% - #{$top});

    background-color: rgba(0, 0, 0, 0.1);

    &:nth-child(1) {
        left: 25%;
    }

    &:nth-child(2) {
        left: 50%;
    }

    &:nth-child(3) {
        left: 75%;
    }
}
</style>
