<template>
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" class="flex decent-work-economic-growth-icon" viewBox="0 0 100 100">
        <path d="M78.75 24.25v8a1.5 1.5 0 1 1-3 0v-4.379L56.225 47.396c-1.322 1.323-3.63 1.322-4.949 0l-6.171-6.171a.502.502 0 0 0-.708 0L23.811 61.811c-.293.293-.677.439-1.061.439s-.768-.146-1.061-.439a1.5 1.5 0 0 1 0-2.121l20.586-20.586a3.505 3.505 0 0 1 4.95 0l6.171 6.172a.498.498 0 0 0 .708 0L73.629 25.75H69.25a1.5 1.5 0 1 1 0-3h8a1.5 1.5 0 0 1 1.5 1.5zm-6.5 12a1.5 1.5 0 0 0-1.5 1.5v36a.5.5 0 0 1-.5.5h-10.5v-22.5a1.5 1.5 0 1 0-3 0v22.5h-11v-26.5a1.5 1.5 0 1 0-3 0v26.5h-10.5a.5.5 0 0 1-.5-.5v-12.5a1.5 1.5 0 1 0-3 0v12.5c0 1.93 1.57 3.5 3.5 3.5h38c1.93 0 3.5-1.57 3.5-3.5v-36a1.5 1.5 0 0 0-1.5-1.5z"/>
    </svg>
</template>

<script>
export default {
    name: "DecentWorkEconomicGrowthIcon",
}
</script>
