<template>
    <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonTitle>Notifications</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <!-- <unseen> -->
            <IonList v-show="unseenNotifications.length > 0">
                <IonListHeader>
                    <IonLabel>New</IonLabel>
                </IonListHeader>
                <IonItem
                    v-for="(notification, i) in unseenNotifications"
                    :key="notification.id"
                >
                    <UserNotification v-model:notification="unseenNotifications[i]"/>
                </IonItem>
            </IonList>
            <!-- </unseen> -->
            <!-- <seen> -->
            <!-- <last-7-days> -->
            <IonList v-show="last7DaysSeenNotifications.length > 0">
                <IonListHeader>
                    <IonLabel>Last 7 days</IonLabel>
                </IonListHeader>
                <IonItem
                    v-for="notification in last7DaysSeenNotifications"
                    :key="notification.id"
                    :button="true"
                >
                    <UserNotification :notification="notification"/>
                </IonItem>
            </IonList>
            <!-- </last-7-days> -->
            <!-- <last-30-days> -->
            <IonList v-show="last30DaysSeenNotifications.length > 0">
                <IonListHeader>
                    <IonLabel>Last 30 days</IonLabel>
                </IonListHeader>
                <IonItem
                    v-for="notification in last30DaysSeenNotifications"
                    :key="notification.id"
                    :button="true"
                >
                    <UserNotification :notification="notification"/>
                </IonItem>
            </IonList>
            <!-- </last-30-days> -->
            <!-- <older-30-days> -->
            <IonList v-show="older30DaysSeenNotifications.length > 0">
                <IonListHeader>
                    <IonLabel>Older</IonLabel>
                </IonListHeader>
                <IonItem
                    v-for="notification in older30DaysSeenNotifications"
                    :key="notification.id"
                    :button="true"
                >
                    <UserNotification :notification="notification"/>
                </IonItem>
            </IonList>
            <!-- </older-30-days> -->
            <!-- </seen> -->
            <div
                class="flex flex--100 flex--x-align-center flex--y-align-center no-notification-section"
                v-if="allNotifications.length === 0"
            >
                <h2 class="no-notification-section__title">
                    No notifications yet
                </h2>
            </div>
        </IonContent>
    </IonPage>
</template>

<script>
import UserAvatar from "@/components/UserAvatar.vue";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonListHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonList,
} from "@ionic/vue";
import {userManager,} from "@/UserManager";
import UserNotification from "@/components/notifications/UserNotification.vue";

const D7_MS = 604800000;
const D30_MS = 2592000000;

export default {
    name: "NotificationsView",
    components: {
        UserNotification,
        IonHeader,
        IonList,
        IonIcon,
        IonPage,
        IonToolbar,
        IonContent,
        IonButtons,
        IonButton,
        UserAvatar,
        IonTitle,
        IonListHeader,
        IonLabel,
        IonItem,
    },
    data () {
        return {
            isLoading: false,
            unseenNotifications: [],
            seenNotifications: [],
        };
    },
    methods: {
        async fetch () {
            this.isLoading = true;
            const {
                unseenNotifications,
                seenNotifications,
            } = await userManager.getNotifications();
            this.unseenNotifications = unseenNotifications;
            this.seenNotifications = seenNotifications;
            this.isLoading = false;
        }
    },
    computed: {
        last7DaysSeenNotifications () {
            return this.seenNotifications.filter((notification) => {
                return Date.now() - new Date(notification.createdAt).getTime() <= D7_MS;
            });
        },

        last30DaysSeenNotifications () {
            return this.seenNotifications.filter((notification) => {
                const elapsedTime = Date.now() - new Date(notification.createdAt).getTime();

                return elapsedTime > D7_MS && elapsedTime <= D30_MS;
            });
        },

        older30DaysSeenNotifications () {
            return this.seenNotifications.filter((notification) => {
                return Date.now() - new Date(notification.createdAt).getTime() > D30_MS;
            });
        },

        allNotifications () {
            return [
                ...this.unseenNotifications,
                ...this.seenNotifications,
            ];
        },
    },
    created () {
        this.fetch();
    },
    ionViewDidLeave () {
        // TODO: Mark all notifications as read
    },
}
</script>

<style lang="scss" scoped>
.no-notification-section {
    cursor: default;
    user-select: none;
    overflow: hidden;

    height: 100%;

    &__title {
        margin: 21px;

        font-size: 23px;
        font-weight: 600;
        letter-spacing: 0.01rem;
        color: rgb(0, 0, 0);
        text-align: center;
    }
}
</style>
