<template>
    <div class="flex flex--100 user-invoice">
        <div class="flex flex--100">
            <h2 class="title">Invoice of {{ visibleCreatedAt }}</h2>
        </div>
        <div class="flex flex--100">
            <p class="description">
                {{ visibleDescription }}
            </p>
        </div>
        <div class="flex flex--100">
            <span class="amount-paid">
                Paid €{{ amoundPaid }} on {{ visiblePaidAt }}
            </span>
        </div>
    </div>
</template>

<script>
import { defineComponent, } from "vue";

export default defineComponent({
    name: "UserInvoice",
    props: {
        // The user Stripe invoice
        invoice: {
            type: Object,
            required: true,
        },
    },
    computed: {
        createdAt () {
            return new Date(this.invoice.created * 1000);
        },

        visibleCreatedAt () {
            return this.createdAt.toLocaleDateString();
        },

        visibleDescription () {
            return this.invoice.lines.data.map((item) => item.description).join("\n");
        },

        amoundPaid () {
            return this.invoice["amount_paid"] / 100;
        },

        paidAt () {
            return new Date(this.invoice["status_transitions"]["paid_at"] * 1000);
        },

        visiblePaidAt () {
            return this.paidAt.toLocaleDateString();
        },
    },
});
</script>

<style lang="scss" scoped>
.user-invoice {
    padding: 11px 0;
}

.title {
    margin: 0;
    padding: 0;

    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.008rem;
    color: rgb(33, 33, 33);
}

.description {
    margin: 9px 0;

    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.008rem;
    color: rgb(113, 113, 113);
}

.amount-paid {
    margin: 0;

    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.008rem;
    color: rgb(113, 113, 113);
}
</style>
