<template>
    <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonBackButton/>
                </IonButtons>
                <IonTitle>My Polls</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <!-- <today> -->
            <IonList v-show="todayPolls.length > 0">
                <IonListHeader>
                    <IonLabel>Today</IonLabel>
                </IonListHeader>
                <IonItem
                    v-for="{ id, } in todayPolls"
                    :key="id"
                    :button="true"
                    @click="toPollView(id)"
                >
                    <SimplePollPreview :id="id"/>
                </IonItem>
            </IonList>
            <!-- </>today> -->
            <!-- <yesterday> -->
            <IonList v-show="yesterdayPolls.length > 0">
                <IonListHeader>
                    <IonLabel>Yesterday</IonLabel>
                </IonListHeader>
                <IonItem
                    v-for="{ id, } in yesterdayPolls"
                    :key="id"
                    :button="true"
                    @click="toPollView(id)"
                >
                    <SimplePollPreview :id="id"/>
                </IonItem>
            </IonList>
            <!-- </yesterday> -->
            <!-- <older> -->
            <IonList v-show="olderPolls.length > 0">
                <IonListHeader>
                    <IonLabel>Older</IonLabel>
                </IonListHeader>
                <IonItem
                    v-for="{ id, } in olderPolls"
                    :key="id"
                    :button="true"
                    @click="toPollView(id)"
                >
                    <SimplePollPreview :id="id"/>
                </IonItem>
            </IonList>
            <!-- </older> -->
            <!-- <no-polls> -->
            <div
                class="flex flex--100 flex--x-align-center flex--y-align-center zero-polls-section"
                v-if="polls.length === 0 && !isLoading"
            >
                <IonButton fill="outline" @click="toNewPollView">
                    Create Your First Poll
                    <IonIcon :icon="chevronForward"/>
                </IonButton>
            </div>
            <!-- </no-polls> -->
            <hr class="safe-bottom-area"/>
        </IonContent>
    </IonPage>
</template>

<script>
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonListHeader,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonTitle,
    IonToolbar,
} from "@ionic/vue";
import { mapStores } from "pinia";
import Poll from "@/components/polls/SimplePoll.vue";
import SimplePoll from "@/components/polls/SimplePoll.vue";
import LiveIcon from "@/components/icons/LiveIcon.vue";
import ApplicationIcon from "@/components/ApplicationIcon.vue";
import { userManager } from "@/UserManager";
import { usePollsStore } from "@/stores/PollsStore";
import { chevronForward } from "ionicons/icons";
import SimplePollPreview from "@/components/polls/SimplePollPreview.vue";
import { useUserStore } from "@/stores/UserStore";

export default {
    name: "MyPollsView",
    components: {
        IonLabel, IonListHeader,
        SimplePollPreview,
        IonButton,
        SimplePoll,
        IonTitle,
        IonButtons, IonBackButton,
        ApplicationIcon,
        LiveIcon,
        Poll,
        IonPage,
        IonContent,
        IonRefresher,
        IonRefresherContent,
        IonIcon,
        IonToolbar,
        IonHeader,
        IonItem,
    },
    data () {
        return {
            isLoading: false,
            polls: [],
        };
    },
    computed: {
        ...mapStores(usePollsStore),
        ...mapStores(useUserStore),

        loggedUser () {
            return this.userStore.user;
        },

        todayPolls () {
            return this.polls.filter((poll) => {
                const votedAt =
                    new Date(poll.createdAt).getTime();
                const todayMidnight = new Date();

                todayMidnight.setHours(0, 0, 0, 0);

                return votedAt >= todayMidnight.getTime();
            });
        },

        yesterdayPolls () {
            return this.polls.filter((poll) => {
                const votedAt =
                    new Date(poll.createdAt).getTime();
                const todayMidnight = new Date();

                todayMidnight.setHours(0, 0, 0, 0);

                const yesterdayMidnight = new Date(todayMidnight);

                yesterdayMidnight.setDate(todayMidnight.getDate() - 1);

                return (
                    votedAt >= yesterdayMidnight
                    && votedAt < todayMidnight
                );
            });
        },

        olderPolls () {
            return this.polls.filter((poll) => {
                const votedAt =
                    new Date(poll.createdAt).getTime();
                const todayMidnight = new Date();

                todayMidnight.setHours(0, 0, 0, 0);

                const yesterdayMidnight = new Date(todayMidnight);

                yesterdayMidnight.setDate(todayMidnight.getDate() - 1);

                return votedAt < yesterdayMidnight;
            });
        },

        chevronForward () {
            return chevronForward;
        },
    },
    methods: {
        async fetch () {
            this.isLoading = true;

            const {
                createdPolls,
            } = await userManager.getCreatedPolls(this.loggedUser.id);

            this.pollsStore.updateMany(createdPolls);

            this.polls = createdPolls;

            this.isLoading = false;
        },

        toNewPollView () {
            this.$router.push("/poll/new");
        },

        toPollView (pollId) {
            this.$router.push(`/polls/${pollId}`);
        },
    },
    created () {
        this.fetch();
    },
};
</script>

<style lang="scss" scoped>
.zero-polls-section {
    height: 100%;
}
</style>
