<template>
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" class="flex clean-water-sanitation-icon" viewBox="0 0 100 100">
        <path d="M71.734 18.867A2.497 2.497 0 0 0 69.841 18H30.159a2.499 2.499 0 0 0-2.473 2.866l6.078 41.146A3.52 3.52 0 0 0 37.226 65H45v7h-3a1.499 1.499 0 0 0-.987 2.629l8 7a1.499 1.499 0 0 0 1.975 0l8-7A1.5 1.5 0 0 0 58 72h-3v-7h7.774a3.52 3.52 0 0 0 3.462-2.988l6.078-41.146a2.495 2.495 0 0 0-.58-1.999zm-8.465 42.706a.505.505 0 0 1-.495.427H53.5a1.5 1.5 0 0 0-1.5 1.5v10a1.5 1.5 0 0 0 1.5 1.5h.508L50 78.507 45.992 75h.508a1.5 1.5 0 0 0 1.5-1.5v-10a1.5 1.5 0 0 0-1.5-1.5h-9.274a.505.505 0 0 1-.495-.427L32.54 33.204c.892.271 1.523.792 2.292 1.441C36.074 35.694 37.618 37 40.533 37c2.915 0 4.458-1.306 5.699-2.354C47.362 33.69 48.179 33 49.995 33c1.818 0 2.635.69 3.766 1.646C55.002 35.695 56.547 37 59.463 37s4.461-1.305 5.703-2.354c.769-.65 1.4-1.171 2.293-1.441l-4.19 28.368zm4.655-31.516c-2.28.259-3.605 1.376-4.695 2.297-1.13.956-1.947 1.646-3.766 1.646s-2.635-.69-3.766-1.646C54.456 31.305 52.911 30 49.995 30c-2.915 0-4.458 1.306-5.699 2.354C43.166 33.31 42.35 34 40.533 34s-2.634-.69-3.764-1.646c-1.09-.921-2.414-2.038-4.693-2.298L30.738 21h38.524l-1.338 9.057zm-19.04 9.441C48.281 40.169 43 46.193 43 51c0 3.86 3.14 7 7 7s7-3.14 7-7c0-4.807-5.281-10.831-5.884-11.502-.568-.634-1.664-.634-2.232 0zM50 55c-2.206 0-4-1.794-4-4 0-2.466 2.328-6.002 4-8.15 1.672 2.148 4 5.684 4 8.15 0 2.206-1.794 4-4 4z"/>
    </svg>
</template>

<script>
export default {
    name: "CleanWaterSanitationIcon",
}
</script>
