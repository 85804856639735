<template>
    <div class="flex flex--100 flex--x-align-center flex--y-align-center website-main-header">
        <div class="flex flex--100 flex--y-align-center traction-section">
            <div class="flex flex--100 title-section">
                <div class="flex flex--100 flex--x-align-center">
                    <h1 class="title">
                        A place where<br>
                        <span class="opinion">
                            opinion
                            <svg viewBox="0 0 418 42" fill="currentColor" class="flex underline-icon">
                                <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"/>
                            </svg>
                        </span>
                        meets impact
                    </h1>
                </div>
                <div class="flex flex--100 flex--x-align-center">
                    <span class="subtitle">
                        We are a social media platform where your polls <br>shape conversations,
                        your insights inspire action,<br>and your votes lead to real impact.
                    </span>
                </div>
                <div class="flex flex--100 flex--x-align-center">
                    <ApplicationMarketplaceButtons/>
                </div>
            </div>
        </div>
        <div class="flex flex--100 flex--x-align-center screenshot-section">
            <img src="/assets/mockups/company-poll.png" alt="" class="screenshot"/>
            <img src="/assets/mockups/ngo-poll-2.png" alt="" class="screenshot"/>
            <img src="/assets/mockups/public-figure-analytics.png" alt="" class="screenshot"/>
        </div>
    </div>
</template>

<script>
import SimplePoll from "@/components/polls/SimplePoll.vue";
import ApplicationMarketplaceButtons from "@/components/website/ApplicationMarketplaceButtons.vue";

export default {
    name: "WebsiteMainHeader",
    components: {
        ApplicationMarketplaceButtons,
        SimplePoll,
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";
@import "@/theme/poll-website.scss";

.website-main-header {
    overflow: hidden;

    min-height: calc(100vh - 113px);
    margin: 0;
    padding: 0;

    background-color: rgb(0, 0, 0);
    background-image: linear-gradient(to right, rgb(0, 0, 0) 60%, $brand-color);

    clip-path: ellipse(300% 100% at 50% 0);

    z-index: 1;
}

.traction-section {
    margin: 113px 0 0 0;
    padding: 0;

    @media (max-width: $mobile-breaking-point) {
        width: 100%;

        margin: 0;
        padding: 50px;
    }
}

.title-section {
    width: 100%;

    margin: 0;
    padding: 0;

    z-index: 2;
}

.title {
    margin: 0;
    padding: 0;

    font-size: 101px;
    font-weight: 800;
    letter-spacing: 0.02rem;

    line-height: 1;

    text-align: center;
    color: rgb(255, 255, 255);

    @media (max-width: $mobile-breaking-point) {
        font-size: 61px;
        line-height: 67px;
    }

    @media (max-width: $mobile-breaking-point) {
        br {
            display: none;
        }
    }
}

.subtitle {
    max-width: 971px;

    margin: 59px 0;

    font-size: 27px;
    font-weight: 300;
    letter-spacing: -0.01rem;
    color: rgb(247, 247, 247);

    @media (max-width: $mobile-breaking-point) {
        br {
            display: none;
        }
    }
}

.screenshot-section {
    position: relative;

    height: 571px;
    margin: 113px 0 0 0;
    padding: 0;

    @media (max-width: $mobile-breaking-point) {
        display: none;
    }

    z-index: 1;
}
.screenshot {
    width: 100%;
    max-width: 391px;
    height: auto;
    margin: 0;
    padding: 0;

    z-index: 2;

    &:first-child {
        position: absolute;

        transform: translate(-50%, 90px);

        opacity: 0.5;
        z-index: 1;
    }

    &:last-child {
        position: absolute;

        transform: translate(50%, 90px);

        opacity: 0.5;
        z-index: 1;
    }
}

::v-deep(.application-marketplace-buttons) {
    width: auto;

    margin: 0;
    padding: 0;

    .marketplace-button {
        border: 1.5px solid rgb(255, 255, 255);
    }
}

.underline-icon {
    position: absolute;
    left: 50%;
    bottom: 0;

    width: 130%;

    color: $brand-color;

    transform: translate(-50%, 50%);

    z-index: -1;
}

.opinion {
    position: relative;

    z-index: 1;
}
</style>
