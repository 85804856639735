<template>
    <svg class="flex statistics-icon" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path d="M21.5,26.5h-11c-2.76,0-5-2.24-5-5v-11c0-2.76,2.24-5,5-5h11c2.76,0,5,2.24,5,5v11c0,2.76-2.24,5-5,5ZM10.5,7.5c-1.65,0-3,1.35-3,3v11c0,1.65,1.35,3,3,3h11c1.65,0,3-1.35,3-3v-11c0-1.65-1.35-3-3-3h-11ZM17,21v-10c0-.55-.45-1-1-1s-1,.45-1,1v10c0,.55.45,1,1,1s1-.45,1-1ZM12.75,21v-7.5c0-.55-.45-1-1-1s-1,.45-1,1v7.5c0,.55.45,1,1,1s1-.45,1-1ZM21.25,21v-5c0-.55-.45-1-1-1s-1,.45-1,1v5c0,.55.45,1,1,1s1-.45,1-1Z"/>
    </svg>
</template>

<script>
import { defineComponent, } from "vue";

export default defineComponent({
    name: "StatisticsIcon",
});
</script>
