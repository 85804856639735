<template>
    <IonPage>
        <IonContent class="ion-content">

            <SimpleBarChart :data="barChartData.sort((a, b) => b - a)" :labels="barChartLabels"/>

            <SimpleBarChart :data="barChartData.sort((a, b) => b - a)" :labels="barChartLabels"/>

        </IonContent>
    </IonPage>
</template>

<script>
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, } from "@ionic/vue";
import StripeSubscriptionForm from "@/components/billings/StripeSubscriptionForm.vue";
import SimpleBarChart from "@/components/charts/SimpleBarChart.vue";

export default {
    name: "PlaygroundView",
    components: {
        SimpleBarChart,
        StripeSubscriptionForm,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
    },
    data () {
        return {
            providers: [],
            meeting: undefined,

            barChartData: [
                400, 200, 210, 150, 0
            ],

            barChartLabels: [
                "Men", "Women", "Binary", "Other", "OK"
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

ion-content {
    &::part(background) {
        background-color: black;
    }
}
</style>
