export const formatTime = (date: Date): string => {
    let hours: string = date.getUTCHours().toString();
    let minutes: string = date.getUTCMinutes().toString();

    if (Number(hours) < 10) {
        hours = `0${hours}`;
    }

    if (Number(minutes) < 10) {
        minutes = `0${minutes}`;
    }

    return `${hours}:${minutes}`;
};

export function timeToCompactString (ms: number): string {
    const days = ms / 86400000;

    if (days >= 1) {
        return `${Math.trunc(days).toString()}d`;
    }

    const hours = ms / 3600000;

    if (hours >= 1) {
        return `${Math.trunc(hours).toString()}h`;
    }

    const minutes = ms / 60000;

    return `${Math.trunc(minutes).toString()}m`;
}

export function toLocaleIsoString (date: Date) {
    const year = date.getFullYear().toString();
    let month = (date.getMonth() + 1).toString();
    let days = date.getDate().toString();
    const hours = date.getHours().toString();
    const minutes = date.getMinutes().toString();

    if (Number(month) < 10) {
        month = `0${month}`;
    }

    if (Number(days) < 10) {
        days = `0${days};`
    }

    return `${year}-${month}-${days}T${hours}:${minutes}Z`;
}
