<template>
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 32 32" class="flex trending-icon" xml:space="preserve">
        <path d="M25,10h-5c-0.553,0-1,0.447-1,1s0.447,1,1,1h3.586L17,18.586L13.414,15c-0.779-0.779-2.049-0.779-2.828,0l-5.293,5.293c-0.391,0.391-0.391,1.023,0,1.414C5.488,21.902,5.744,22,6,22s0.512-0.098,0.707-0.293L12,16.414L15.586,20c0.78,0.778,2.048,0.78,2.828,0L25,13.414V17c0,0.553,0.447,1,1,1s1-0.447,1-1v-5C27,10.897,26.103,10,25,10z"/>
    </svg>
</template>

<script>
export default {
    name: "TrendingIcon",
};
</script>
