<template>
    <div class="flex flex--100 plan">
        <div class="flex flex--100 plan-features-section">
            <div class="flex flex--100 flex--y-align-center feature-section">
                <ApplicationIcon/>
                <span class="feature">
                    30 Polls
                    <span class="monthly">/ month</span>
                </span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section">
                <ApplicationIcon/>
                <span class="feature">
                    300.000 Votes
                    <span class="monthly">/ month</span>
                </span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section">
                <ApplicationIcon/>
                <span class="feature">
                    4 Demographics Analytics
                    <InfoButton>
                        Uncover Analytics by<br>
                        Gender, Age Range, Country and Education
                    </InfoButton>
                </span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section">
                <ApplicationIcon/>
                <span class="feature">Polls in Feed Algorithm</span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section">
                <ApplicationIcon/>
                <span class="feature">4 Poll Options</span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section">
                <ApplicationIcon/>
                <span class="feature">
                    Voters Verification<!--<br>
                    (Email, Mobile Number)-->
                </span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section">
                <ApplicationIcon/>
                <span class="feature">Export Poll Data</span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section">
                <ApplicationIcon/>
                <div class="feature">
                    Poll Actions<!--<br>
                    (Donation, Petition, etc.)-->
                </div>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section">
                <ApplicationIcon/>
                <span class="feature">
                    Targeted Polls<!--<br>
                    (Age, Gender, Income Range, etc.)-->
                </span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section">
                <ApplicationIcon/>
                <span class="feature">
                    Collaboration on Polls
                </span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section feature-section--disabled">
                <ApplicationIcon/>
                <div class="feature">
                    Social Media Integrations
                </div>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section feature-section--disabled">
                <ApplicationIcon/>
                <span class="feature">
                    Poll Comments Sentiment Analysis
                </span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section feature-section--disabled">
                <ApplicationIcon/>
                <span class="feature">
                    Poll Options as Images
                </span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section feature-section--disabled">
                <ApplicationIcon/>
                <span class="feature">
                    Group Polls to Survey
                </span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section feature-section--disabled">
                <ApplicationIcon/>
                <span class="feature">
                    Recurrent Polls
                </span>
            </div>
        </div>
        <IonPopover
            class="info-popover"
            :is-open="popoverIsOpen"
            :event="popoverEvent"
            @didDismiss="dismissPopover"
        >
            <IonContent>
                <div class="info-popover-text">
                    Votes Analytics by<br>
                    <b>Age Range</b><br>
                    <b>Gender</b><br>
                    <b>Country</b><br>
                </div>
            </IonContent>
        </IonPopover>
    </div>
</template>

<script>
import ApplicationIcon from "@/components/ApplicationIcon.vue";
import { defineComponent, } from "vue";
import { IonContent, IonPopover } from "@ionic/vue";
import InfoButton from "@/components/mixed/InfoButton.vue";

export default defineComponent({
    name: "StandardCreatorPlan",
    components: {
        InfoButton,
        IonContent, IonPopover,
        ApplicationIcon,
    },
    data () {
        return {
            popoverEvent: undefined,
            popoverIsOpen: false,
        };
    },
    methods: {
        openPopover (e) {
            this.popoverEvent = e;
            this.popoverIsOpen = true;
        },

        dismissPopover () {
            this.popoverEvent = undefined;
            this.popoverIsOpen = false;
        },
    },
});
</script>

<style lang="scss" scoped>
@import "@/theme/pricing-plans.scss";
</style>
