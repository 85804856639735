<template>
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 32 32" class="flex home-icon" xml:space="preserve">
        <path d="M27.633,15.226L17.266,6.744c-0.733-0.6-1.798-0.599-2.533,0L4.367,15.226c-0.427,0.35-0.49,0.979-0.141,1.407c0.349,0.428,0.979,0.49,1.407,0.141l0.487-0.399l0.718,7.897C6.98,25.827,8.265,27,9.827,27h12.347c1.562,0,2.847-1.173,2.988-2.729l0.718-7.896l0.487,0.399C26.553,16.926,26.777,17,27,17c0.29,0,0.577-0.125,0.774-0.367C28.124,16.206,28.061,15.576,27.633,15.226z M18,25h-4v-4c0-1.103,0.897-2,2-2s2,0.897,2,2V25z M23.169,24.09C23.123,24.609,22.694,25,22.173,25H20v-4c0-2.206-1.794-4-4-4s-4,1.794-4,4v4H9.827c-0.521,0-0.949-0.391-0.996-0.909l-0.84-9.245L16,8.292l8.01,6.554L23.169,24.09z"/>
    </svg>
</template>

<script>
export default {
    name: "HomeIcon",
};
</script>
