<template>
    <div class="flex info-button">
        <span @click="openPopover">
            <InfoIcon/>
        </span>
        <IonPopover
            class="info-popover"
            :is-open="popoverIsOpen"
            :event="popoverEvent"
            @didDismiss="dismissPopover"
        >
            <IonContent>
                <div class="slot">
                    <slot/>
                </div>
            </IonContent>
        </IonPopover>
    </div>
</template>

<script>
import { defineComponent, } from "vue";
import { IonContent, IonPopover, } from "@ionic/vue";
import InfoIcon from "@/components/icons/InfoIcon.vue";

export default defineComponent({
    name: "InfoButton",
    components: {
        InfoIcon,
        IonContent,
        IonPopover,
    },
    data () {
        return {
            popoverEvent: undefined,
            popoverIsOpen: false,
        };
    },
    methods: {
        openPopover (e) {
            this.popoverEvent = e;
            this.popoverIsOpen = true;
        },

        dismissPopover () {
            this.popoverEvent = undefined;
            this.popoverIsOpen = false;
        },
    },
});
</script>

<style lang="scss" scoped>
.info-button {
    will-change: opacity;

    transition: opacity 190ms;
    cursor: pointer;

    &:hover {
        opacity: 0.5;
    }
}

.info-popover {
    --offset-y: 5px;

    &::part(arrow) {
        display: none;
    }
}

.info-icon {
    width: 19px;

    color: rgb(117, 117, 117);
}

.slot {
    margin: 15px;
    padding: 0;

    font-size: 15px;
    font-weight: 300;
    color: rgb(23, 23, 23);
}
</style>
