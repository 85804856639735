<template>
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" class="flex reduced-inequalities-icon" viewBox="0 0 100 100">
        <path d="M57.5 60.583h-15a2.503 2.503 0 0 1-2.5-2.5v-4c0-1.378 1.121-2.5 2.5-2.5h15c1.379 0 2.5 1.122 2.5 2.5v4c0 1.379-1.121 2.5-2.5 2.5zm-14.5-3h14v-3H43v3zm14.5-3h.01-.01zm0-6.166h-15a2.503 2.503 0 0 1-2.5-2.5v-4c0-1.378 1.121-2.5 2.5-2.5h15c1.379 0 2.5 1.122 2.5 2.5v4c0 1.378-1.121 2.5-2.5 2.5zm-14.5-3h14v-3H43v3zm14.5-3h.01-.01zm-.123-8H42.623c-.979 0-1.845-.55-2.262-1.435a2.48 2.48 0 0 1 .335-2.658l6.605-7.996c.667-.808 1.65-1.271 2.698-1.271s2.031.463 2.698 1.271l6.605 7.997c.623.754.752 1.771.335 2.657s-1.282 1.435-2.26 1.435zm-.386-2.181h.011-.011zm0-.001zm-13.305-.818h12.629l-5.929-7.178c-.131-.158-.298-.181-.386-.181s-.255.023-.386.181l-5.928 7.178zM50 78.943a3.486 3.486 0 0 1-2.698-1.271l-6.605-7.997a2.477 2.477 0 0 1-.335-2.657 2.478 2.478 0 0 1 2.262-1.435h14.754c.979 0 1.845.55 2.262 1.435s.288 1.903-.335 2.657L52.7 77.672a3.493 3.493 0 0 1-2.7 1.271zm-6.314-10.36 5.929 7.178c.131.158.298.181.386.181s.255-.023.386-.181l5.929-7.178h-12.63zm13.305-.818zM31.91 59.88a2.482 2.482 0 0 1-1.586-.576l-7.996-6.606c-.808-.667-1.271-1.65-1.271-2.698s.464-2.032 1.271-2.699l7.996-6.605a2.477 2.477 0 0 1 2.658-.333 2.478 2.478 0 0 1 1.435 2.261v14.754c0 .978-.55 1.844-1.435 2.261-.344.162-.71.241-1.072.241zm.324-2.888h.011-.011zm-.817-13.308-7.179 5.93c-.158.131-.182.298-.182.386s.023.255.182.385l7.179 5.931V43.684zM68.089 59.88c-.361 0-.727-.08-1.071-.242a2.478 2.478 0 0 1-1.435-2.261V42.623c0-.978.55-1.844 1.435-2.261a2.48 2.48 0 0 1 2.658.333l7.996 6.606c.808.667 1.271 1.65 1.271 2.698s-.464 2.032-1.271 2.699l-7.996 6.605a2.479 2.479 0 0 1-1.587.577zm.494-16.196v12.632l7.179-5.93c.158-.131.182-.298.182-.386s-.023-.255-.182-.385l-7.179-5.931z"/>
    </svg>
</template>

<script>
export default {
    name: "ReducedInequalitiesIcon",
};
</script>
