<template>
    <svg fill="currentColor" class="flex open-book-icon" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.74219,7.19531c-.48145-.38135-1.09668-.51904-1.70117-.37988l-7.04102,1.65723-7.04199-1.65723c-.60059-.14307-1.21875-.00244-1.7002.37988-.48145.38135-.75781.95264-.75781,1.56738v12.54932c0,1.16602.79297,2.16699,1.92676,2.43359l7.34375,1.72803c.07617.01758.15234.02637.22949.02637s.15332-.00879.22949-.02637l7.34375-1.72803c1.13379-.26709,1.92676-1.26758,1.92676-2.43359v-12.54932c0-.61475-.27637-1.18604-.75781-1.56738ZM8.5,21.31201v-12.54932l6.5,1.5296v12.94507l-6.11523-1.43903c-.22656-.05322-.38477-.25293-.38477-.48633ZM23.5,21.31201c0,.2334-.1582.43311-.38477.48633h-.00098l-6.11426,1.43896v-12.94501l6.5-1.5296v12.54932ZM18.02637,14.52295c-.12598-.5376.20703-1.07617.74512-1.20264l2.5-.58789c.52734-.12549,1.0752.20605,1.20215.74463.12598.5376-.20703,1.07617-.74512,1.20264l-2.5.58789c-.07617.01807-.15332.02637-.22949.02637-.45312,0-.86426-.31055-.97266-.771ZM22.47363,17.47705c.12598.5376-.20703,1.07617-.74512,1.20264l-2.5.58789c-.07617.01807-.15332.02637-.22949.02637-.45312,0-.86426-.31055-.97266-.771-.12598-.5376.20703-1.07617.74512-1.20264l2.5-.58789c.52734-.125,1.0752.20703,1.20215.74463ZM13.97363,14.52295c-.1084.46045-.51953.771-.97266.771-.07617,0-.15332-.0083-.22949-.02637l-2.5-.58789c-.53809-.12646-.87109-.66504-.74512-1.20264.12598-.53857.67188-.87012,1.20215-.74463l2.5.58789c.53809.12646.87109.66504.74512,1.20264ZM12.72363,18.229c-.1084.46045-.51953.771-.97266.771-.07617,0-.15332-.0083-.22949-.02637l-1.25-.29395c-.53809-.12646-.87109-.66504-.74512-1.20264s.67188-.86963,1.20215-.74463l1.25.29395c.53809.12646.87109.66504.74512,1.20264Z"/>
    </svg>
</template>

<script>
import { defineComponent, } from "vue";

export default defineComponent({
    name: "OpenBookIcon",
});
</script>
