<template>
    <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonBackButton/>
                </IonButtons>
                <IonTitle>My Invoices</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent>

            <div
                class="flex flex--100 flex--x-align-center flex--y-align-center loading-section"
                v-show="isWaitingServerResponse"
            >
                <IonSpinner name="circles"/>
            </div>

            <IonList v-if="!isWaitingServerResponse">
                <IonListHeader>
                    <IonLabel>Settled Invoices</IonLabel>
                </IonListHeader>
                <IonItem
                    v-for="invoice in invoices"
                    :key="invoice.id"
                >
                    <UserInvoice :invoice="invoice"/>
                </IonItem>
                <IonItem lines="none" v-if="!isWaitingServerResponse && invoices.length === 0">
                    <IonNote>No invoices yet</IonNote>
                </IonItem>
            </IonList>
        </IonContent>
    </IonPage>
</template>

<script>
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonNote, IonSpinner,
} from "@ionic/vue";
import { userManager } from "@/UserManager";
import UserInvoice from "@/components/billings/UserInvoice.vue";

export default {
    name: "MyInvoicesView",
    components: {
        IonSpinner,
        UserInvoice,
        IonPage,
        IonHeader,
        IonToolbar,
        IonNote,
        IonTitle,
        IonContent,
        IonList,
        IonListHeader,
        IonItem,
        IonLabel,
        IonButtons,
        IonBackButton,
        IonButton,
    },
    data () {
        return {
            isWaitingServerResponse: false,
            invoices: [],
        };
    },
    methods: {
        async fetch () {
            this.isWaitingServerResponse = true;

            try {
                const {
                    invoices,
                } = await userManager.getInvoices();
                this.invoices = invoices;
            }
            catch {
                // Silence is golden
            }

            this.isWaitingServerResponse = false;
        },
    },
    created () {
        this.fetch();
    },
};
</script>

<style lang="scss" scoped>
.loading-section {
    height: 100%;
}
</style>
