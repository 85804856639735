<template>
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 100 100" class="flex social-media-trending-icon">
        <path d="M65.628 32.585a2.486 2.486 0 0 0-2.42-1.19 2.458 2.458 0 0 0-2.083 1.687c-.529 1.599-1.528 3.752-3.356 5.438-.18-10.294-6.374-17.702-12.262-21.263a2.493 2.493 0 0 0-2.612.015 2.493 2.493 0 0 0-1.17 2.345c.699 8.079-3.215 13.001-7.358 18.211-3.539 4.45-7.199 9.052-8.444 15.854a25.213 25.213 0 0 0 4.955 20.057c4.303 5.498 10.701 8.895 17.555 9.318.527.032 1.052.049 1.574.049 6.265 0 12.166-2.329 16.76-6.647a24.596 24.596 0 0 0 7.722-17.853c-.001-10.261-6.198-21.615-8.861-26.021zm-.917 41.687c-4.367 4.105-10.073 6.164-16.094 5.79-6.086-.376-11.548-3.279-15.377-8.173a22.205 22.205 0 0 1-4.367-17.667c1.11-6.063 4.532-10.366 7.841-14.527 4.17-5.244 8.478-10.659 8.066-19.343 5.108 3.431 10.192 10.087 9.985 19.164a2.497 2.497 0 0 0 1.358 2.291 2.476 2.476 0 0 0 2.635-.216c2.542-1.896 4.01-4.451 4.842-6.545 2.743 4.691 7.887 14.679 7.887 23.559.001 5.995-2.406 11.559-6.776 15.667zm.318-21.418v8a1.5 1.5 0 0 1-3 0v-4.379L54.503 64c-1.322 1.323-3.628 1.322-4.95 0l-3.17-3.171a.502.502 0 0 0-.708 0l-8.169 8.169c-.293.293-.677.439-1.061.439s-.768-.146-1.061-.439a1.5 1.5 0 0 1 0-2.121l8.169-8.169a3.504 3.504 0 0 1 4.95 0l3.171 3.171a.501.501 0 0 0 .708 0l7.525-7.525h-4.379a1.5 1.5 0 0 1 0-3h8a1.5 1.5 0 0 1 1.501 1.5z"/>
    </svg>
</template>

<script>
export default {
    name: "SocialMediaTrendingIcon",
};
</script>
