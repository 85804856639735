<template>
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 100 100" class="flex sustainable-cities-communities-icon">
        <path d="M80 54.053H67V35.558a2.478 2.478 0 0 0-1.619-2.339 2.474 2.474 0 0 0-2.761.691L51.5 46.597V27c0-1.93-1.57-3.5-3.5-3.5H36.5c-1.93 0-3.5 1.57-3.5 3.5v30.319l-4.257-5.621c-.477-.629-1.203-.99-1.993-.99s-1.516.361-1.993.991l-6.953 9.179c-.005.007-.007.016-.012.023a1.489 1.489 0 0 0-.197.372l-.024.078c-.042.137-.07.28-.07.431V74c0 1.379 1.122 2.5 2.5 2.5h60c1.378 0 2.5-1.121 2.5-2.5V56.553a2.504 2.504 0 0 0-2.501-2.5zm-.5 3v2.5H67v-2.5h12.5zm-52.75-3.017 4.732 6.247h-9.464l4.732-6.247zm-6.25 9.247H33V73.5h-4.75v-4.224a1.5 1.5 0 1 0-3 0V73.5H20.5V63.283zM36 27a.5.5 0 0 1 .5-.5H48a.5.5 0 0 1 .5.5v46.5H36V27zm15.5 24.147L64 36.887V73.5H51.5V51.147zM67 73.5V62.553h12.5V73.5H67zm-25.75-20v1a1.5 1.5 0 1 1-3 0v-1a1.5 1.5 0 1 1 3 0zm5 0v1a1.5 1.5 0 1 1-3 0v-1a1.5 1.5 0 1 1 3 0zm-5 7v1a1.5 1.5 0 1 1-3 0v-1a1.5 1.5 0 1 1 3 0zm5 0v1a1.5 1.5 0 1 1-3 0v-1a1.5 1.5 0 1 1 3 0zm-5-14v1a1.5 1.5 0 1 1-3 0v-1a1.5 1.5 0 1 1 3 0zm5 0v1a1.5 1.5 0 1 1-3 0v-1a1.5 1.5 0 1 1 3 0zm10.5 7v1a1.5 1.5 0 1 1-3 0v-1a1.5 1.5 0 1 1 3 0zm5 0v1a1.5 1.5 0 1 1-3 0v-1a1.5 1.5 0 1 1 3 0zm-5 7v1a1.5 1.5 0 1 1-3 0v-1a1.5 1.5 0 1 1 3 0zm5 0v1a1.5 1.5 0 1 1-3 0v-1a1.5 1.5 0 1 1 3 0zm0-14v1a1.5 1.5 0 1 1-3 0v-1a1.5 1.5 0 1 1 3 0zm-20.5-7v1a1.5 1.5 0 1 1-3 0v-1a1.5 1.5 0 1 1 3 0zm5 0v1a1.5 1.5 0 1 1-3 0v-1a1.5 1.5 0 1 1 3 0zm-5-7v1a1.5 1.5 0 1 1-3 0v-1a1.5 1.5 0 1 1 3 0zm5 0v1a1.5 1.5 0 1 1-3 0v-1a1.5 1.5 0 1 1 3 0z"/>
    </svg>
</template>

<script>
export default {
    name: "SustainableCitiesCommunitiesIcon",
};
</script>
