<template>
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" class="flex quality-education-icon" viewBox="0 0 100 100">
        <path d="M77.6 23.516a4.505 4.505 0 0 0-4.5 4.5v36.6c0 .172.029.343.087.504l3 8.4a1.502 1.502 0 0 0 2.826 0l3-8.4c.058-.162.087-.333.087-.504v-36.6c0-2.482-2.019-4.5-4.5-4.5zm0 3c.827 0 1.5.673 1.5 1.5v3.9h-3v-3.9a1.5 1.5 0 0 1 1.5-1.5zm0 42.04-1.5-4.2v-29.44h3v29.44l-1.5 4.2zM63.542 25.409a3.888 3.888 0 0 0-3.69-.321L42.91 32.415a3.306 3.306 0 0 1-2.619 0l-16.943-7.327a3.886 3.886 0 0 0-3.689.321 3.888 3.888 0 0 0-1.758 3.259V63.45a5.095 5.095 0 0 0 3.075 4.681L39.1 75.968c.794.344 1.647.516 2.5.516s1.706-.172 2.501-.516l18.123-7.837A5.096 5.096 0 0 0 65.3 63.45V28.668a3.888 3.888 0 0 0-1.758-3.259zM22.166 65.377A2.099 2.099 0 0 1 20.9 63.45V28.668c0-.438.284-.672.405-.752a.918.918 0 0 1 .495-.15c.108 0 .229.021.356.076L39.1 35.169c.324.14.66.244 1 .327v37.636l-17.934-7.755zM62.3 63.45c0 .838-.497 1.595-1.266 1.927h-.001L43.1 73.133V35.496c.34-.083.676-.187 1.001-.327l16.941-7.327a.885.885 0 0 1 .853.074c.121.08.405.314.405.752V63.45z"/>
    </svg>
</template>

<script>
export default {
    name: "QualityEducationIcon",
}
</script>
