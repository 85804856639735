import { defineStore, } from "pinia";
import { ref, } from "vue";

export const useUtilityStore = defineStore("utility", () => {
    const sharedComponents = ref({});

    return {
        sharedComponents,
    };
});
