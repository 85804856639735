<template>
    <span class="flex follow-button" :class="modifiers">
        {{ text }}
    </span>
</template>

<script>
import { defineComponent, } from "vue";
import { Haptics, ImpactStyle, } from "@capacitor/haptics";

export default defineComponent({
    name: "FollowButton",
    props: {
        value: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        modifiers () {
            return {
                "follow-button--followed": this.value,
            };
        },
        text () {
            if (this.value) {
                return "Following";
            }

            return "Follow";
        },
    },
    methods: {
        async vibrate () {
            try {
                await Haptics.impact({
                    style: ImpactStyle.Medium,
                });
            }
            catch {
                // Silence is golden
            }
        },
    },
    watch: {
        value () {
            this.vibrate();
        },
    },
});
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.follow-button {
    cursor: pointer;

    margin: 0;
    padding: 8px 20px;
    border-radius: 8px;

    background-color: $brand-color;

    font-weight: 500;
    font-size: 14px;
    color: rgb(250, 250, 250);

    letter-spacing: 0.008rem;

    &--followed {
        background-image:
            linear-gradient(45deg, rgb(233, 233, 233), rgb(227, 227, 227), rgb(233, 233, 233));

        font-weight: 400;
        color: $base-text-color;
    }
}
</style>
